import PropTypes from "prop-types";
import styled from "styled-components";

const StyledCounter = styled.div`
  font-family: Inter;
  text-align: center;
  width: fit-content;
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 12px;
  border-radius: 10px;

  ${(props) =>
    props.size === "small" &&
    `
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 10px;

    `}

  ${(props) =>
    props.size === "large" &&
    `
    font-size: 18px;
    padding: 4px 15px;
    border-radius: 16px;

  `}
`;

const Counter = ({ text = "", size = "small", visible = true }) => {
  return visible ? <StyledCounter size={size}>{text}</StyledCounter> : <></>;
};

Counter.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
};

export default Counter;
