import { createContext } from "react";

const FeatureContext = createContext({
  flags: null,
  setFlags: () => {}, // Not used, but later if API develops feature flagging endpoints
  context: null, // To be set upon login based on organization membership and user
  setContext: () => {}, // To be set upon login based on organization membership and user
});

export default FeatureContext;
