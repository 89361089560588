const { splitFormProps, useField } = require("react-form");
const {
  StyledRadio,
  RadioInputGroup,
  RadioInputLabel,
  RadioInput,
} = require("./styles");
const { Label } = require("core/components/Form");

export const Radio = (props) => {
  const [field, fieldOptions] = splitFormProps(props);
  const { value = "", setValue } = useField(field, {
    ...fieldOptions,
  });

  return (
    <StyledRadio {...props}>
      <Label htmlFor="radio">{props.label}</Label>
      <RadioInputGroup>
        {props.options.map((option) => (
          <RadioInputLabel key={option.label}>
            <RadioInput
              checked={value === option.value}
              type="radio"
              name={props.group}
              onChange={() => setValue(option.value)}
            />
            <span
              className={value === option.value ? "checked" : "unchecked"}
            />
            {option.label}
          </RadioInputLabel>
        ))}
      </RadioInputGroup>
    </StyledRadio>
  );
};
