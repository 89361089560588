import Typography from "../Typography";
import { TableError } from "./styles";

const TableErrorFallback = () => {
  return (
    <TableError>
      <Typography variant="h1" style={{ color: "#BA351C" }}>
        Error
      </Typography>
      <Typography variant="h5" style={{ color: "#BA351C" }}>
        There was an issue loading this table. Please contact support for
        assistance.
      </Typography>
    </TableError>
  );
};

export default TableErrorFallback;
