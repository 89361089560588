import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

import { useNavigate } from "react-router-dom";

const CloseButton = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <FontAwesomeIcon
      icon={faClose}
      size="2xl"
      style={{
        position: "absolute",
        top: "25px",
        right: "35px",
        cursor: "pointer",
      }}
      onClick={() => (onClose ? onClose() : navigate(-1))}
    />
  );
};

export default CloseButton;
