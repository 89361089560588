import { createContext } from "react";

const NavigationContext = createContext({
  isNavOpen: false,
  setNavOpen: () => {},
  //For search text persistence in Users & Organizations pages
  userSearchValue: "",
  setUserSearchValue: () => {},
  orgSearchValue: "",
  setOrgSearchValue: () => {},
  //For table page number persistence in Users & Organizations pages
  currentUserPageNumber: "",
  setCurrentUserPageNumber: () => {},
  currentOrgPageNumber: "",
  setCurrentOrgPageNumber: () => {},
});

export default NavigationContext;
