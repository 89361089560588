import FormContext from "./FormContext";

const FormProvider = ({ validatePristine, children }) => {
  return (
    <FormContext.Provider
      value={{
        validatePristine,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
