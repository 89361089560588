import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Typography from "./Typography";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(8, 7, 7, 0.6);
`;

const ModalWrapper = styled.div`
  margin-top: 20vh;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const ModalContent = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  padding: 32px;
  min-width: 415px;
`;

const ModalDismiss = styled.div`
  position: absolute;
  display: inline-block;
  top: 13px;
  right: 13px;
  padding: 2px;
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
  color: #3e3e3c;

  :hover {
    border-radius: 50%;
    background-color: #f5f2fc;
  }
`;

const DismissIcon = styled(FontAwesomeIcon)`
  width: 22px;
  height: 22px;
`;

const Modal = ({ visible, dismiss, title, style = {}, children }) => {
  return (
    <ModalBackground visible={visible}>
      <ModalWrapper style={style}>
        <ModalContent>
          {title && (
            <Typography variant="h3" style={{ textAlign: "left" }}>
              {title}
            </Typography>
          )}
          {children}
        </ModalContent>
        <ModalDismiss onClick={dismiss}>
          <DismissIcon icon={faClose} />
        </ModalDismiss>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default Modal;
