import { animated, Spring } from "react-spring";
import styled from "styled-components";

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 600;
`;

const height = 120;
const calcHeight = (percent) => percent * 0.01 * height;

const parts = [
  {
    color: "#702a6e",
    heightChanges: [35, 75, 50, 40, 35],
  },
  {
    color: "#8d2a58",
    heightChanges: [25, 30, 60, 90, 25],
  },
  {
    color: "#ca2a29",
    heightChanges: [50, 80, 70, 10, 50],
  },
  {
    color: "#fe2a00",
    heightChanges: [65, 10, 30, 50, 65],
  },
];

const Loader = () => {
  return (
    <LoaderContainer>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          gap: "22px",
          height: `${height}px`,
        }}
      >
        {parts.map((part, index) => {
          return (
            <Spring
              // Matt: Using index for key as an exception here, we know we aren't using
              // normal list operations like sort, update, delete
              key={index}
              config={{ duration: 250 }}
              from={{ height: calcHeight(part.heightChanges[0]) }}
              to={part.heightChanges.slice(1).map((hc) => ({
                height: calcHeight(hc),
              }))}
              loop={true}
            >
              {(styles) => (
                <animated.div
                  alt="animated logo part"
                  style={{
                    ...styles,
                    width: "18px",
                    backgroundColor: part.color,
                  }}
                ></animated.div>
              )}
            </Spring>
          );
        })}
      </div>
    </LoaderContainer>
  );
};

export default Loader;
