import { useState } from "react";

import { required, validate } from "common/validation";
import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";

const UpdateAliasesForm = ({
  onSubmit,
  onCancel,
  defaultValues,
  isLoading,
}) => {
  // Trap default values and prevent react form from resetting on re-render
  const [formState] = useState(defaultValues);

  return (
    <Form
      mode="create"
      defaultValues={formState}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <Input
        type="text"
        field="provider_name"
        label="Alias (Optional)"
        validate={validate()}
        isLoading={isLoading}
        autoComplete="name"
      />
      <Input
        type="text"
        field="practice_name"
        label="Practice Name (Optional)"
        validate={validate()}
        isLoading={isLoading}
        autoComplete="practice_name"
      />
      <Input
        type="text"
        field="healthcare_organization_name"
        label="Organization Name (Optional)"
        validate={validate()}
        isLoading={isLoading}
        autoComplete="healthcare_organization_name"
      />
      <Input
        type="textarea"
        field="npis"
        label="NPIs"
        validate={validate(required())}
        isLoading={isLoading}
        autoComplete="npis"
        placeholder="Enter a list of NPI numbers"
        disabled={defaultValues.npis.length}
      />
    </Form>
  );
};

export default UpdateAliasesForm;
