import { useContext } from "react";
import NavigationContext from "core/context/NavigationContext";

const useNavigation = () => {
  const {
    isNavOpen,
    setNavOpen,
    userSearchValue,
    setUserSearchValue,
    orgSearchValue,
    setOrgSearchValue,
    currentUserPageNumber,
    setCurrentUserPageNumber,
    currentOrgPageNumber,
    setCurrentOrgPageNumber,
  } = useContext(NavigationContext);

  return {
    isNavOpen,
    setNavOpen,
    userSearchValue,
    setUserSearchValue,
    orgSearchValue,
    setOrgSearchValue,
    currentUserPageNumber,
    setCurrentUserPageNumber,
    currentOrgPageNumber,
    setCurrentOrgPageNumber,
  };
};

export default useNavigation;
