import { useParams } from "react-router-dom";

import { validate } from "common/validation";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";

const OrganizationLicenceForm = ({
  onSubmit,
  submitLabel = "Submit",
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  const params = useParams();
  const { orgId: organizationId } = params;

  return (
    <Form
      id="userLicenses"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitLabel={submitLabel}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      mode={!!defaultValues?.id ? "update" : "create"}
    >
      <Error>{error}</Error>
      <Input
        type="number"
        min="0"
        field="licenses.user_limit"
        label="User Limit"
        validate={validate()}
        isLoading={isFetching}
        autoComplete="user_limit"
      />
      <Input
        type="number"
        min="0"
        field="licenses.tableau_user_licenses.explorer_count"
        label="BI Portal Explorer"
        validate={validate()}
        isLoading={isFetching}
        autoComplete="explorer_count"
      />
      <Input
        type="number"
        min="0"
        field="licenses.tableau_user_licenses.viewer_count"
        label="BI Portal Viewer"
        validate={validate()}
        isLoading={isFetching}
        autoComplete="viewer_count"
      />
      {organizationId === "populi" ? (
        <Input
          type="number"
          min="0"
          defaultValue={0}
          field="licenses.tableau_user_licenses.creator_count"
          label="BI Portal Creator"
          validate={validate()}
          isLoading={isFetching}
          autoComplete="creator_count"
        />
      ) : null}
    </Form>
  );
};

export default OrganizationLicenceForm;
