import { createContext } from "react";

const FlashContext = createContext({
  success: null,
  setSuccess: () => {},
  error: null,
  setError: () => {},
});

export default FlashContext;
