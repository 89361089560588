import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import {
  faListUl,
  faFileCertificate,
  faArrowLeft,
  faKey,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faBan } from "@fortawesome/pro-solid-svg-icons";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getUsers } from "modules/admin/actions";
import useToaster from "core/hooks/useToaster";
import { FLAG_KEYS, ROLES, PERMISSIONS } from "core/constants";
import Typography from "core/components/Typography";
import { Column, Row } from "core/styles";
import Flex from "core/components/Flex";
import { SideMenuContainer, SubNav } from "modules/admin/SubNav";
import useCurrentUser from "core/hooks/useCurrentUser";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";
import { SecondaryButton } from "core/components/QueryBuilder/styles";
import useHasPermissions from "core/hooks/useHasPermissions";
import { UserGeneralInfoNavSection } from "modules/admin/UserGeneralInfoNavSection";
import { ResetPasswordNavSection } from "modules/admin/ResetPasswordNavSection";
import { ChangePasswordNavSection } from "modules/admin/ChangePasswordNavSection";
import { CredentialNavSection } from "modules/admin/CredentialNavSection";

const getUserStatus = ({ isActive }) =>
  isActive ? USER_STATUSES.ACTIVE : USER_STATUSES.INACTIVE;

const USER_STATUSES = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

const USER_STATUS_VARIANTS = {
  Active: "success",
  Inactive: "default",
};

const defaultUser = {
  active: true,
  status: getUserStatus({ isActive: true }),
  email: "",
  name: "",
  organizationId: "",
  role: "",
  username: "",
  tableauConfig: { tableauLicense: "" },
  direct_data_access_enabled: false,
};

const DetailsNavigationMenu = ({ isUserFetching, user }) => {
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  const { currentUser } = useCurrentUser();

  const isCurrentUser = currentUser?.email === user?.email;

  const showResetPassward = useHasPermissions(
    PERMISSIONS[currentUser?.role],
    "permission:user:reset-password"
  );

  return (
    <SideMenuContainer>
      <SubNav
        to="general"
        icon={faListUl}
        label="General"
        statusIcon={user?.active ? faCheck : faBan}
        statusVariant={USER_STATUS_VARIANTS[user?.status]}
        isStatusLoading={isUserFetching}
      />
      {!isCurrentUser && showResetPassward && (
        <SubNav
          to="reset-password"
          icon={faKey}
          label="Password"
          isDisabled={isUserFetching}
        />
      )}
      {isCurrentUser && (
        <SubNav
          to="change-password"
          icon={faKey}
          label="Password"
          isDisabled={false}
        />
      )}
      {hasDataAccessFeature && (
        <SubNav
          to="credentials"
          icon={faFileCertificate}
          label="Data Credentials"
          isDisabled={!user?.direct_data_access_enabled || isUserFetching}
        />
      )}
    </SideMenuContainer>
  );
};

const UserProfile = () => {
  const { toaster } = useToaster();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const { email, orgId: organizationId } = params;
  const location = useLocation();
  const from = location?.state?.from || window.history.state.from;
  const orgId = location?.state?.orgId;
  const userEmail = location?.state?.userEmail;

  const fetchQueryCall = useAuthenticatedCall((req) =>
    getUsers({ ...req, email })
  );

  const { currentUser } = useCurrentUser();

  const queryKey = ["users", organizationId, { email }];

  const {
    isError,
    error,
    isPlaceholderData, // isLoading is now false, so this replaces that while we fetch fresh data
    data: profileUser,
  } = useQuery({
    queryKey,
    queryFn: fetchQueryCall,
    staleTime: 20 * 1000,
    refetchOnMount: true,
    select: (data) => {
      if (Array.isArray(data) && data.length === 1) {
        const userData = data[0];

        return {
          ...defaultUser,
          ...userData,
          status: getUserStatus({ isActive: userData.active }),
          roleLabel: ROLES[userData.role],
        };
      }

      return defaultUser;
    },
    placeholderData: defaultUser,
  });

  useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        toaster.error({ message: error[0] });
      } else {
        toaster.error({ message: error.message });
      }
    }
  }, [isError, error, toaster]);

  useEffect(() => {
    const pathname = location.pathname; //this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname, email, currentUser?.email]);

  const previousPath = localStorage.getItem("pageUrl");
  const previousPathContainsAdmin = previousPath?.split("/")[1] === "admin";
  const previousPathContainsCreate = previousPath?.split("/")[3] === "create";
  const otherOrgId = !previousPathContainsCreate && previousPath?.split("/")[3];
  const otherOrgEmail =
    !previousPathContainsCreate && previousPath?.split("/")[5];

  useEffect(() => {
    if (orgId !== undefined) {
      window.history.pushState(
        { from: "UserManagement", orgId: orgId },

        "",
        `/admin/org-management/${orgId}`
      );
    } else if (orgId === undefined || userEmail === undefined) {
      window.history.pushState({ from: "Home" }, "", "/");
    }
  }, [orgId, userEmail]);

  useEffect(() => {
    if (
      previousPathContainsAdmin &&
      otherOrgId !== organizationId &&
      !previousPathContainsCreate
    ) {
      window.history.pushState(
        { from: "Users", orgId: otherOrgId },
        "",
        `/admin/org-management/${organizationId}/users/${email}/profile/general`
      );
    } else if (
      previousPathContainsAdmin &&
      otherOrgId !== organizationId &&
      previousPathContainsCreate
    ) {
      window.history.pushState(
        { from: "Organizations" },
        "",
        `/admin/org-management/${organizationId}/users/${email}/profile/general`
      );
    }
  }, [
    previousPath,
    previousPathContainsAdmin,
    organizationId,
    email,
    otherOrgId,
    previousPathContainsCreate,
    orgId,
    userEmail,
  ]);

  const getBackLinkAndText = (from) => {
    switch (from) {
      case "Users":
        return {
          text:
            otherOrgEmail === currentUser?.email
              ? "Back to User Profile"
              : "Back to Org Users",
          path:
            otherOrgEmail === currentUser?.email
              ? `/admin/org-management/${orgId}/users/${userEmail}/profile/general`
              : `/admin/org-management/${otherOrgId}`,
        };

      case "Aliases":
        return {
          text: "Back to Managed Providers",
          path: `/config/${organizationId}/managed-providers/aliases`,
        };

      case "Relationships":
        return {
          text: "Back to Managed Providers",
          path: `/config/${organizationId}/managed-providers/relationships`,
        };

      case "Organizations":
        return {
          text: orgId ? "Back to Org Details" : "Back to Organizations",
          path: orgId
            ? `/admin/org-management/${orgId}/update/general`
            : "/admin/org-management",
        };

      case "MyOrganization":
        return {
          text: "Back to My Organization",
          path: `/admin/org-management/${organizationId}`,
        };

      case "UserManagement":
        return {
          text: "Back to Org Details",
          path: orgId
            ? `/admin/org-management/${orgId}/update/general`
            : `/admin/org-management/${organizationId}/update/general`,
        };

      case "OrgUsers":
        return {
          text: "Back to Org Users",
          path: `/admin/org-management/${orgId}`,
        };

      case "Home":
        return {
          text: "Back to Home",
          path: "/",
        };

      default:
        return { text: "Back to Home", path: "/" };
    }
  };

  const showResetPassward = useHasPermissions(
    PERMISSIONS[currentUser?.role],
    "permission:user:reset-password"
  );

  return (
    <div style={{ position: "relative", padding: "80px 64px" }}>
      <div
        style={{
          position: "absolute",
          top: "30px",
        }}
      >
        <SecondaryButton
          onClick={() => {
            navigate(getBackLinkAndText(from)?.path, {
              state: {
                from:
                  orgId !== undefined
                    ? "OrgUsers"
                    : orgId === undefined || userEmail === undefined
                    ? "Home"
                    : "Users",
                orgId: orgId,
              },
            });
          }}
          style={{ display: "flex", width: "fit-content" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ marginRight: "5px", marginBottom: "-1px" }}
          />
          <Typography variant="sub-text" noMargin>
            {getBackLinkAndText(from)?.text}
          </Typography>
        </SecondaryButton>
      </div>

      <Flex direction="column">
        <AnimatePresence mode="wait">
          <Flex directionSm="column" direction="row" gap="7vw">
            <Flex direction="column">
              <Column gap="large" alignItems="start">
                <Row gap="15px" alignItems="center">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      width: "48px",
                      height: "48px",
                      padding: "10px 12px",
                      paddingBottom: "11px",
                      color: "#fff",
                      backgroundColor: "#00C1F3",
                      borderRadius: "5px",
                    }}
                  />
                  <div>
                    <Typography
                      variant="h3"
                      noMargin
                      style={{ maxWidth: "230px" }}
                    >
                      {isPlaceholderData || isError ? (
                        <Skeleton style={{ width: "150px" }} />
                      ) : (
                        profileUser.name
                      )}
                    </Typography>
                    <Typography variant="h5" noMargin>
                      {isPlaceholderData || isError ? (
                        <Skeleton style={{ width: "150px" }} />
                      ) : (
                        profileUser.roleLabel
                      )}
                    </Typography>
                  </div>
                </Row>

                <DetailsNavigationMenu
                  isUserFetching={isPlaceholderData}
                  user={profileUser}
                />

                <div id="portal__devtools" />
              </Column>
            </Flex>

            <Flex direction="column" minWidth="450px" width="min-content">
              <Routes>
                <Route
                  index
                  element={<Navigate replace to="general" state={{ from }} />}
                />
                <Route
                  path="general"
                  element={
                    <UserGeneralInfoNavSection
                      profileUser={profileUser}
                      isLoading={isPlaceholderData}
                      isError={isError}
                      USER_STATUS_VARIANTS={USER_STATUS_VARIANTS}
                    />
                  }
                />
                {showResetPassward && (
                  <Route
                    path="reset-password"
                    element={
                      <motion.div style={{ marginBottom: "30vh" }}>
                        <Typography variant="h2">Password</Typography>
                        <ResetPasswordNavSection user={profileUser} />
                      </motion.div>
                    }
                  />
                )}
                <Route
                  path="change-password"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Password</Typography>
                      <ChangePasswordNavSection
                        user={profileUser}
                        onMutationSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["users", organizationId, { email }],
                          });
                        }}
                      />
                    </motion.div>
                  }
                />
                <Route
                  path="credentials"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Data Credentials</Typography>
                      <CredentialNavSection
                        type="user"
                        entity={profileUser}
                        isLoading={isPlaceholderData}
                        onMutationSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["users", organizationId, { email }],
                          });
                        }}
                      />
                    </motion.div>
                  }
                />
              </Routes>
            </Flex>
          </Flex>
        </AnimatePresence>
      </Flex>
    </div>
  );
};

export default UserProfile;
