import styled from "styled-components";

export const StyledRadio = styled.div`
  width: 100%;
  margin-bottom: 22px;
  ${(props) =>
    props.isDisabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`;

export const RadioInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "column" : "row"};
  gap: 10px;
  margin-top: 10px;
`;

export const RadioInput = styled.input`
  height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
  width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
  margin-right: 8px;
  opacity: 0;
`;

export const RadioInputLabel = styled.label`
  font-size: 12px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: relative;
  color: #2b2826;
  line-height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};

  .unchecked {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 50%;
  }

  .checked {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 2px;
      left: 2px;
      width: ${({ variant }) => (variant === "large" ? "14px" : "10px")};
      height: ${({ variant }) => (variant === "large" ? "14px" : "10px")};
      border-radius: 50%;
      background: #0097c0;
    }
  }
`;
