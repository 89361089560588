import { useState } from "react";
import FeatureContext from "./FeatureContext";
import { FLAG_KEYS, FLAG_ENTITIES, RELEASE_STAGES } from "core/constants";

const FLAGS = {
  [FLAG_KEYS.DATA_ACCESS_MANAGEMENT]: {
    key: FLAG_KEYS.DATA_ACCESS_MANAGEMENT,
    description: "Provides access to data access management feature.",
    entity: FLAG_ENTITIES.ORGANIZATION,
    stage: RELEASE_STAGES.GA, // only available to current context with access to beta
  },
};

// Example Context
// {
//   "organization": {
//     key: "populi",
//     availableStages: [RELEASE_STAGES.BETA, RELEASE_STAGES.GA]
//   }
// };

const FeatureProvider = ({ children }) => {
  const [flags, setFlags] = useState(FLAGS);
  const [context, setContext] = useState(null);

  return (
    <FeatureContext.Provider
      value={{
        flags,
        setFlags, // Not used, but later if API  develops feature flagging endpoints
        context,
        setContext,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export default FeatureProvider;
