import { useState } from "react";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import { required, validate } from "common/validation";
import { FormSelect as Select } from "core/components/Form/Select";
import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";
import CheckboxGroup, {
  CheckboxLabelWithIcon,
} from "core/components/Form/CheckboxGroup";

const UpdateRelationshipsForm = ({
  onSubmit,
  onCancel,
  defaultValues,
  isLoading,
}) => {
  // Trap default values and prevent react form from resetting on re-render
  const [formState] = useState(defaultValues);

  return (
    <Form
      mode="create"
      defaultValues={formState}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <Select
        field="is_provider_in_network"
        label="Network Status (Optional)"
        validate={validate()}
        placeholder="Select a Status"
        options={[
          {
            value: true,
            label: "In Network",
          },
          {
            value: false,
            label: "Out of Network",
          },
        ]}
      />
      <Select
        field="provider_affiliation"
        label="Network Affiliation (Optional)"
        validate={validate()}
        placeholder="Select an Affiliation"
        options={[
          {
            value: "Employed",
            label: "Employed",
          },
          {
            value: "Aligned",
            label: "Aligned",
          },
          {
            value: "Joint Venture",
            label: "Joint Venture",
          },
          {
            value: "Affiliated",
            label: "Affiliated",
          },
          {
            value: "Owned",
            label: "Owned",
          },
          {
            value: "Partnership",
            label: "Partnership",
          },
          {
            value: "Unaffiliated",
            label: "Unaffiliated",
          },
        ]}
      />

      <CheckboxGroup
        label="Provider Participations (Optional)"
        validate={validate()}
        options={[
          {
            field: "is_in_program_ipa",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in IPA - <br />Independent Practice Association"
              >
                IPA
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_cin",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in CIN - <br />Clinically Integrated Network"
              >
                CIN
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_pho",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in PHO - <br />Physician Hospital Organization"
              >
                PHO
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_aco",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in ACO - <br />Accountable Care Organization"
              >
                ACO
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_mco",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in MCO - <br />Managed Care Organizations"
              >
                MCO
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_mso",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in MSO - <br />Management Services Organizations"
              >
                MSO
              </CheckboxLabelWithIcon>
            ),
          },
          {
            field: "is_in_program_ism",
            label: (
              <CheckboxLabelWithIcon
                icon={faInfo}
                info="Provider participates in ISM - <br />Integrated Salary Models"
              >
                ISM
              </CheckboxLabelWithIcon>
            ),
          },
        ]}
      />

      <Input
        type="textarea"
        field="npis"
        label="NPIs"
        validate={validate(required())}
        isLoading={isLoading}
        autoComplete="npis"
        placeholder="Enter a list of NPI numbers"
        disabled={defaultValues.npis.length}
      />
    </Form>
  );
};

export default UpdateRelationshipsForm;
