import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { DateTime, Interval } from "luxon";
import Skeleton from "react-loading-skeleton";
import {
  faCalendarDay,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

import { InputGroup, Label } from "core/components/Form";
import {
  FieldError,
  InputSkeletonContainer,
} from "core/components/Form/styles";

import DatePickerInput from "./DatePickerInput";
import DatePickerHeader from "./DatePickerHeader";
import {
  DATE_FORMATS,
  PLACEHOLDERS,
  QUARTER_MONTHS,
  RANGE_PLACEHOLDERS,
  RANGE_WIDTHS,
  WIDTHS,
} from "./constants";
import {
  CustomLeftArrow,
  CustomRightArrow,
  StyledCalendarIcon,
  StyledClearIcon,
  IconActionContainer,
  StyledErrorIcon,
} from "./styles";

const QuarterContent = (quarter, shortQuarter) => {
  return <>{QUARTER_MONTHS[shortQuarter]}</>;
};

const createSpecialLabelForAllTheNonDayTypeValuesAndAddItToTheLabelTextInParentheses =
  (type, label) => {
    switch (type) {
      case "month":
        return `${label} (Monthly)`;

      case "year":
        return `${label} (Yearly)`;

      case "quarter":
        return `${label} (Quarterly)`;

      default:
        return label;
    }
  };

const DatePicker = ({
  id = "",
  name = "",
  label = "",
  type = "date",
  size = "large",
  errorMessage,
  isRange = false,
  isDisabled = false,
  isLoading = false,
  value = null,
  minDate = null,
  maxDate = null,
  excludeDates = null,
  excludeDateIntervals = null,
  onChange = null,
}) => {
  // store startDate internally
  // used when with ranges when user has only selected a start date
  // and value is null since Interval needs both a start and end date
  const [startDate, setStartDate] = useState(null);
  const containerRef = useRef();
  const datePickerRef = useRef();

  const placeholderText = useMemo(
    () => (isRange ? RANGE_PLACEHOLDERS[type] : PLACEHOLDERS[type]),
    [isRange, type]
  );

  const width = useMemo(
    () => (isRange ? RANGE_WIDTHS[type] : WIDTHS[type]),
    [isRange, type]
  );

  const dateFormat = useMemo(() => DATE_FORMATS[type], [type]);

  useEffect(() => {
    if (isRange) {
      const isValidInterval = value?.isLuxonInterval;
      if (isValidInterval) {
        setStartDate(null);
      }
    }
  }, [isRange, value]);

  const _onChange = (newValue) => {
    if (isRange) {
      const [start, end] = newValue;

      if (start && end) {
        onChange(
          Interval.fromDateTimes(
            DateTime.fromJSDate(start),
            DateTime.fromJSDate(end)
          )
        );
      } else if (start) {
        onChange(null);
        setStartDate(DateTime.fromJSDate(start));
      } else {
        onChange(null);
        setStartDate(null);
      }
    } else {
      onChange(newValue ? DateTime.fromJSDate(newValue) : null);
    }
  };

  const openCalendar = () => {
    datePickerRef.current.setOpen(true);
    datePickerRef.current.setFocus(true);
  };

  const closeCalendar = () => {
    datePickerRef.current.setOpen(false);
    datePickerRef.current.setFocus(false);
    datePickerRef.current.setBlur(true);
  };

  const clearInput = () => _onChange(isRange ? [null, null] : null);

  const handleDownOrEnterKeyPress = (event) => {
    if (["Enter", "ArrowDown"].includes(event.key)) {
      openCalendar();
    }
  };

  const CustomHeader = (props) => {
    return <DatePickerHeader {...props} minDate={minDate} maxDate={maxDate} />;
  };

  const customInput = (
    <DatePickerInput onInputChange={_onChange} isRange={isRange} type={type} />
  );

  if (isLoading) {
    return (
      <InputGroup>
        {size !== "small" && <Label>{label}</Label>}
        <InputSkeletonContainer style={{ width }}>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  return (
    <InputGroup disabled={isDisabled}>
      {size !== "small" && (
        <Label htmlFor={id}>
          {createSpecialLabelForAllTheNonDayTypeValuesAndAddItToTheLabelTextInParentheses(
            type,
            label
          )}
        </Label>
      )}
      <div
        ref={containerRef}
        style={{ display: "inline-block", position: "relative", width }}
        className={`date-picker-container date-size-${size} date-picker-${type} ${
          !!errorMessage && "has-error"
        }`}
        onBlur={(event) => {
          // When tabbing out of calendar to next element, if it isn't a part of the DatePicker, close calendar
          const parent = containerRef.current;

          // Matt: event.relatedTarget null for elements in calendar without tabindex/focusable element type
          // We can make the calendar buttons focusable but using as="button" on styled component and adding tabIndex="0"
          // Will require additional styling though
          const containsRelatedTarget =
            parent.contains(event.relatedTarget) || !event.relatedTarget;

          if (!containsRelatedTarget) {
            closeCalendar();
          }
        }}
      >
        <div>
          <ReactDatePicker
            id={id}
            ref={datePickerRef}
            name={name}
            selectsRange={isRange}
            selected={isRange ? undefined : value?.toJSDate()}
            startDate={
              isRange
                ? value?.start?.toJSDate() || startDate?.toJSDate()
                : undefined
            }
            endDate={isRange ? value?.end.toJSDate() : undefined}
            showMonthYearPicker={type === "month"}
            showFourColumnMonthYearPicker={type === "month"}
            showQuarterYearPicker={type === "quarter"}
            showYearPicker={type === "year"}
            placeholderText={placeholderText}
            todayButton={
              type === "date" ? (
                <div className="react-datepicker__today-text">Today</div>
              ) : null
            }
            onChange={_onChange}
            showPopperArrow={false}
            popperPlacement="bottom-start"
            autoComplete="off"
            dateFormat={dateFormat}
            customInput={customInput}
            renderQuarterContent={QuarterContent}
            renderCustomHeader={type === "date" ? CustomHeader : undefined}
            minDate={minDate?.toJSDate()}
            maxDate={maxDate?.toJSDate()}
            excludeDates={excludeDates?.map((dt) => dt.toJSDate())}
            excludeDateIntervals={excludeDateIntervals?.map((i) => ({
              start: i.start.toJSDate(),
              end: i.end.toJSDate(),
            }))}
            previousMonthButtonLabel={<CustomLeftArrow />}
            previousYearButtonLabel={<CustomLeftArrow />}
            nextMonthButtonLabel={<CustomRightArrow />}
            nextYearButtonLabel={<CustomRightArrow />}
            disabled={isDisabled}
            preventOpenOnFocus={true}
            onKeyDown={handleDownOrEnterKeyPress}
            enableTabLoop={false}
          />

          <IconActionContainer $size={size}>
            {errorMessage && (
              <StyledErrorIcon icon={faCircleExclamation} $size={size} />
            )}

            {isRange ? (
              startDate || value ? (
                <StyledClearIcon
                  icon={faXmark}
                  onClick={clearInput}
                  $size={size}
                />
              ) : null
            ) : value ? (
              <StyledClearIcon
                icon={faXmark}
                onClick={clearInput}
                $size={size}
              />
            ) : null}

            <StyledCalendarIcon
              icon={faCalendarDay}
              onClick={openCalendar}
              $size={size}
            />
          </IconActionContainer>
        </div>
        <FieldError isHidden={size === "small"}>{errorMessage}</FieldError>
      </div>
    </InputGroup>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["date", "month", "quarter", "year"]),
  size: PropTypes.oneOf(["large", "small"]),
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRange: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(DateTime),
    PropTypes.instanceOf(Interval),
  ]),
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(DateTime)),
  excludeDateIntervals: PropTypes.arrayOf(PropTypes.instanceOf(Interval)),
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
