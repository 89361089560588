import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { animated, useSpring, useTransition } from "react-spring";
import {
  useIsRestoring,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DocumentTitle from "react-document-title";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { faCircleInfo, faClose } from "@fortawesome/pro-regular-svg-icons";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import Navigation from "core/components/Navigation";
// import AuthExpireModal from "modules/auth/AuthExpireModal";
import useCurrentUser from "core/hooks/useCurrentUser";
import useHasPermissions from "core/hooks/useHasPermissions";
import {
  FLAG_ENTITIES,
  RELEASE_STAGES,
  ROLES,
  PERMISSIONS,
} from "core/constants";
import {
  getAdminApiPermission,
  getOrganizations,
  getProductCatalog,
  getProductCatalogVersion,
  getUsers,
} from "modules/admin/actions";
import Typography from "./Typography";
import { SecondaryButton } from "./QueryBuilder/styles";
import Loader from "./Loader";
import { getLastActiveCredential } from "modules/admin/utilities";
import { DateTime } from "luxon";
import { useFeatureFlaggingState } from "core/hooks/featureFlagging";

const BannerContainer = styled.div`
  background-color: #0097c0;
  color: #fff;
  font-family: "Inter";
  text-align: center;
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  ${(props) =>
    props.variant === "warning" &&
    `
    background-color: #d68712;
    color: #fff
  `}

  ${(props) =>
    props.variant === "error" &&
    `
    background-color: #BA351C;
    color: #fff;
  `}
`;

const catalogQueryKey = [
  "catalog",
  null,
  {
    include: ["models", "data", "fields", "field_values"],
    // Currently only supporting single value filter,
    // Arrays combine objects as "AND"
    filters: [
      {
        catalog: "products",
        field: "is_license_required",
        operator: "=",
        value: true,
      },
    ],
  },
];

// Matt: Intentionally co-locating for now, but likely moving to core in future
const Banner = ({ isVisible, variant, onDismiss, children }) => {
  const containerHeight = useSpring({
    height: isVisible ? "30px" : "0px",
    delay: isVisible ? 675 : 0,
    position: "sticky",
    top: "0px",
    zIndex: 2000,
  });

  const transitions = useTransition([isVisible], {
    from: { position: "relative", zIndex: 2000, y: -30, width: "100%" },
    enter: [{ y: 0 }],
    leave: { position: "relative", zIndex: 2000, y: -30, width: "100%" },
    exitBeforeEnter: true,
  });

  return (
    <animated.div
      style={{ backgroundColor: "#2f2e41", height: "0px", ...containerHeight }}
    >
      {transitions((style, isVisible) => {
        return isVisible ? (
          <BannerContainer variant={variant} as={animated.div} style={style}>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "40px 1fr 40px",
              }}
            >
              <div />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {children}
              </div>
              {onDismiss && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"
                    onClick={onDismiss}
                  />
                </div>
              )}
            </div>
          </BannerContainer>
        ) : null;
      })}
    </animated.div>
  );
};

let hasCheckedCatalogVersion = false;

const LoadWhileRestoringLocalStorage = ({ children }) => {
  const isRestoring = useIsRestoring();

  if (isRestoring) {
    return <Loader />;
  }

  return children;
};

const ProtectedLayout = ({
  pageTitle,
  backLinkText,
  backLinkTo,
  showBackLink,
  children,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { orgId: orgIdParam } = useParams();
  const { updateEntityContext } = useFeatureFlaggingState();

  useEffect(() => {
    if (orgIdParam) {
      const organizationFeatureContext = {
        entity: FLAG_ENTITIES.ORGANIZATION,
        key: orgIdParam,
        availableStages:
          orgIdParam === "populi"
            ? [RELEASE_STAGES.BETA, RELEASE_STAGES.GA]
            : [RELEASE_STAGES.GA],
      };

      updateEntityContext(organizationFeatureContext);
    }
  }, [orgIdParam, updateEntityContext]);

  const locationContainsConfig = location.pathname.split("/")[1] === "config";

  const { currentUser } = useCurrentUser();
  const { role, email, organizationId } = currentUser;

  const fetchQueryCall = useAuthenticatedCall(getOrganizations);
  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchQueryCall,
  });

  const fetchUserCall = useAuthenticatedCall(getUsers);

  const queryKey = ["users", organizationId, { email }];
  const { data: users } = useQuery({
    queryKey,
    queryFn: fetchUserCall,
    staleTime: 20 * 1000,
  });

  const user = useMemo(() => {
    return {
      ...users?.[0],
    };
  }, [users]);
  const lastActiveCredential = getLastActiveCredential(user.data_access_info);
  const isCredentialExpiring = lastActiveCredential
    ? lastActiveCredential.days_to_expire <= 7
    : false;

  // Fetch product catalog
  const fetchCatalog = useAuthenticatedCall(getProductCatalog);
  const { data: productCatalog } = useQuery({
    queryKey: catalogQueryKey,
    queryFn: fetchCatalog,
    enabled: useHasPermissions(
      PERMISSIONS[role],
      getAdminApiPermission("catalog")
    ),
  });

  const fetchCatalogVersion = useAuthenticatedCall(getProductCatalogVersion);
  const { data: productCatalogVersion } = useQuery({
    queryKey: ["catalog-version"],
    queryFn: fetchCatalogVersion,
    enabled: useHasPermissions(
      PERMISSIONS[role],
      getAdminApiPermission("catalog-version")
    ),
  });

  // check if catalog version changed
  useEffect(() => {
    if (
      !hasCheckedCatalogVersion &&
      productCatalogVersion?.version &&
      productCatalog &&
      Object.keys(productCatalog).length > 0
    ) {
      hasCheckedCatalogVersion = true;

      if (
        productCatalogVersion.version !==
        productCatalog[Object.keys(productCatalog)[0]].version
      ) {
        // queryClient.invalidateQueries({ queryKey: catalogQueryKey });
      }
    }
  }, [productCatalog, productCatalogVersion, queryClient]);

  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    orgIdParam || organizationId
  );
  const [isExpirationBannerDismmissed, setExpirationBannerDismmissed] =
    useState(() => {
      const now = DateTime.now();
      const localValue = localStorage.getItem("dateExpirationBannerDismmissed");

      if (localValue) {
        const isToday = now.hasSame(DateTime.fromISO(localValue), "day");

        return isToday;
      }

      return false;
    });

  useEffect(() => {
    if (locationContainsConfig && orgIdParam !== selectedOrganizationId) {
      navigate(location.pathname.replace(orgIdParam, selectedOrganizationId));
    }
  }, [
    locationContainsConfig,
    orgIdParam,
    selectedOrganizationId,
    organizationId,
    location,
    navigate,
  ]);

  const inAliasMode = useMemo(() => {
    return locationContainsConfig && organizationId !== orgIdParam;
  }, [locationContainsConfig, organizationId, orgIdParam]);
  const aliasOrgName = inAliasMode
    ? organizations?.find((org) => org.id === selectedOrganizationId).name
    : null;

  const exitAliasMode = () => {
    setSelectedOrganizationId("populi");
  };

  const handleDismissExpirationBanner = () => {
    localStorage.setItem(
      "dateExpirationBannerDismmissed",
      DateTime.now().toISO()
    );
    setExpirationBannerDismmissed(true);
  };

  return (
    <>
      <DocumentTitle
        title={pageTitle ? `Populi | ${pageTitle}` : "Populi Platform"}
      />

      <div style={{ display: "flex", height: "100%" }}>
        <Navigation />
        <main
          style={{
            flex: 1,
            height: "100%",
            overflow: "auto",
            position: "relative",
          }}
        >
          {/* Switch Org Banner */}
          <Banner isVisible={inAliasMode && aliasOrgName}>
            <div style={{ marginRight: "5px" }}>
              You are currently viewing <b>{aliasOrgName}</b>
            </div>
            <div style={{ marginRight: "15px" }}>
              <FontAwesomeIcon id="switchedOrg" icon={faCircleInfo} />
              <Tooltip
                anchorSelect="#switchedOrg"
                render={() => [
                  "While viewing an organization in switched mode",
                  <br />,
                  `you can ${
                    role === ROLES.systemViewer ? "review" : "review or update"
                  } their configurations.`,
                ]}
                style={{ zIndex: "1000", textAlign: "left" }}
              />
            </div>
            <div
              className="resetSwitchOrg"
              data-cy="resetSwitchOrg"
              onClick={exitAliasMode}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Back to Populi Org
            </div>
          </Banner>

          {/* Data Access Credential Expiration Banner */}
          <Banner
            isVisible={isCredentialExpiring && !isExpirationBannerDismmissed}
            variant={
              lastActiveCredential?.days_to_expire <= 3 ? "error" : "warning"
            }
            onDismiss={handleDismissExpirationBanner}
          >
            <div style={{ marginRight: "5px" }}>
              {lastActiveCredential?.days_to_expire <= 0
                ? "Your Data Access Credential has expired!"
                : `Your Data Access Credential will expire in ${lastActiveCredential?.days_to_expire} days.`}
            </div>

            <div
              onClick={() =>
                navigate(
                  `/admin/org-management/${organizationId}/users/${email}/profile/credentials`
                )
              }
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Get New Credential
            </div>
          </Banner>
          <>
            {showBackLink && backLinkText && backLinkTo ? (
              <>
                <div style={{ marginTop: "30px" }} />
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    top:
                      isCredentialExpiring && !isExpirationBannerDismmissed
                        ? "54px"
                        : "24px",
                    left: "34px",
                    transition: "top 0.25s ease-in-out",
                  }}
                >
                  <SecondaryButton
                    onClick={() => {
                      navigate(backLinkTo, {
                        state: {
                          from: "Organizations",
                        },
                      });
                    }}
                    style={{ display: "flex", width: "fit-content" }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ marginRight: "5px", marginBottom: "-1px" }}
                    />

                    <Typography variant="sub-text" noMargin>
                      {backLinkText}
                    </Typography>
                  </SecondaryButton>
                </div>
              </>
            ) : null}

            <LoadWhileRestoringLocalStorage>
              {children}
            </LoadWhileRestoringLocalStorage>
          </>
        </main>
      </div>
      {/* <AuthExpireModal /> */}
    </>
  );
};

export default ProtectedLayout;
