import { useState } from "react";
import ToastContext from "./ToastContext";

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [percentRemaining, setPercentRemaining] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        setToasts,
        startTimestamp,
        setStartTimestamp,
        percentRemaining,
        setPercentRemaining,
        intervalId,
        setIntervalId,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
