const useHasPermissions = (permissions, rules, dynamicParams = {}) => {
  const checkRules = rules ? [].concat(rules) : null;

  if (!checkRules) {
    return true;
  }

  if (!permissions) {
    return false;
  }

  return permissions.some((permission) => {
    if (typeof permission === "object") {
      return permission.rule(dynamicParams);
    }
    return Boolean(checkRules.includes?.(permission));
  });
};

export default useHasPermissions;
