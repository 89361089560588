import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainHeader, Subtitle } from "./styles";
import Typography from "../Typography";

const PageHeader = ({
  title,
  subtitle = null,
  icon,
  iconBackgroundColor,
  PageActionsComponent,
  swapOpacity = false,
}) => {
  return (
    <MainHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          swapOpacity={swapOpacity}
          style={{
            height: "36px",
            width: "36px",
            padding: "10px",
            marginRight: "15px",
            color: "#fff",
            backgroundColor: iconBackgroundColor,
            borderRadius: "5px",
          }}
        />
        <div>
          <Typography variant="h2" noMargin>
            {title}
          </Typography>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
      </div>
      {PageActionsComponent}
    </MainHeader>
  );
};

export default PageHeader;
