import FourOhFourImage from "assets/404.svg";
import ButtonLink from "core/components/ButtonLink";
import Typography from "core/components/Typography";
import { Row } from "core/styles";

const PageNotFound = ({ hideGoBack = false }) => {
  return (
    <div
      data-cy="error-page"
      style={{
        height: "100%",
        width: "80%",
        margin: "0px auto",
        padding: "15vh 0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <img
        src={FourOhFourImage}
        alt="404 page"
        style={{
          display: "block",
          width: "60%",
          margin: "0px auto",
        }}
      />

      <Typography variant="h2" style={{ textAlign: "center" }}>
        Looks like the page you’re looking for is no longer here or may have
        moved.
      </Typography>

      <Row>
        {!hideGoBack ? (
          <ButtonLink variant="outlined" width="auto" to="-1">
            Go Back
          </ButtonLink>
        ) : null}
        <ButtonLink to="/" width="auto">
          Visit Homepage
        </ButtonLink>
      </Row>
    </div>
  );
};

export default PageNotFound;
