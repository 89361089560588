import Modal from "core/components/Modal";
import { Row } from "core/styles";
import Button from "./Button";
import styled from "styled-components";

const Content = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
`;

const ConfirmModal = ({
  visible = false,
  onYes,
  yesLabel = "Yes",
  onNo,
  noLabel = "No",
  disabled = false,
  isSubmitting = false,
  dismiss,
  title,
  children,
  width = "auto",
}) => {
  return (
    <Modal visible={visible} dismiss={dismiss} title={title} style={{ width }}>
      <Content>{children}</Content>
      <Row style={{ justifyContent: "flex-end", gap: "16px" }}>
        {onNo ? (
          <Button variant="outlined" onClick={onNo} width="auto">
            {noLabel}
          </Button>
        ) : null}
        <Button
          width={onNo ? "auto" : "100%"}
          onClick={onYes}
          isLoading={isSubmitting}
          disabled={disabled || isSubmitting}
        >
          {yesLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmModal;
