import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { useState } from "react";
import { activateUser } from "./actions";
import ConfirmModal from "core/components/ConfirmModal";
import { useQueryClient } from "@tanstack/react-query";
import Error from "core/components/Error";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";

const ActivateUserModal = ({ user, close }) => {
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const activate = useAuthenticatedMutation(activateUser);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onNo = () => {
    setError(null);
    close();
  };

  const onYes = async () => {
    setError(null);
    setIsSubmitting(true);

    try {
      await activate({ email: user.email });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toaster.success({ message: "User has been activated" });
      close();
    } catch (err) {
      setError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <ConfirmModal
      visible={!!user}
      dismiss={onNo}
      onNo={onNo}
      onYes={onYes}
      isSubmitting={isSubmitting}
      title="Activate User"
    >
      <Error>{error}</Error>
      <Typography variant="p">
        Are you sure you want to activate{" "}
        <b>{user.name || user.email || "this user"}</b>?
      </Typography>
    </ConfirmModal>
  );
};

export default ActivateUserModal;
