import styled from "styled-components";

export const Wrapper = styled.div`
  .queryBuilder {
    width: fit-content;
    min-width: 100%;
  }

  .queryBuilder-branches .rule::before,
  .queryBuilder-branches .rule::after,
  .queryBuilder-branches .ruleGroup .ruleGroup::before,
  .queryBuilder-branches .ruleGroup .ruleGroup::after {
    border-color: #9f9f9f;
  }

  .ruleGroup {
    background-color: #efefef;
    padding: 10px;
    border-radius: 5px;
    border: none;
    gap: 10px;

    .ruleGroup-header {
      gap: 10px;
    }

    .ruleGroup-body {
      .ruleGroup {
        background-color: #dfdfdf;
        border: 1px solid #9f9f9f;
      }
      .ruleGroup:not(:first-child) {
        margin-top: 10px;

        &::before {
          top: calc(-0.5rem - 12px);
          height: calc(50% + 0.5rem + 14px);
        }
      }
    }

    .rule {
      gap: 5px;
      align-items: flex-start;

      & > div:not(:first-child):not(:nth-child(2)) {
        /* flex-grow: 1; */
      }

      // Remove rule icon
      & > span {
        margin-top: 2px;
      }

      &::before {
        top: -11px;
        height: 25px;
      }

      &::after {
        top: 11px;
        height: calc(100% - 0px);
      }
    }
  }
`;

export const SecondaryButton = styled.div`
  min-width: 50px;
  font-size: 12px;
  font-family: Inter;
  text-align: center;
  color: #541299;
  padding: 5px 6px;
  padding-top: 4px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f0e9f0;
  }

  &:focus {
    border: 1px solid #007eac;
    outline: none;
  }
`;

export const StyledIconContainer = styled.span`
  cursor: pointer;
  border-radius: 100%;
  padding: 2px 5px;

  &:hover {
    background-color: #f5f2fc;
  }

  ${(props) =>
    props.size === "sm" &&
    `
    padding: 0px 3.5px;
  `}
`;

export const StyledRemoveButton = styled(StyledIconContainer)`
  color: #706e6b;
  margin-left: -2px;
  margin-bottom: -1px;
`;
