import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons";

import Toggle from "core/components/Toggle";
import Typography from "core/components/Typography";

const SettingsGear = ({ disabled, path }) => {
  const navigate = useNavigate();

  return (
    <FontAwesomeIcon
      icon={faGear}
      style={{
        color: "#3e3e3c",
        width: "19px",
        height: "20px",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={() => {
        if (!disabled) {
          navigate(path, { state: { from: "route" } });
        }
      }}
    />
  );
};

const ProductItem = (props) => {
  const {
    toggleId,
    name,
    description,
    productPath,
    isEnabled,
    handleEnabledChange,
    maxControlsCount,
    controlsConfiguredCount = 0,
    productHasError = false,
    isReadOnly = false,
  } = props;

  const backgroundColor = productHasError
    ? "#ff2a00"
    : controlsConfiguredCount
    ? "#000000"
    : "#cccccc";

  return (
    <li>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "7px",
            }}
          >
            <Typography variant="h5" mb="xs" color="black">
              {name}
            </Typography>
            {maxControlsCount > 0 && (
              <div
                style={{
                  backgroundColor,
                  fontSize: "12px",
                  borderRadius: "16px",
                  color: "#ffffff",
                  fontWeight: "normal",
                  padding: "2px 8px",
                  height: "fit-content",
                  marginBottom: "5px",
                }}
              >
                {controlsConfiguredCount}/{maxControlsCount} Configured
              </div>
            )}
          </div>
          <Typography variant="p">{description}</Typography>
        </div>
        <div
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SettingsGear path={productPath} disabled={isReadOnly} />

          <Toggle
            id={toggleId}
            value={isEnabled}
            onChange={(isChecked) => handleEnabledChange(toggleId, isChecked)}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
