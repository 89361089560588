import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextWithMark } from "./styles";
import { formatSpecialCharacters } from "common/utilities";

const commonStyle = {
  flexGrow: 1,
  overflowX: "clip",
  textOverflow: "ellipsis",
};

const MatchableText = ({
  children = "",
  matchString = "",
  placeholder = "",
}) => {
  if (!children) {
    return <i style={commonStyle}>{placeholder}</i>;
  }

  if (!matchString) {
    return (
      <span title={children} style={commonStyle}>
        {children}
      </span>
    );
  }

  const formattedMatchString = formatSpecialCharacters(matchString);

  const matches = children.replace(
    new RegExp(formattedMatchString, "gi"),
    (match) => {
      return `<mark>${match}</mark>`;
    }
  );

  return (
    <TextWithMark
      title={children}
      dangerouslySetInnerHTML={{ __html: matches }}
    />
  );
};

export const SearchableTableCell = ({
  searchConfig,
  columnId,
  placeholder = "",
  children,
  ...rest
}) => {
  const isComponentInDefaultMode = !!rest.table;
  const config = isComponentInDefaultMode
    ? rest.table.options.meta.searchConfig
    : searchConfig;
  const id = isComponentInDefaultMode ? rest.column.id : columnId;

  const isColumnSearchable = !!config.columns?.includes(id);
  const matchString = isColumnSearchable ? config.searchValue : "";

  if (isComponentInDefaultMode) {
    return (
      <MatchableText matchString={matchString} placeholder={placeholder}>
        {rest.getValue()}
      </MatchableText>
    );
  }

  return (
    <MatchableText matchString={matchString} placeholder={placeholder}>
      {children}
    </MatchableText>
  );
};

export const TextWithIconTableCell = ({
  position = "left",
  icon,
  primaryColor,
  iconBackgroundColor,
  children,
}) => {
  const baseIconStyles = {
    height: "16px",
    width: "20px",
    color: primaryColor,
    padding: "3px",
    flexShrink: 0,
    flexGrow: 0,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {position === "left" && (
        <FontAwesomeIcon
          icon={icon}
          style={{
            ...baseIconStyles,
            marginRight: "10px",
          }}
        />
      )}
      {children}
      {position === "right" && (
        <FontAwesomeIcon
          icon={icon}
          style={{
            ...baseIconStyles,
            marginLeft: "10px",
          }}
        />
      )}
    </div>
  );
};

export const IconTableCell = ({
  icon,
  iconAlt,
  primaryColor,
  secondaryColor,
  styles,
}) => {
  const baseIconStyles = {
    aspectRatio: "17.65 / 20",
    height: "20px",
    padding: "3px",
    borderRadius: "4px",
    "--fa-primary-color": primaryColor || "default",
    "--fa-secondary-color": secondaryColor || primaryColor || "default",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
      title={iconAlt}
    >
      <FontAwesomeIcon
        icon={icon}
        alt={iconAlt}
        style={{
          ...baseIconStyles,
          ...styles,
        }}
      />
    </div>
  );
};
