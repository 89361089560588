import axios from "axios";

import Utils from "./utilities";

const API = axios.create({
  baseURL: Utils.getApiUrlPrefix(),
});

const transformAxiosResponse = (response) => {
  if (response.data) {
    if (response.data.data) {
      const { data, ...rest } = response.data;
      return {
        rows: data,
        ...rest,
      };
    }
    return response.data;
  }

  return response;
};

const catchAxiosError = (error) => {
  console.log("catchApiError", { error });

  if (error.response) {
    // Matt: Sometimes the error is returned as a string under data
    // Matt: API team notified, they are working to resolve
    if (typeof error.response.data === "string") {
      throw Error(error.response.data);
    }

    // Sometimes the error is returned as an object with a message prop
    if (error.response.data?.message) {
      // Array of messages for validation
      if (error.response.data.details?.length) {
        throw error.response.data.details.map((detail) => {
          if (detail.param) {
            return `${detail.msg} for ${detail.param}`;
          } else {
            return `${detail.msg}`;
          }
        });
      }

      // Need message and resetLink if expired password error
      if (error.config?.url === "/login" && error.response.data.resetLink) {
        throw error.response.data;
      }

      throw error.response.data.message;
    }

    if (error.message) {
      throw Error(error.message);
    }

    if (error.response.data.errors) {
      throw Error(error.response.data.errors.map((e) => e.msg).join(", "));
    }

    throw Error(`${error.response.status} http status received`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
};

API.interceptors.response.use(transformAxiosResponse, catchAxiosError);

export default API;
