import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { StyledInput } from "core/components/Form";

const Search = ({
  name,
  value,
  onChange,
  placeholder,
  styles = { container: {}, input: {}, cancelButton: {}, icon: {} },
  autoFocus = false,
}) => {
  return (
    <div style={{ width: "250px", position: "relative", ...styles.container }}>
      <StyledInput
        type="search"
        field={name || "search"}
        name={name || "search"}
        placeholder={placeholder || "Search by..."}
        value={value}
        onChange={onChange}
        style={{ ...styles.input }}
        cancelButtonStyles={styles.cancelButton}
        autoFocus={autoFocus}
      />
      {value === "" && (
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{
            position: "absolute",
            right: "15px",
            top: "14px",
            width: "14px",
            height: "14px",
            color: "#706E6B",
            fontWeight: "light",
            ...styles.icon,
          }}
        />
      )}
    </div>
  );
};

export default Search;
