import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import PropTypes from "prop-types";

const CopyIcon = styled(FontAwesomeIcon)`
  opacity: 0.8;
  color: #541299;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover {
    opacity: 1;
  }
`;

export const CopyButton = ({
  value,
  hoverTitle = "Copy",
  successTitle = "Success!",
  tooltipDirection = "right",
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <span
      id="copyIcon"
      style={{
        display: "inline-block",
        position: "relative",
        marginLeft: "5px",
        cursor: "pointer",
      }}
      onClick={handleCopyClick}
    >
      <Tooltip
        anchorSelect="#copyIcon"
        place={tooltipDirection}
        positionStrategy="fixed"
        style={{
          transition: "background-color 150ms linear",
          ...(copySuccess ? { backgroundColor: "#008844" } : {}),
        }}
      >
        {copySuccess ? successTitle : hoverTitle}
      </Tooltip>
      <CopyIcon icon={faCopy} size="lg" />
    </span>
  );
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  hoverTitle: PropTypes.string,
  successTitle: PropTypes.string,
};

export default CopyButton;
