import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";

import Chip from "../Chip";
import {
  NumberFieldContainer,
  NumberIcon,
  NumberIconContainer,
  TextInput,
  InputErrorIcon,
  MultiTextInput,
  MultiContainer,
  ClearValueButton,
} from "./styles";

const isNumber = (value) => typeof value === "number";

const NumberField = (props) => {
  const {
    id,
    value,
    error,
    placeholder,
    onChange,
    setValue,
    min,
    max,
    variant,
    isReadOnly,
    isDisabled,
    isMultiValue = false,
    ...rest
  } = props;
  const inputRef = useRef();
  const [draftValue, setDraftValue] = useState("");

  const handleDecrement = () => {
    if (isDisabled || isReadOnly) {
      return;
    }

    const newValue = (value || 0) - 1;

    if (newValue < min) {
      onChange(Number(min));
    } else {
      onChange(newValue);
    }
  };

  const handleIncrement = () => {
    if (isDisabled || isReadOnly) {
      return;
    }

    const newValue = (value || 0) + 1;

    if (newValue > max) {
      onChange(Math.min());
    } else {
      onChange(newValue);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;

    // Allow empty number field
    if (newValue === "") {
      return onChange(event.target.value);
    }

    return onChange(Number(event.target.value));
  };

  if (isMultiValue) {
    return (
      <div style={{ position: "relative" }}>
        <MultiContainer
          isDisabled={isDisabled}
          hasError={!!error}
          variant={variant}
          onClick={() => inputRef?.current.focus()}
        >
          {value.length > 0 && (
            <ClearValueButton
              variant={variant}
              hasError={!!error}
              onClick={() => {
                onChange([]);
                inputRef?.current.focus();
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </ClearValueButton>
          )}

          {value.map((singleValue, index) => {
            return (
              <Chip
                key={index}
                tabIndex={index + 1}
                text={String(singleValue)}
                variant="actionable"
                isDisabled={isDisabled}
                size={variant}
                onDismiss={() => {
                  // Remove value from value list
                  onChange(value.filter((v, i) => i !== index));
                  inputRef?.current.focus();
                }}
              />
            );
          })}

          <MultiTextInput
            id={id}
            name={id}
            tabIndex={value.length + 2}
            type="number"
            variant={variant}
            placeholder={Boolean(draftValue) ? "" : placeholder}
            value={draftValue}
            ref={inputRef}
            disabled={isDisabled}
            readOnly={isReadOnly}
            onChange={(event) => {
              const newValue = event.target.value;

              // Allow empty number field
              if (newValue === "") {
                return setDraftValue(event.target.value);
              }

              return setDraftValue(Number(event.target.value));
            }}
            onKeyDown={(event) => {
              // When user hits backspace
              if (event.key === "Backspace") {
                // Check to make sure input has value
                const inputHasValue = isNumber(draftValue);

                if (!inputHasValue) {
                  // Remove last value chip
                  onChange(value.toSpliced(-1, 1));
                }
              }

              // When user hits enter
              if (event.key === "Enter") {
                // Check to make sure input has value
                const inputHasValue = isNumber(draftValue);

                if (inputHasValue) {
                  // Pass up draft value to field instance
                  onChange(value.concat(draftValue));

                  // Reset draft input value
                  setDraftValue("");
                }
              }
            }}
            {...rest}
          />
        </MultiContainer>

        {error && (
          <InputErrorIcon icon={faCircleExclamation} variant={variant} />
        )}
      </div>
    );
  }

  return (
    <NumberFieldContainer
      isDisabled={isDisabled}
      hasError={!!error}
      variant={variant}
      onClick={() => inputRef.current.focus()}
    >
      <TextInput
        type="number"
        ref={inputRef}
        id={id}
        name={id}
        placeholder={placeholder}
        readOnly={isReadOnly}
        disabled={isDisabled}
        variant={variant}
        value={value}
        error={error}
        onChange={handleChange}
        {...rest}
      />
      <NumberIconContainer variant={variant}>
        <NumberIcon
          icon={faMinus}
          variant={variant}
          disabled={isDisabled || isReadOnly}
          onClick={handleDecrement}
        />
        <NumberIcon
          icon={faPlus}
          variant={variant}
          disabled={isDisabled || isReadOnly}
          onClick={handleIncrement}
        />
      </NumberIconContainer>
    </NumberFieldContainer>
  );
};

export default NumberField;
