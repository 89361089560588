import React from "react";
import Skeleton from "react-loading-skeleton";

import { TinyIcon } from "core/styles";

import Checkbox from "./Checkbox";
import {
  CheckboxGrid,
  InputGroup,
  InputSkeletonContainer,
  Label,
  CheckboxInputGroup,
  LabelTextWithIcon,
} from "./styles";
import { Tooltip } from "react-tooltip";

export const CheckboxLabelWithIcon = ({ info, icon, children }) => {
  return (
    <LabelTextWithIcon>
      {children}
      <TinyIcon icon={icon} id={children} />
      <Tooltip anchorId={children} html={info} positionStrategy="fixed" />
    </LabelTextWithIcon>
  );
};

const CheckboxGroup = React.forwardRef((props, ref) => {
  if (props.isLoading) {
    return (
      <InputGroup>
        <Label>{props.label}</Label>
        <InputSkeletonContainer>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  return (
    <InputGroup>
      <Label>{props.label}</Label>
      <InputGroup>
        <CheckboxGrid>
          {props.options.map(({ field, label }) => (
            <CheckboxInputGroup key={field}>
              <Checkbox field={field} />
              <Label>{label}</Label>
            </CheckboxInputGroup>
          ))}
        </CheckboxGrid>
      </InputGroup>
      {props.children}
    </InputGroup>
  );
});

export default CheckboxGroup;
