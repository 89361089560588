import styled from "styled-components";
import Button from "../Button";
import { Textarea } from "../Form/styles";

export const ModalHeader = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0px;
  line-height: 1;
`;

export const ModalText = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  max-width: 562px;
  line-height: 1;
`;

export const ModalTextarea = styled(Textarea)`
  height: 317px;
  width: 533px;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;

  ::placeholder {
    color: #9f9f9f;
  }
  ::-webkit-input-placeholder {
    color: #9f9f9f;
  }
  :-ms-input-placeholder {
    color: #9f9f9f;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

export const ModalButton = styled(Button)`
  width: auto;
  min-width: 143px;
  padding: 0.25rem 32px;
`;

export const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
