import styled from "styled-components";

const ProductTitleCard = styled.div`
  background-color: #5f6467;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export default ProductTitleCard;
