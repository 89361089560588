import { useParams } from "react-router-dom";

import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";
import { FormSelect as Select } from "core/components/Form/Select";
import Error from "core/components/Error";
import useCurrentUser from "core/hooks/useCurrentUser";
import { minLength, required, email, validate } from "common/validation";
import {
  FLAG_KEYS,
  REVERSE_LOOKUP_ROLES,
  REVERSE_LOOKUP_TABLEAU_ROLES,
  ROLES,
  TABLEAU_ROLES,
} from "core/constants";
import { LabelWithTooltip } from "core/components/Form";
import BiPortalUserTooltip from "./BiPortalUserTooltip";
import { Radio } from "./Radio";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";

const UserForm = ({
  onSubmit,
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  const params = useParams();
  const { orgId: organizationId } = params;
  const { currentUser } = useCurrentUser();
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitLabel={`${defaultValues?.email ? "Update" : "Create"} User`}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      disabled={[ROLES.orgUser, ROLES.systemViewer].includes(currentUser.role)}
      mode={defaultValues?.name ? "update" : "create"}
    >
      <Error>{error}</Error>
      <Input
        type="text"
        field="name"
        label="Name"
        validate={validate(required(), minLength(2))}
        isLoading={isFetching}
        disabled={[ROLES.orgUser, ROLES.systemViewer].includes(
          currentUser.role
        )}
      />
      <Input
        type="email"
        field="email"
        label="Email"
        validate={validate(required(), email())}
        autoComplete="off"
        readOnly={!!defaultValues?.email}
        disabled={!!defaultValues?.email}
      />
      <Input
        type="email"
        field="alternateEmail"
        label="Alternate Email"
        validate={validate(email())}
        autoComplete="off"
        disabled={[ROLES.orgUser, ROLES.systemViewer].includes(
          currentUser.role
        )}
      />
      <Select
        field="role"
        label="Role"
        validate={validate(required())}
        isLoading={isFetching}
        disabled={[ROLES.orgUser, ROLES.systemViewer].includes(
          currentUser.role
        )}
        options={[
          ...(organizationId === "populi" &&
          currentUser.role === ROLES.systemAdmin
            ? [
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemAdmin],
                  label: ROLES.systemAdmin,
                },
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemViewer],
                  label: ROLES.systemViewer,
                },
              ]
            : []),
          ...(organizationId === "populi" &&
          currentUser.role === ROLES.systemViewer
            ? [
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemViewer],
                  label: ROLES.systemViewer,
                },
              ]
            : []),
          {
            value: REVERSE_LOOKUP_ROLES[ROLES.orgAdmin],
            label: ROLES.orgAdmin,
          },
          {
            value: REVERSE_LOOKUP_ROLES[ROLES.orgUser],
            label: ROLES.orgUser,
          },
        ]}
      />
      <Select
        field="tableauConfig.tableauLicense"
        label={
          <LabelWithTooltip info={<BiPortalUserTooltip />}>
            BI Portal User
          </LabelWithTooltip>
        }
        validate={validate()}
        isLoading={isFetching}
        placeholder="None"
        disabled={[ROLES.orgUser].includes(currentUser.role)}
        options={[
          {
            value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.viewer],
            label: TABLEAU_ROLES.viewer,
          },
          {
            value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.explorer],
            label: TABLEAU_ROLES.explorer,
          },
          ...(organizationId === "populi"
            ? [
                {
                  value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.creator],
                  label: TABLEAU_ROLES.creator,
                },
              ]
            : []),
          {
            value: "",
            label: "None",
          },
        ]}
      />
      {hasDataAccessFeature && (
        <Radio
          field="direct_data_access_enabled"
          group="user-data-accessibility"
          label="Enable Direct Data Access?"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      )}
    </Form>
  );
};

export default UserForm;
