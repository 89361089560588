import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const Link = styled(RouterLink)`
  text-decoration: none;
  color: #541299;

  :hover {
    text-decoration: underline;
  }
`;

export default Link;
