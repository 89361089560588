import React from "react";

import { Select } from "core/components/Form/Select";

const Filter = (props) => {
  const { name, value, onChange, autoFocus } = props;

  return (
    <Select
      name={name || "select"}
      options={props.options}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      hideError={true}
      styles={{
        container: (baseStyles, state) => {
          return {
            ...baseStyles,
            height: "auto",
            padding: "2px 0px",
          };
        },
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            minHeight: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "24px",
          };
        },
        menu: (baseStyles, state) => {
          return {
            ...baseStyles,
          };
        },
        menuList: (baseStyles, state) => {
          return {
            ...baseStyles,
          };
        },
        dropdownIndicator: (baseStyles, state) => {
          return {
            ...baseStyles,
            padding: "0px",
            paddingRight: "2px",
          };
        },
      }}
    />
  );
};

export default Filter;
