import Modal from "../Modal";
import BulkPasteEditor from "./BulkPasteEditor";
import BulkPasteValidator from "./BulkPasteValidator";
import useBulkPaste from "./useBulkPaste";

const BulkPasteModal = () => {
  const { modalVisible, validationVisible, hideModal } = useBulkPaste();

  return (
    <Modal visible={modalVisible} dismiss={hideModal}>
      {validationVisible ? <BulkPasteValidator /> : <BulkPasteEditor />}
    </Modal>
  );
};

export default BulkPasteModal;
