import { useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney,
  faPeopleRoof,
  faCircleQuestion,
  faUser,
  faCode,
  faChevronRight,
  faChartColumn,
  faBuildingUser,
  faCity,
} from "@fortawesome/pro-regular-svg-icons";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getOrganizationById, getUsers } from "modules/admin/actions";

import { ROLES, REVERSE_LOOKUP_TABLEAU_ROLES } from "core/constants";

import { Tooltip } from "react-tooltip";
import { useQuery } from "@tanstack/react-query";
import Popover from "./Popover.js";
import Typography from "./Typography/Typography.js";
import useNavigation from "core/hooks/useNavigation.js";
import useCurrentUser from "../hooks/useCurrentUser.js";

const collapsedWidth = "64px";
const expandedWidth = "264px";

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
  padding: 8px 15px;
  justify-content: flex-start;

  span {
    overflow: hidden;
    line-height: 20px;
  }

  &:hover {
    text-decoration: none;
    background-color: #543f70;
  }

  ${(props) =>
    props.$isActive &&
    `
    background-color: #543f70;
    font-weight: 600;

    &:hover {
      background-color: #543f70;
    }
    `}

  ${(props) =>
    props.$isNavClosed &&
    `
    justify-content: center;
  `}
`;

const DrawerAside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2a0f4d;
  width: ${collapsedWidth};
  height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  transition: width 250ms ease-in-out;

  ${(props) =>
    props.$isOpen &&
    `
    width: ${expandedWidth};
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
  `}
`;

const ExternalLink = styled(NavLink)`
  padding: 8px 15px;
`;

const MainNav = styled.nav`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const NavToggle = styled.div`
  position: absolute;
  top: 52px;
  right: -13px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  background-color: #785c96;
  border-radius: 9999px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  transition-delay: 100ms;

  &:hover {
    /* background-color: #ffffff26; */
  }

  ${(props) =>
    props.$isNavOpen &&
    `
      transform: rotate(180deg);
  `}
`;

const ProfileMenu = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 8px 4px;

  &:hover {
    background-color: #543f70;
  }

  ${(props) =>
    props.$isNavOpen &&
    `
    justify-content: flex-start;
    gap: 12px;
    padding: 8px 10px;
    align-items: flex-start;

    svg {
        margin-top: 1px;
    }
  `}
`;

const useOrganizationByIdQuery = ({ id }) => {
  const fetchQueryCall = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId: id })
  );

  const { data } = useQuery({
    queryKey: ["organization", id],
    queryFn: fetchQueryCall,
    // onError: (error) => {
    //   if (Array.isArray(error)) {
    //     toaster.error({ message: error[0] });
    //   } else {
    //     toaster.error({ message: error.message });
    //   }
    // },
  });

  return data;
};

const useUserByEmailQuery = ({ id, email }) => {
  const fetchQueryCall = useAuthenticatedCall((req) =>
    getUsers({ ...req, organizationId: id, email })
  );

  const { data } = useQuery({
    queryKey: ["user", id],
    queryFn: fetchQueryCall,
    // onError: (error) => {
    //   if (Array.isArray(error)) {
    //     toaster.error({ message: error[0] });
    //   } else {
    //     toaster.error({ message: error.message });
    //   }
    // },
  });

  return data?.[0];
};

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { currentUser: user } = useCurrentUser();
  const { orgId: orgIdParam } = useParams();
  const { isNavOpen, setNavOpen } = useNavigation();
  const isClosed = !isNavOpen;

  const locationContainsConfig = location.pathname.split("/")[1] === "config";
  const { role, name, email, organizationId, orgName } = user;

  const userOrganization = useOrganizationByIdQuery({ id: organizationId });
  const userData = useUserByEmailQuery({ id: organizationId, email });

  const hasBiPortalLicense = useMemo(
    () =>
      userData?.tableauConfig?.tableauLicense &&
      Object.values(REVERSE_LOOKUP_TABLEAU_ROLES).includes(
        userData?.tableauConfig?.tableauLicense
      ),
    [userData]
  );
  const biPortalUrl = useMemo(
    () => userOrganization?.onboarding?.bi?.portal_url,
    [userOrganization]
  );
  const biPortal = useMemo(
    () =>
      userData && userOrganization // user and org has loaded
        ? hasBiPortalLicense && biPortalUrl
          ? {
              // valid bi portal link and user license
              disabled: false,
              errorMessage: null,
            }
          : {
              // invalid
              disabled: true,
              errorMessage: !hasBiPortalLicense
                ? "You do not have a BI Portal license assigned." // no BI licence for user
                : "The BI Portal is not set up for your userOrganization.", // no BI portal link for org
            }
        : {
            // user and/or org not loaded yet, disable button
            disabled: true,
            errorMessage: null,
          },
    [userData, userOrganization, hasBiPortalLicense, biPortalUrl]
  );

  const selectedOrganizationId = useMemo(() => {
    return orgIdParam || organizationId;
  }, [orgIdParam, organizationId]);

  const routes = [
    {
      to: "/",
      name: "Home",
      icon: faHouseChimney,
    },
    {
      to: `/config/${user.organizationId}/managed-providers`,
      name: "Managed Providers",
      permissions: "configurations:read",
      icon: faPeopleRoof,
    },
    {
      to: "/admin/org-management",
      name: "Organizations",
      permissions: "org-management:read",
      icon: faCity,
      exact: true,
    },
    {
      to: `/admin/org-management/${user.organizationId}`,
      name: "My Organization",
      permissions: "user-management:read",
      icon: faBuildingUser,
    },
  ];

  const {
    setUserSearchValue,
    setOrgSearchValue,
    setCurrentUserPageNumber,
    setCurrentOrgPageNumber,
  } = useNavigation();

  const onRouteChange = () => {
    setOrgSearchValue("");
    setUserSearchValue("");
    setCurrentUserPageNumber(0);
    setCurrentOrgPageNumber(0);
  };

  return (
    <DrawerAside $isOpen={isNavOpen}>
      <NavToggle onClick={() => setNavOpen(!isNavOpen)} $isNavOpen={isNavOpen}>
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ color: "#ffffff", width: "15px" }}
        />
      </NavToggle>
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: isNavOpen ? "flex-start" : "center",
          marginBottom: "30px",
          textAlign: "center",
          color: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isNavOpen ? (
            <img src="/dh-logo-white.svg" alt="logo" height="31px" />
          ) : (
            <img src="/dh-logo-mark.svg" alt="logo" height="31px" />
          )}
        </div>
      </div>
      <MainNav>
        <div>
          {routes
            .filter(
              (route) =>
                !route.permissions ||
                user.permissions.includes(route.permissions)
            )
            .map(({ to, name, icon, exact }) => {
              const isActive =
                to === "/"
                  ? location.pathname === to
                  : exact
                  ? location.pathname === to
                  : location.pathname.includes(to);

              const id = name.split(" ").join("");

              return (
                <NavLink
                  key={id}
                  id={id}
                  to={to}
                  $isActive={isActive}
                  $isNavClosed={isClosed}
                  onClick={onRouteChange}
                  state={{ from: id }}
                >
                  {isNavOpen ? (
                    <>
                      <span
                        style={{
                          textAlign: "center",
                          marginRight: "15px",
                          minWidth: "25px",
                        }}
                      >
                        <FontAwesomeIcon icon={icon} size="lg" />
                      </span>
                      <span>{name}</span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={icon} size="lg" />
                      <Tooltip
                        anchorSelect={`#${id}`}
                        children={name}
                        place="right"
                        positionStrategy="absolute"
                        offset={20}
                        style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                      />
                    </>
                  )}
                </NavLink>
              );
            })}
        </div>

        {/* Bottom Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {!biPortal.disabled && (
            <ExternalLink
              id="biPortal"
              as="a"
              href={biPortalUrl}
              target="_blank"
              rel="noreferrer"
              $isNavClosed={isClosed}
            >
              {isNavOpen ? (
                <>
                  <span style={{ marginRight: "15px", minWidth: "25px" }}>
                    <FontAwesomeIcon icon={faChartColumn} size="lg" />
                  </span>
                  <span>BI Portal</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faChartColumn} size="xl" />
                  <Tooltip
                    anchorSelect="#biPortal"
                    children="BI Portal"
                    place="right"
                    positionStrategy="absolute"
                    offset={20}
                    style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                  />
                </>
              )}
            </ExternalLink>
          )}

          <ExternalLink
            id="apiDocs"
            as="a"
            href="https://api.populi.ai"
            target="_blank"
            rel="noreferrer"
            $isNavClosed={isClosed}
          >
            {isNavOpen ? (
              <>
                <span style={{ marginRight: "15px", minWidth: "25px" }}>
                  <FontAwesomeIcon icon={faCode} size="lg" />
                </span>
                <span>API Docs</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCode} size="lg" />
                <Tooltip
                  anchorSelect="#apiDocs"
                  children="API Docs"
                  place="right"
                  positionStrategy="absolute"
                  offset={20}
                  style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                />
              </>
            )}
          </ExternalLink>

          <ExternalLink
            id="supportPortal"
            as="a"
            href="https://support.populi.ai/hc/en-us"
            target="_blank"
            rel="noreferrer"
            $isNavClosed={isClosed}
          >
            {isNavOpen ? (
              <>
                <span style={{ marginRight: "15px", minWidth: "25px" }}>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </span>
                <span>Support Portal</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCircleQuestion} size="xl" />
                <Tooltip
                  anchorSelect="#supportPortal"
                  children="Support Portal"
                  place="right"
                  positionStrategy="absolute"
                  offset={20}
                  style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                />
              </>
            )}
          </ExternalLink>

          <Popover
            placement="right-end"
            offset={20}
            navProfilePopover={true}
            userInfo={user}
            isNavOpen={isNavOpen}
            TriggerComponent={(props) => {
              const { setRef, ...triggerProps } = props;

              return (
                <>
                  <ProfileMenu
                    id="profileMenu"
                    $isNavOpen={isNavOpen}
                    data-cy="profileMenuButton"
                    ref={setRef}
                    {...triggerProps}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        minWidth: "18px",
                        width: "18px",
                        padding: "7px 6px",
                        color: "#fff",
                        backgroundColor: "#00C1F3",
                        borderRadius: "3px",
                      }}
                    />

                    {isNavOpen && (
                      <div
                        style={{
                          height: "48px",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Typography variant="h6" color="white" noMargin>
                          {name || email}
                        </Typography>

                        <Typography variant="sub-text" color="white" noMargin>
                          {orgName}
                        </Typography>

                        <Typography variant="sub-text" color="white" noMargin>
                          {role}
                        </Typography>
                      </div>
                    )}
                  </ProfileMenu>
                  <Tooltip
                    anchorSelect="#profileMenu"
                    children="Settings"
                    place="right"
                    positionStrategy="absolute"
                    offset={20}
                    style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                    hidden={
                      isNavOpen || triggerProps["aria-expanded"] === "true"
                    }
                  />
                </>
              );
            }}
            actions={[
              {
                name: "View Profile",
                onClick: (event, setPopoverOpen) => {
                  navigate(
                    `/admin/org-management/${user.organizationId}/users/${email}/profile/general`,
                    {
                      state: {
                        from:
                          location?.state?.from || window.history.state.from,
                        orgId: location?.state?.orgId,
                        userEmail: location?.state?.userEmail,
                      },
                    }
                  );
                  setPopoverOpen(false);
                },
              },
              ...([ROLES.systemAdmin, ROLES.systemViewer].includes(role)
                ? [
                    ...(locationContainsConfig
                      ? [
                          {
                            name: "Switch Organization",
                            dataCy: "switchOrgMenuLink",
                            onClick: (event, setPopoverOpen) => {
                              navigate(
                                `/admin/switch-org/${selectedOrganizationId}`,
                                {
                                  state: {
                                    fromPath: location.pathname,
                                  },
                                }
                              );
                              setPopoverOpen(false);
                            },
                          },
                        ]
                      : []),
                    {
                      name: [ROLES.systemViewer].includes(role)
                        ? "View Organizations"
                        : "Manage Organizations",
                      onClick: (event, setPopoverOpen) => {
                        navigate("/admin/org-management");
                        setPopoverOpen(false);
                      },
                    },
                  ]
                : []),
              ...([ROLES.orgAdmin].includes(role)
                ? [
                    {
                      name: "My Organization",
                      onClick: (event, setPopoverOpen) => {
                        navigate(
                          `/admin/org-management/${user.organizationId}`
                        );
                        setPopoverOpen(false);
                      },
                    },
                  ]
                : []),
              {
                name: "Logout",
                dataCy: "logoutMenuLink",
                onClick: () => {
                  auth.removeUser();
                  auth.signoutRedirect();
                },
              },
            ]}
          />
        </div>
      </MainNav>
    </DrawerAside>
  );
};

export default Navigation;
