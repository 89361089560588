import { useState } from "react";
import FlashContext from "./FlashContext";

const FlashProvider = ({ children, settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  return (
    <FlashContext.Provider value={{ success, setSuccess, error, setError }}>
      {children}
    </FlashContext.Provider>
  );
};

export default FlashProvider;
