import { createGlobalStyle } from "styled-components";
import "@fontsource/source-code-pro";

import "react-datepicker/dist/react-datepicker.css";
import "./core/components/DatePicker/styles.css";

const GlobalStyle = createGlobalStyle`
html, body, #root {
  height: 100%;
  overflow: hidden;
}

html {
  background-color: #2f2e41;
}

body {
  color: #3E3E3C;
  background-color: ${(props) => props.theme.app.backgroundColor};
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  color: ${(props) => props.theme.dh_colors.purple_50};
  font-size: 16px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
}

:root {
  --rt-color-white:  ${(props) => props.theme.dh_colors.white} !important;
  --rt-color-dark: ${(props) => props.theme.dh_colors.purple_80} !important;
  --rt-opacity: 1 !important;
}
`;

export default GlobalStyle;
