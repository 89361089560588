import { DateTime } from "luxon";

import { DATE_FORMATS } from "./constants";

export const stringToDate = (type, str, isEditing = false) => {
  if (!str) {
    return null;
  }

  const dateFormat = DATE_FORMATS[type];

  if (type === "quarter" && isEditing) {
    const [start, end] = str.split(" - ");
    const endDateTime = end ? DateTime.fromFormat(end, dateFormat) : null;

    // dont parse as valid if editing end date
    // (or else backspace can't delete start date)
    if (!endDateTime?.isValid) {
      return null;
    }

    str = start;
  }

  const dateTime = DateTime.fromFormat(str, dateFormat);

  if (!dateTime.isValid) {
    return null;
  }

  // be sure date is the start of the quarter
  if (
    type === "quarter" &&
    dateTime?.isValid &&
    (dateTime.month - 1) % 3 !== 0
  ) {
    return dateTime.set({
      month: Math.floor((dateTime.month - 1) / 3) * 3 + 1,
    });
  }

  return dateTime;
};

export const dateToString = (type, dateTime) => {
  if (!dateTime?.isValid) {
    return "";
  }

  const dateFormat = DATE_FORMATS[type];

  // include end of quarter
  if (type === "quarter") {
    const startStr = dateTime.toFormat(dateFormat);
    const endStr = dateTime.plus({ months: 2 }).toFormat(dateFormat);

    return `${startStr} - ${endStr}`;
  }

  return dateTime.toFormat(dateFormat);
};
