import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { NextButton, PaginationContainer, PreviousButton } from "./styles";

const ServerSidePaginator = (props) => {
  const {
    prevPageToken,
    nextPageToken,
    handlePreviousClick,
    handleNextClick,
    isRefreshing,
  } = props;

  return (
    <PaginationContainer>
      {isRefreshing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faCircleNotch} spin />
          <span style={{ marginLeft: "8px" }}>Loading</span>
        </div>
      ) : null}

      <PreviousButton
        onClick={handlePreviousClick}
        disabled={!prevPageToken || isRefreshing}
      >
        <FontAwesomeIcon icon={faCaretLeft} size="lg" />
      </PreviousButton>
      <NextButton
        onClick={handleNextClick}
        disabled={!nextPageToken || isRefreshing}
      >
        <FontAwesomeIcon icon={faCaretRight} size="lg" />
      </NextButton>
    </PaginationContainer>
  );
};

export default ServerSidePaginator;
