import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import Error from "core/components/Error";
import ConfirmModal from "core/components/ConfirmModal";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { activateOrganization } from "./actions";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";

const ActivateOrganizationModal = ({ organization, close }) => {
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const mutation = useAuthenticatedMutation(activateOrganization);

  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onNo = () => {
    setError(null);
    close();
  };

  const onYes = async () => {
    setError(null);
    setIsSubmitting(true);

    try {
      await mutation({ organizationId: organization.id });
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      toaster.success({ message: "Organization has been activated" });
      close();
    } catch (err) {
      setError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <ConfirmModal
      visible={!!organization}
      onNo={onNo}
      onYes={onYes}
      isSubmitting={isSubmitting}
      dismiss={onNo}
      title="Activate Organization"
    >
      <Error>{error}</Error>
      <Typography variant="p">
        Are you sure you want to activate{" "}
        <b>{organization.name || "this organization"}</b>?
      </Typography>
    </ConfirmModal>
  );
};

export default ActivateOrganizationModal;
