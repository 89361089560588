import { useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import z from "zod";

import { createPassword } from "modules/auth/actions";
import useFlash from "core/hooks/useFlash";
import useCurrentUser from "core/hooks/useCurrentUser";
import UnifiedAuthPageWrapper from "core/components/UnifiedAuthPageWrapper";
import Link from "core/components/Link";
import { Fieldset } from "core/components/Form/styles";
import { newPasswordZodValidator } from "common/validation";

const formSchema = z
  .object({
    newPassword: z.string().superRefine(newPasswordZodValidator),
    confirmPassword: z.string(),
    usernameParts: z.array(z.string()),
  })
  .superRefine((data, context) => {
    const passwordContainsUsername = data.usernameParts.some((usernamePart) => {
      return data.newPassword.toLowerCase().includes(usernamePart);
    });
    if (passwordContainsUsername) {
      context.addIssue({
        message: "Must not contain your username in any form.",
        path: ["newPassword"],
      });
    }

    if (data.newPassword !== data.confirmPassword) {
      context.addIssue({
        message: "Passwords must match.",
        path: ["confirmPassword"],
      });
    }
  });

const CreatePassword = () => {
  const { setError: setFlashError, setSuccess } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsPasswordExpired } = useCurrentUser();

  const email = searchParams.get("email")?.split(" ").join("+");
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const origin = searchParams.get("origin");

  const usernameParts = useMemo(() => {
    const lowerEmail = email.split("@")[0].toLowerCase();
    const emailSplitIntoFourCharacters = lowerEmail.split("");

    return emailSplitIntoFourCharacters.reduce(
      (finalList, currentValue, currentIndex, originalArray) => {
        if (currentIndex > originalArray.length - 4) {
          return finalList;
        }

        return finalList.concat(
          originalArray.slice(currentIndex, currentIndex + 4).join("")
        );
      },
      []
    );
  }, [email]);

  const [fields, setFields] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFields((previousValues) => {
      const updatedFormValues = {
        ...previousValues,
        [name]: value,
      };

      const validatedForm = formSchema.safeParse({
        ...updatedFormValues,
        usernameParts,
      });

      if (validatedForm.success) {
        setFieldErrors({
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        const flattenedFieldErrors = validatedForm.error.flatten((issue) => {
          return issue.message;
        }).fieldErrors;

        const fieldErrorKeys = Object.keys(fieldErrors);
        const updatedErrors = fieldErrorKeys.reduce(
          (errorMap, fieldErrorKey) => {
            // Don't set error unless field has a value (isTouched behavior)
            const isFieldTouched = !!updatedFormValues[fieldErrorKey];

            // If field has error, pluck first error off of issue list, or clear error
            errorMap[fieldErrorKey] =
              flattenedFieldErrors[fieldErrorKey] && isFieldTouched
                ? flattenedFieldErrors[fieldErrorKey][0]
                : "";
            return errorMap;
          },
          {}
        );

        setFieldErrors(updatedErrors);
      }

      return updatedFormValues;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError("");
    setIsLoading(true);

    try {
      const result = await createPassword({
        ...fields,
        mode,
        oobCode,
        email,
      });

      if (origin && origin === "tableau") {
        window.location.replace("https://tableau.populi.ai");
      } else {
        setSuccess(result.message);
        setIsPasswordExpired(false);
        setFlashError(null);
        navigate("/");
      }
    } catch (error) {
      setFormError(error);
    }

    setIsLoading(false);
  };

  const isFormValid = useMemo(() => {
    return formSchema.safeParse({
      ...fields,
      usernameParts,
    }).success;
  }, [fields, usernameParts]);

  return (
    <UnifiedAuthPageWrapper>
      <div className="form-wrapper">
        <div className="main-header">
          <h1 className="main-header__heading">Create password</h1>
          <p>Password must be least 15 characters in length.</p>
        </div>

        <form id="login-form" onSubmit={handleSubmit}>
          <Fieldset disabled={isLoading}>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="newPassword" className="input__label">
                  New Password
                </label>

                {fieldErrors.newPassword && (
                  <span id="password-error" className="input-error is-errored">
                    {fieldErrors.newPassword}
                  </span>
                )}
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter new password"
                  autoComplete="new-password"
                  required
                  onChange={handleInputChange}
                  value={fields.newPassword}
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  tabIndex="-1"
                  onClick={(event) => {
                    const field = event.target.form.newPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="confirmPassword" className="input__label">
                  Confirm Password
                </label>

                {fieldErrors.confirmPassword && (
                  <span
                    id="confirmPassword-error"
                    className="input-error is-errored"
                  >
                    {fieldErrors.confirmPassword}
                  </span>
                )}
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm new password"
                  autoComplete="new-password"
                  required
                  onChange={handleInputChange}
                  value={fields.confirmPassword}
                />

                <button
                  type="button"
                  id="toggle-confirmPassword"
                  className="password__toggle"
                  tabIndex="-1"
                  onClick={(event) => {
                    const field = event.target.form.confirmPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>

            {formError && (
              <div id="form-error" className="form-error is-errored">
                {formError}
              </div>
            )}

            <div className="form-section">
              <button className="button--primary" disabled={!isFormValid}>
                Create password
              </button>
            </div>

            <div className="form-extras">
              <div className="help-links">
                <Link to="/">Back to login</Link>
              </div>
            </div>
          </Fieldset>
        </form>
      </div>
    </UnifiedAuthPageWrapper>
  );
};

export default CreatePassword;
