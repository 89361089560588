import "assets/reset.css";
import "assets/login.css";

const Page = ({ showBackground = true, children }) => {
  return (
    <div className="content">
      <header className="header">
        <div className="header__logo">
          <a href="https://www.definitivehc.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="152"
              height="31.127"
              viewBox="0 0 152 31.127"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Path_499"
                    data-name="Path 499"
                    d="M0-103.341H152v-31.128H0Z"
                    transform="translate(0 134.469)"
                    fill="#531295"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_959"
                data-name="Group 959"
                transform="translate(0 134.469)"
              >
                <g
                  id="Group_814"
                  data-name="Group 814"
                  transform="translate(0 -134.469)"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="Path_477"
                    data-name="Path 477"
                    d="M166.555-122.467h-1.688v-9.486h1.688a5.343,5.343,0,0,1,3.661,1.193,4.577,4.577,0,0,1,1.332,3.55,4.5,4.5,0,0,1-1.332,3.531,5.263,5.263,0,0,1-3.661,1.213m5.63-10.105a8.34,8.34,0,0,0-5.67-1.9h-4.422v14.5h4.422a8.34,8.34,0,0,0,5.67-1.9,6.742,6.742,0,0,0,2.177-5.343,6.823,6.823,0,0,0-2.177-5.362"
                    transform="translate(-124.57 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_478"
                    data-name="Path 478"
                    d="M238.113-122.429h-7.271v-3.806h6.63v-2.457h-6.63v-3.3h7.211v-2.477h-9.986v14.5h10.046Z"
                    transform="translate(-175.272 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_479"
                    data-name="Path 479"
                    d="M287.9-125.826h6.851v-2.457H287.9v-3.709h7.451v-2.476H285.126v14.5H287.9Z"
                    transform="translate(-219.122 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_480"
                    data-name="Path 480"
                    d="M342.184-119.971h2.774v-14.5h-2.774Z"
                    transform="translate(-262.972 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_481"
                    data-name="Path 481"
                    d="M372.753-130l.116.175,6.532,9.849h2.667v-14.5h-2.674v10.024l-.116-.174-6.512-9.85h-2.687v14.5h2.674Z"
                    transform="translate(-284.409 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_482"
                    data-name="Path 482"
                    d="M437.87-119.971h2.774v-14.5H437.87Z"
                    transform="translate(-336.508 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_483"
                    data-name="Path 483"
                    d="M465.452-119.971h2.754v-11.982h4.427v-2.515H461.005v2.515h4.447Z"
                    transform="translate(-354.287 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_484"
                    data-name="Path 484"
                    d="M522.477-119.971h2.774v-14.5h-2.774Z"
                    transform="translate(-401.529 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_485"
                    data-name="Path 485"
                    d="M557.408-134.469h-2.936l-3.657,9.943-.258.876c-.085-.277-.178-.576-.281-.894l-3.652-9.925h-2.936l5.519,14.5h2.683Z"
                    transform="translate(-417.83 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_486"
                    data-name="Path 486"
                    d="M621.025-122.429h-7.272v-3.806h6.631v-2.457h-6.631v-3.3h7.211v-2.477h-9.985v14.5h10.045Z"
                    transform="translate(-469.544 134.469)"
                    fill="#531295"
                  />
                  <path
                    id="Path_487"
                    data-name="Path 487"
                    d="M168.154-48.156H163.21v-4.823h-2.382V-40.94h2.382v-5.047h4.945v5.047h2.383V-52.979h-2.383Z"
                    transform="translate(-123.598 71.843)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_488"
                    data-name="Path 488"
                    d="M218.191-46.1h5.463v-2.121h-5.463v-2.622h5.941v-2.137h-8.323V-40.94h8.373v-2.121h-5.991Z"
                    transform="translate(-165.851 71.843)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_489"
                    data-name="Path 489"
                    d="M264.359-49.879l1.436,3.645h-2.869l1.288-3.25c.05-.135.1-.267.145-.395m-1.1-3.1L258.3-40.941h2.529l1.27-3.2h4.527l1.254,3.2h2.53L265.445-52.98Z"
                    transform="translate(-198.504 71.844)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_490"
                    data-name="Path 490"
                    d="M319.529-52.979h-2.382V-40.94h8.307v-2.121h-5.924Z"
                    transform="translate(-243.73 71.843)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_491"
                    data-name="Path 491"
                    d="M352.668-50.811h3.664v9.87H358.7v-9.87h3.647V-52.98h-9.677Z"
                    transform="translate(-271.029 71.844)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_492"
                    data-name="Path 492"
                    d="M410.986-48.156h-4.945v-4.823h-2.382V-40.94h2.382v-5.047h4.945v5.047h2.383V-52.979h-2.383Z"
                    transform="translate(-310.216 71.843)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_493"
                    data-name="Path 493"
                    d="M465.888-45.842a3.758,3.758,0,0,1-3.487,2.227,3.444,3.444,0,0,1-2.689-1.164,4.083,4.083,0,0,1-1.08-2.918,4.109,4.109,0,0,1,1.115-2.937,3.472,3.472,0,0,1,2.67-1.162,3.711,3.711,0,0,1,3.387,2.242l.146.276,1.895-1.338-.1-.208a5.26,5.26,0,0,0-2.057-2.285,6.256,6.256,0,0,0-3.243-.84,6.073,6.073,0,0,0-4.418,1.762,6.068,6.068,0,0,0-1.815,4.491,6.079,6.079,0,0,0,1.746,4.471,5.949,5.949,0,0,0,4.4,1.765,5.9,5.9,0,0,0,3.165-.778,7.115,7.115,0,0,0,2.235-2.365l.138-.223-1.872-1.283Z"
                    transform="translate(-350.608 72.59)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_494"
                    data-name="Path 494"
                    d="M516.243-49.879l1.436,3.645H514.81l1.289-3.25c.05-.135.1-.267.145-.395m-1.1-3.1L510.18-40.941h2.53l1.271-3.2h4.526l1.254,3.2h2.53L517.328-52.98Z"
                    transform="translate(-392.079 71.844)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_495"
                    data-name="Path 495"
                    d="M571.411-50.89h2.662c1.809,0,2.018.867,2.018,1.519s-.209,1.5-2.018,1.5h-2.662Zm6.252,3.672a3.272,3.272,0,0,0,.811-2.233A3.247,3.247,0,0,0,577.3-52.02a4.789,4.789,0,0,0-3.111-.959h-5.16V-40.94h2.382v-4.839H573.3l2.756,4.839h2.755l-3-5.093a4.117,4.117,0,0,0,1.852-1.185"
                    transform="translate(-437.304 71.843)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_496"
                    data-name="Path 496"
                    d="M622.829-43.061V-46.1h5.463v-2.121h-5.463v-2.622h5.941V-52.98h-8.323V-40.94h8.373v-2.121Z"
                    transform="translate(-476.819 71.844)"
                    fill="#00c1f3"
                  />
                  <path
                    id="Path_497"
                    data-name="Path 497"
                    d="M48.3-85.074H44.445V-96.249H33.27V-100.1H48.3Z"
                    transform="translate(-25.569 108.058)"
                    fill="#531295"
                  />
                  <path
                    id="Path_498"
                    data-name="Path 498"
                    d="M0-133.271v30.409H2.726L22.167-122.3l-2.726-2.726L3.855-109.442v-19.974h22.7v22.7H12.032l-3.855,3.855H30.409v-30.409Z"
                    transform="translate(0 133.548)"
                    fill="#531295"
                  />
                </g>
              </g>
            </svg>
          </a>
        </div>

        <div className="dropdown">
          <button className="button--flat">
            Explore
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm240 0q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm240 0q-33 0-56.5-23.5T640-240q0-33 23.5-56.5T720-320q33 0 56.5 23.5T800-240q0 33-23.5 56.5T720-160ZM240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400ZM240-640q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640Z" />
            </svg>
          </button>
          <div className="dropdown__items">
            <a className="dropdown__item" href="https://www.defhc.com/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g clipPath="url(#a)">
                  <path
                    fill="#781ADB"
                    d="M0 0v20h1.791L12.413 9.378v5.556h2.536V5.05H5.066v2.536h5.556l-8.086 8.086V2.536h14.928v14.928h-9.55L5.38 20H20V0H0Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
              Definitive Healthcare (View)
            </a>
            <a className="dropdown__item" href="https://app.monocl.com/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g clipPath="url(#a)">
                  <path
                    fill="#781ADB"
                    d="M0 0v20h1.791L12.413 9.378v5.556h2.536V5.05H5.066v2.536h5.556l-8.086 8.086V2.536h14.928v14.928h-9.55L5.38 20H20V0H0Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
              Monocl (ExpertInsight)
            </a>
            <a className="dropdown__item" href="https://app.populi.ai/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g clipPath="url(#a)">
                  <path
                    fill="#781ADB"
                    d="M0 0v20h1.791L12.413 9.378v5.556h2.536V5.05H5.066v2.536h5.556l-8.086 8.086V2.536h14.928v14.928h-9.55L5.38 20H20V0H0Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
              Populi (Claims and consumer analytics)
            </a>
            <a className="dropdown__item" href="https://app.carevoyance.com/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g clipPath="url(#a)">
                  <path
                    fill="#781ADB"
                    d="M0 0v20h1.791L12.413 9.378v5.556h2.536V5.05H5.066v2.536h5.556l-8.086 8.086V2.536h14.928v14.928h-9.55L5.38 20H20V0H0Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
              Carevoyance (Sales accelerator)
            </a>
          </div>
        </div>
      </header>
      <main className="main">
        <div className="application-message">
          {/* <div className="application-message__text">
        <p>
          Please Note: View will be performing scheduled maintenance on our
          View Platform from 7:00 PM to 10:00 PM ET on Thursday, April 25th.
          Users may experience decreased functionality during this time.
        </p>
      </div> */}
        </div>
        {children}
      </main>

      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer__row">
            <p>
              By signing in, you agree to the{" "}
              <a
                href="https://www.definitivehc.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              .
            </p>
          </div>
          <div className="footer__row">
            <p>
              If your company is already a subscriber,{" "}
              <a href="mailto:support@definitivehc.com">contact us</a> for
              sign-in credentials.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Page;
