import styled from "styled-components";

const StyledFlex = styled.div`
  display: flex;
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection};`}

  @media (max-width: 960px) {
    ${(props) =>
      props.flexDirectionSm && `flex-direction: ${props.flexDirectionSm};`}
  }
`;

const Flex = (props) => {
  const {
    direction: flexDirection,
    directionSm: flexDirectionSm,
    grow: flexGrow,
    shrink: flexShrink,
    wrap: flexWrap,
    basis: flexBasis,
    justifyContent,
    alignContent,
    alignItems,
    gap,
    // rowGap,
    // columnGap,
    children,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    width,
    height,
    margin,
    padding,
    style,
  } = props;
  return (
    <StyledFlex
      flexDirection={flexDirection}
      flexDirectionSm={flexDirectionSm}
      style={{
        flexGrow,
        flexShrink,
        flexWrap,
        flexBasis,
        justifyContent,
        alignContent,
        alignItems,
        gap,
        // rowGap,
        // columnGap,
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        width,
        height,
        margin,
        padding,
        ...style,
      }}
    >
      {children}
    </StyledFlex>
  );
};

export default Flex;
