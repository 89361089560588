import styled from "styled-components";

export const LoaderFullScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 600;

  & > div {
    position: relative;
    height: 100px;
    width: 88px;
  }
`;
