import { useContext } from "react";
import BulkPasteContext from "./BulkPasteContext";

const useBulkPaste = () => {
  const {
    id,
    size,
    values,
    setValues,
    draftValues,
    setDraftValues,
    validValues,
    setValidValues,
    invalidValues,
    setInvalidValues,
    validator,
    label,
    modalVisible,
    setModalVisible,
    validationVisible,
    setValidationVisible,
    editModeIndex,
    setEditModeIndex,
    onChange,
    isDisabled,
  } = useContext(BulkPasteContext);

  const showModal = () => {
    if (isDisabled) {
      return;
    }

    setDraftValues(values?.join("\n") || "");
    setValidValues([]);
    setInvalidValues([]);
    setEditModeIndex(null);
    setValidationVisible(false);
    setModalVisible(true);
  };

  const hideModal = () => {
    setValidationVisible(false);
    setModalVisible(false);
  };

  const saveEditor = () => {
    const valid = [];
    const invalid = [];
    const trimmedValues = draftValues
      .replaceAll(/,/g, " ")
      .split(/\s+/)
      .map((value) => value.trim())
      .filter((value) => !!value);

    trimmedValues.forEach((value) => {
      const isValid = validator(value);

      if (isValid && !valid.includes(value)) {
        valid.push(value);
      } else if (!isValid && !invalid.includes(value)) {
        invalid.push(value);
      }
    });

    setValidValues(valid);
    setInvalidValues(invalid);

    if (invalid.length) {
      setValidationVisible(true);
    } else {
      setValues(valid);
      onChange?.(valid);
      hideModal();
    }
  };

  const revalidate = (value, revalidatedIndex) => {
    // Update value in array
    setInvalidValues((prev) => {
      return prev.map((prevValue, invalidIndex) => {
        if (invalidIndex === revalidatedIndex) {
          return value;
        }

        return prevValue;
      });
    });

    const isValid = validator(value);
    if (!isValid) return "Invalid";

    const isDuplicate = validValues.includes(value);
    if (isDuplicate) return "Already exists";

    return null;
  };

  const saveValidator = () => {
    const correctedValues = invalidValues.filter(
      (value) => validator(value) && !validValues.includes(value)
    );
    const newValidValues = validValues.concat(correctedValues);

    setValues(newValidValues);
    onChange?.(newValidValues);
    hideModal();
  };

  const clearValues = (event) => {
    event.stopPropagation();
    setValues([]);
    onChange?.([]);
  };

  return {
    id,
    size,
    label,
    validator,
    values,
    setValues,
    draftValues,
    setDraftValues,
    invalidValues,
    modalVisible,
    setModalVisible,
    validationVisible,
    setValidationVisible,
    editModeIndex,
    setEditModeIndex,
    showModal,
    hideModal,
    saveEditor,
    saveValidator,
    revalidate,
    clearValues,
    isDisabled,
  };
};

export default useBulkPaste;
