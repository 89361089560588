import { Column, TooltipHeading } from "core/styles";

const OrgIdTooltip = () => {
  return (
    <Column
      style={{
        alignItems: "start",
        gap: "6px",
        maxWidth: "315px",
        paddingBottom: "5px",
      }}
    >
      <TooltipHeading style={{ fontSize: "12px" }}>
        The Organization ID must meet the following criteria:
      </TooltipHeading>
      <ul style={{ paddingLeft: "20px", margin: "0px" }}>
        <li>
          Must be all lowercase letters and numbers (no special characters)
        </li>
        <li>The first character must be a letter</li>
        <li>Max 10 characters</li>
        <li>Must be unique</li>
        <li>Cannot be hosting or populi</li>
      </ul>
    </Column>
  );
};

export default OrgIdTooltip;
