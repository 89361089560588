import { useState } from "react";
import { DateTime } from "luxon";
import Typography from "core/components/Typography";
import Button from "core/components/Button";
import { passwordReset } from "modules/auth/actions";
import useToaster from "core/hooks/useToaster";
import { ROLES } from "core/constants";
import useCurrentUser from "core/hooks/useCurrentUser";
import DetailsViewer from "../DetailsViewer";

const RESET_PASSWORD_PERMISSIONS = {
  CAN_RESET: "permission:user:reset-password:reset",
  CAN_VIEW_META: "permission:user:reset-password:view-meta",
};

const getPermissions = ({ loggedInUser, profileUser }) => {
  let permissions = [];

  if (
    loggedInUser.role === ROLES.systemAdmin ||
    loggedInUser.role === ROLES.orgAdmin
  ) {
    permissions.push(RESET_PASSWORD_PERMISSIONS.CAN_RESET);
    permissions.push(RESET_PASSWORD_PERMISSIONS.CAN_VIEW_META);
  } else if (
    loggedInUser.role === ROLES.systemViewer &&
    loggedInUser.email === profileUser.email
  ) {
    permissions.push(RESET_PASSWORD_PERMISSIONS.CAN_RESET);
    permissions.push(RESET_PASSWORD_PERMISSIONS.CAN_VIEW_META);
  } else {
    // populi viewer can only view meta on other user
    permissions.push(RESET_PASSWORD_PERMISSIONS.CAN_VIEW_META);
  }

  return permissions;
};

export const ResetPasswordNavSection = ({ user }) => {
  const { currentUser } = useCurrentUser();
  const { toaster } = useToaster();
  const [isLoading, setIsLoading] = useState(false);

  const permissions = getPermissions({
    loggedInUser: currentUser,
    profileUser: user,
  });

  return (
    <>
      {permissions.includes(RESET_PASSWORD_PERMISSIONS.CAN_RESET) && (
        <>
          <Typography
            variant="p"
            noMargin
            style={{ lineHeight: "22px", marginBottom: "24px" }}
          >
            Instructions to reset password will be sent to the email associated
            with this user.
          </Typography>
          <Button
            onClick={() => {
              setIsLoading(true);
              passwordReset({ email: user.email })
                .then(() => {
                  toaster.success({
                    message: "Reset password email has been sent.",
                  });
                  setIsLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                });
            }}
            isLoading={isLoading}
          >
            Reset Password
          </Button>
        </>
      )}
      {/* Metadata */}
      {permissions.includes(RESET_PASSWORD_PERMISSIONS.CAN_VIEW_META) && (
        <DetailsViewer
          isLoading={false}
          data={[
            {
              label: "Last Reset",
              value:
                user.last_password_update_timestamp &&
                DateTime.fromMillis(
                  user.last_password_update_timestamp
                ).toFormat("MMMM d, yyyy 'at' h:mm a"),
            },
          ]}
        />
      )}
    </>
  );
};
