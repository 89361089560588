import useCurrentUser from "./useCurrentUser";

// A wrapper for the the function passed to useMutate
// Provides currentUser's accessToken to the underlying api call invoked
const useAuthenticatedMutation = (apiCall) => {
  const { currentUser } = useCurrentUser();

  let { accessToken } = currentUser || {};

  return async (requestParams) => {
    return apiCall({
      ...requestParams,
      accessToken,
    });
  };
};

export default useAuthenticatedMutation;
