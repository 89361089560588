import styled from "styled-components";

import BulkPasteValidatorRow from "./BulkPasteValidatorRow";
import useBulkPaste from "./useBulkPaste";

const Container = styled.div`
  border-top: solid 1px #dddbda;
  overflow-y: auto;
  max-height: 300px;
`;

const BulkPasteValidatorTable = () => {
  const { invalidValues, editModeIndex } = useBulkPaste();

  return (
    <Container>
      {invalidValues.map((value, index) => {
        return (
          <BulkPasteValidatorRow
            key={index}
            index={index}
            value={value}
            inEditMode={editModeIndex === index}
          />
        );
      })}
    </Container>
  );
};

export default BulkPasteValidatorTable;
