import {
  Column,
  Row,
  TooltipHeading,
  TooltipIcon,
  TooltipLabel,
} from "core/styles";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";

const BiPortalUserTooltip = () => {
  return (
    <Column
      style={{
        alignItems: "start",
        gap: "6px",
        maxWidth: "315px",
        paddingBottom: "5px",
      }}
    >
      <TooltipHeading>BI Portal Viewers</TooltipHeading>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faCheck}
          style={{ color: "#ffffff", backgroundColor: "#006cca" }}
        />
        <TooltipLabel>Create personal views of dashboards</TooltipLabel>
      </Row>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faClose}
          style={{ color: "#ffffff", backgroundColor: "#ff2e12" }}
        />
        <TooltipLabel>
          Save dashboard views and share with other
          <br /> users in the organization
        </TooltipLabel>
      </Row>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faClose}
          style={{ color: "#ffffff", backgroundColor: "#ff2e12" }}
        />
        <TooltipLabel>
          Download dashboard to edit in Tableau Desktop
        </TooltipLabel>
      </Row>
      <TooltipHeading style={{ paddingTop: "10px" }}>
        BI Portal Explorers
      </TooltipHeading>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faCheck}
          style={{ color: "#ffffff", backgroundColor: "#006cca" }}
        />
        <TooltipLabel>Create personal views of dashboards</TooltipLabel>
      </Row>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faCheck}
          style={{ color: "#ffffff", backgroundColor: "#006cca" }}
        />
        <TooltipLabel>
          Save dashboard views and share with other
          <br /> users in the organization
        </TooltipLabel>
      </Row>
      <Row alignItems="flex-start" gap="5px">
        <TooltipIcon
          icon={faCheck}
          style={{ color: "#ffffff", backgroundColor: "#006cca" }}
        />
        <TooltipLabel>
          Download dashboard to edit in Tableau Desktop
        </TooltipLabel>
      </Row>
    </Column>
  );
};

export default BiPortalUserTooltip;
