import Form from "./Form";
import Input from "./Input";
import LabelWithTooltip from "./LabelWithTooltip";

export { Input, LabelWithTooltip };

export {
  FormContainer,
  InputGroup,
  Label,
  Input as StyledInput,
} from "./styles";

export default Form;
