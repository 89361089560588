import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPencil,
  faRotateRight,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";

import { StyledInput } from "../Form";
import useBulkPaste from "./useBulkPaste";

const Container = styled.div`
  display: flex;
  padding: ${(props) => (props.$inEditMode ? "5px 20px" : "10px 20px")};
  background-color: ${(props) =>
    (props.$index + 1) % 2 === 0 ? "#fff" : "#fafaf9"};
  border-bottom: solid 1px #dddbda;
  justify-content: space-between;
`;

const Error = styled.div`
  color: #c23934;
  font-size: 14px;
  margin-right: 10px;
`;

const InputContainer = styled.div`
  color: ${(props) => (props.$isValid ? "#706E6B" : "#C23934")};
  font-family: "Inter";
  font-size: 16px;
  width: 200px;
`;

const IconContainer = styled.div`
  color: #706e6b;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 1px;
`;

const RevalidateIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props.$canRevalidate ? "pointer" : "default")};
  opacity: ${(props) => (props.$canRevalidate ? 1 : 0.5)};
  width: 20px;
  height: 20px;
`;

const ValidIcon = styled(FontAwesomeIcon)`
  color: #1ec67c;
  margin-right: 12px;
  width: 20px;
  height: 20px;
`;

const InvalidIcon = styled(FontAwesomeIcon)`
  color: orange;
  margin-right: 12px;
  width: 20px;
  height: 20px;
`;

const PencilIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #3e3e3c;
  width: 20px;
  height: 20px;
`;

const BulkPasteValidatorRow = ({ index, value, inEditMode }) => {
  const { setEditModeIndex, validator, revalidate } = useBulkPaste();

  const [canRevalidate, setCanRevalidate] = useState(false);
  const [draftValue, setDraftValue] = useState(value);
  const [error, setError] = useState(null);
  const isValid = useMemo(() => validator(draftValue), [validator, draftValue]);

  const handleInputChange = (event) => {
    setCanRevalidate(true);
    setDraftValue(event.target.value);
  };

  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      handleRevalidate();
    }

    if (event.key === "Escape") {
      setEditModeIndex(null);
    }
  };

  const handleRevalidate = () => {
    const error = revalidate(draftValue, index);
    setError(error);
    setEditModeIndex(null);
    setCanRevalidate(false);
  };

  return (
    <Container $inEditMode={inEditMode} $index={index}>
      <InputContainer $isValid={isValid && !error}>
        {inEditMode ? (
          <StyledInput
            type="text"
            style={{ height: "28px", width: "100%" }}
            value={draftValue}
            onChange={handleInputChange}
            onKeyDown={handleKeydown}
            autoFocus
          />
        ) : (
          draftValue
        )}
      </InputContainer>
      <IconContainer>
        {inEditMode ? (
          <RevalidateIcon
            $canRevalidate={canRevalidate}
            icon={faRotateRight}
            onClick={canRevalidate ? handleRevalidate : null}
          />
        ) : (
          <>
            {isValid && !error ? (
              <ValidIcon icon={faCircleCheck} />
            ) : (
              <>
                {error && <Error>{error}</Error>}
                <InvalidIcon icon={faTriangleExclamation} />
              </>
            )}
            <PencilIcon
              icon={faPencil}
              onClick={() => setEditModeIndex(index)}
            />
          </>
        )}
      </IconContainer>
    </Container>
  );
};

export default BulkPasteValidatorRow;
