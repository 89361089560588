import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPencil,
  faRotateRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import { StyledInput } from "core/components/Form";

const NPIValidatorTableRow = ({
  npiItem,
  editDraftNPI,
  changeDraftNPI,
  validateDraftNPI,
}) => {
  const { index, npi, isValid, errorText, canRevalidate, inEditMode } = npiItem;

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      changeDraftNPI({ npi: value, rowIndex: index });
    }
  };

  const handleKeydown = (event) => {
    if (event.key === "Enter" && canRevalidate) {
      handleRevalidate();
    }

    if (event.key === "Escape") {
      editDraftNPI({ rowIndex: null });
    }
  };

  const handleRevalidate = () => {
    validateDraftNPI({ rowIndex: index });
  };

  return (
    <div
      style={{
        display: "flex",
        padding: inEditMode ? "5px 20px" : "10px 20px",
        backgroundColor: (index + 1) % 2 === 0 ? "#fff" : "#fafaf9",
        borderBottom: "solid 1px #DDDBDA",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          color: isValid ? "#706E6B" : "#C23934",
          fontFamily: "Inter",
          fontSize: "16px",
          width: "200px",
        }}
      >
        {inEditMode ? (
          <StyledInput
            type="text"
            style={{ height: "28px", width: "100%" }}
            value={npi}
            onChange={handleInputChange}
            onKeyDown={handleKeydown}
            autoFocus
          />
        ) : (
          npi
        )}
      </div>
      <div
        style={{
          color: "#706E6B",
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "1px",
        }}
      >
        {inEditMode ? (
          <FontAwesomeIcon
            icon={faRotateRight}
            style={{
              cursor: canRevalidate ? "pointer" : "default",
              opacity: canRevalidate ? 1 : 0.5,
            }}
            onClick={canRevalidate ? handleRevalidate : null}
          />
        ) : (
          <>
            {isValid ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{
                  color: "#008844",
                  marginRight: "12px",
                }}
              />
            ) : (
              <>
                {errorText && (
                  <div
                    style={{
                      color: "#C23934",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  >
                    {errorText}
                  </div>
                )}
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{ color: "orange", marginRight: "12px" }}
                />
              </>
            )}
            <FontAwesomeIcon
              icon={faPencil}
              style={{ cursor: "pointer" }}
              onClick={() => editDraftNPI({ rowIndex: index })}
            />
          </>
        )}
      </div>
    </div>
  );
};

const NPIValidatorTable = ({
  npiList,
  editDraftNPI,
  changeDraftNPI,
  validateDraftNPI,
}) => {
  // key is item.npi + index because two row can have same npi with invalidated state
  return (
    <div
      style={{
        borderTop: "solid 1px #DDDBDA",
      }}
    >
      {npiList.map((item, index) => {
        return (
          <NPIValidatorTableRow
            key={item.npi + index}
            npiItem={item}
            editDraftNPI={editDraftNPI}
            changeDraftNPI={changeDraftNPI}
            validateDraftNPI={validateDraftNPI}
          />
        );
      })}
    </div>
  );
};

export default NPIValidatorTable;
