import styled from "styled-components";
import * as RadixSlider from "@radix-ui/react-slider";

const Root = styled(RadixSlider.Root)`
  position: relative;
  display: flex;
  align-items: center;

  &[data-orientation="vertical"] {
    flex-direction: column;
    width: 20px;
    height: 100px;
  }
`;

const Track = styled(RadixSlider.Track)`
  position: relative;
  flex-grow: 1;
  height: 6px;
  width: 150px;
  border-radius: 3.5px;
  background-color: #dddbda;
  background: linear-gradient(90deg, #dddbda 0%, #dddbda 100%);

  ${(props) =>
    props.size === "small" &&
    `
    height: 4px;
    width: 100px;
  `}
`;

const FilledTrack = styled.div`
  position: absolute;
  background-color: #007eac;
  height: 6px;
  border-radius: 3.5px;

  ${(props) =>
    props.size === "small" &&
    `
    height: 4px;
  `}
`;

const Range = styled(RadixSlider.Range)`
  position: absolute;

  &[data-orientation="vertical"] {
    width: 100%;
  }
`;

const Thumb = styled(RadixSlider.Thumb)`
  display: inline-block;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  color: #007eac;
  font-family: "Inter";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007eac;
  }

  ${(props) =>
    props.size === "small" &&
    `
    height: 20px;
    width: 20px;
    padding-top: 2px;
    font-size: 10px;
  `}
`;

const InputGroup = styled.div`
  width: 100%;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  ${(props) =>
    props.isReadOnly &&
    `
    pointer-events: none;
  `}
`;

const Label = styled.label`
  display: block;
  text-align: left;
  font-size: 0.75rem;
  font-weight: ${(props) => (props.normal ? "normal" : "600")};
  margin-bottom: 3px;
  font-family: "Inter";
  color: #000000;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`;

const styles = {
  Root,
  Track,
  FilledTrack,
  Range,
  Thumb,
  InputGroup,
  Label,
};

export default styles;
