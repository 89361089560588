import { createContext } from "react";

const BulkPasteContext = createContext({
  id: null,
  size: null,
  label: null,
  validator: null,
  values: null,
  onChange: () => {},
  setValues: () => {},
  draftValues: null,
  setDraftValues: () => {},
  validValues: null,
  setValidValues: () => {},
  invalidValues: null,
  setInvalidValues: () => {},
  modalVisible: null,
  setModalVisible: () => {},
  validationVisible: null,
  setValidationVisible: () => {},
  editModeIndex: null,
  setEditModeIndex: () => {},
});

export default BulkPasteContext;
