import { animated, useSpring } from "react-spring";

const showStyles = {
  display: "flex",
  opacity: 1,
  minHeight: "45px",
};

const hideStyles = {
  display: "none",
  opacity: 0,
};

const baseStyles = {
  backgroundColor: "#BA351C",
  width: "100%",
  color: "white",
  fontSize: "14px",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Inter",
  fontWeight: "600",
  padding: "10px 30px",
  borderRadius: "5px",
  marginBottom: "25px",
};

const Error = ({ style, children }) => {
  const styles = children
    ? { ...baseStyles, ...showStyles, ...style }
    : hideStyles;
  const slideInStyles = useSpring({
    config: { mass: 5, tension: 50, friction: 10 },
    ...styles,
  });

  if (Array.isArray(children)) {
    return (
      <animated.div style={slideInStyles}>
        <ul style={{ padding: "0px" }}>
          {children.map((e) => (
            <li key={e} style={{ listStyleType: "none" }}>
              {e}
            </li>
          ))}
        </ul>
      </animated.div>
    );
  }

  if (typeof children === "string") {
    return <animated.div style={slideInStyles}>{children}</animated.div>;
  }

  if (children?.message) {
    return (
      <animated.div style={slideInStyles}>{children.message}</animated.div>
    );
  }

  return <animated.div style={slideInStyles}>Unexpected Error!</animated.div>;
};

export default Error;
