import styled from "styled-components";

export const StyledRadio = styled.div`
  width: 100%;
  margin-bottom: 22px;
  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`;

export const RadioInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const RadioInput = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 8px;
  opacity: 0;
`;

export const RadioInputLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  color: #2b2826;

  .unchecked {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 50%;
  }

  .checked {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #0097c0;
    }
  }
`;
