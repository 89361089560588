import styled from "styled-components";

const TabsContainer = styled.div`
  width: fit-content;
  border: 1px solid #541299;
  border-radius: 5px;
  height: 41px;
  display: flex;
  align-items: center;
  align-content: center;

  div:first-child {
    border-radius: 4px 0 0 4px;
  }

  div:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

const Tabs = ({ children }) => {
  return <TabsContainer>{children}</TabsContainer>;
};

export default Tabs;
