import { createContext } from "react";
import { themeModes, themes } from "theme";

const ThemeContext = createContext({
  themeMode: themeModes.light,
  setThemeMode: () => {},
  theme: themes[themeModes.light],
});

export default ThemeContext;
