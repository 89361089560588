export const RANGE_SEPERATOR = "\u2192";

export const DATE_FORMATS = {
  date: "MM/dd/yyyy",
  month: "MM/yyyy",
  quarter: "MM/yyyy",
  year: "yyyy",
};

export const MONTH_YEAR_FORMAT = "MMMM yyyy";

export const PLACEHOLDERS = {
  date: "mm/dd/yyyy",
  month: "mm/yyyy",
  quarter: "mm/yyyy - mm/yyyy",
  year: "yyyy",
};

export const WIDTHS = {
  date: "192px",
  month: "231px",
  quarter: "270px",
  year: "220px",
};

export const RANGE_PLACEHOLDERS = {
  date: `mm/dd/yyyy ${RANGE_SEPERATOR} mm/dd/yyyy`,
  month: `mm/yyyy ${RANGE_SEPERATOR} mm/yyyy`,
  quarter: `mm/yyyy - mm/yyyy ${RANGE_SEPERATOR} mm/yyyy - mm/yyyy`,
  year: `yyyy ${RANGE_SEPERATOR} yyyy`,
};

export const RANGE_WIDTHS = {
  date: "294px",
  month: "231px",
  quarter: "394px",
  year: "220px",
};

export const QUARTER_MONTH_NUMBERS = {
  Q1: "01 - 03",
  Q2: "04 - 06",
  Q3: "07 - 09",
  Q4: "10 - 12",
};

export const QUARTER_MONTHS = {
  Q1: "Jan - Mar",
  Q2: "Apr - Jun",
  Q3: "Jul - Sep",
  Q4: "Oct - Dec",
};
