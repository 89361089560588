import { useEffect, useMemo, useState } from "react";

const deepMergeObjects = (prevUpdates = {}, nextUpdates = {}) => {
  if ([prevUpdates, nextUpdates].includes(null)) {
    return prevUpdates ?? nextUpdates;
  }

  const uniqueKeys = [
    ...new Set([...Object.keys(prevUpdates), ...Object.keys(nextUpdates)]),
  ];

  return uniqueKeys.reduce((mergedValues, key) => {
    const prevValue = prevUpdates[key];
    const nextValue = nextUpdates[key];

    if ([prevValue, nextValue].includes(undefined)) {
      return {
        ...mergedValues,
        [key]: nextValue ?? prevValue,
      };
    }

    const isPrevValueObject =
      prevValue && !Array.isArray(prevValue) && typeof prevValue === "object";
    const isNextValueObject =
      nextValue && !Array.isArray(nextValue) && typeof nextValue === "object";

    if (isPrevValueObject && isNextValueObject) {
      return {
        ...mergedValues,
        [key]: deepMergeObjects(prevValue, nextValue, "recursive"),
      };
    } else {
      return {
        ...mergedValues,
        [key]: nextValue,
      };
    }
  }, {});
};

const buildComplexFieldState = ({ prevState = {}, fieldKey, newValue }) => {
  if (!fieldKey.includes(".")) {
    return { ...prevState, [fieldKey]: newValue };
  } else {
    const splitFieldKey = fieldKey.split(".");
    const nextKey = splitFieldKey.shift();

    return {
      ...prevState,
      [nextKey]: splitFieldKey.length
        ? buildComplexFieldState({
            prevState: prevState[nextKey],
            fieldKey: splitFieldKey.join("."),
            newValue,
          })
        : newValue,
    };
  }
};

// Matt: I do think this and "placeholderValues" is going to need additional work to support schema based validation for complex forms
const createComplexDefaultErrorObject = (defaultState) => {
  const defaultKeys = Object.keys(defaultState);

  return defaultKeys.reduce((fieldErrorMap, fieldErrorKey) => {
    fieldErrorMap[fieldErrorKey] = "";

    return fieldErrorMap;
  }, {});
};

const useForm = ({
  disabledUntilTouched = false,
  placeholderValues = {},
  initialValues = {},
  isFetchingInitialValues = false,
  formSchema,
  shouldValidateWithSchema = false,
  metadata = {},
  onSubmit,
}) => {
  const [formValues, setFormValues] = useState(placeholderValues);
  const [draftChanges, setDraftChanges] = useState({});
  const [errors, setErrors] = useState(() => {
    return createComplexDefaultErrorObject(placeholderValues);
  });
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isFetchingInitialValues) {
      setFormValues((previosState) =>
        deepMergeObjects(previosState, initialValues)
      );
    }
  }, [initialValues, isFetchingInitialValues]);

  const isValid = useMemo(() => {
    const isFormValid = formSchema.safeParse({
      ...formValues,
      ...metadata,
    });

    return isFormValid.success;
  }, [formSchema, formValues, metadata]);

  const isDisabled = useMemo(
    () => (disabledUntilTouched && !isTouched) || !isValid,
    [disabledUntilTouched, isTouched, isValid]
  );

  const onFieldChange = useMemo(
    () => (fieldKey) => (value, error) => {
      setIsTouched(true);

      setFormValues((previousValues) => {
        const updatedFormValues = buildComplexFieldState({
          prevState: previousValues,
          fieldKey,
          newValue: value,
        });

        // Matt: interim solution for supporting complex schema validations directly
        if (shouldValidateWithSchema) {
          const validatedForm = formSchema.safeParse({
            ...updatedFormValues,
            ...metadata,
          });

          if (validatedForm.success) {
            setErrors(createComplexDefaultErrorObject(placeholderValues));
          } else {
            const flattenedFieldErrors = validatedForm.error.flatten(
              (issue) => {
                return issue.message;
              }
            ).fieldErrors;

            const fieldErrorKeys = Object.keys(errors);
            const updatedErrors = fieldErrorKeys.reduce(
              (errorMap, fieldErrorKey) => {
                // Don't set error unless field has a value (isTouched behavior)
                const isFieldTouched = !!updatedFormValues[fieldErrorKey];

                // If field has error, pluck first error off of issue list, or clear error
                errorMap[fieldErrorKey] =
                  flattenedFieldErrors[fieldErrorKey] && isFieldTouched
                    ? flattenedFieldErrors[fieldErrorKey][0]
                    : "";
                return errorMap;
              },
              {}
            );

            setErrors(updatedErrors);
          }
        } else {
          setErrors((previousState) => {
            return buildComplexFieldState({
              prevState: previousState,
              fieldKey,
              newValue: error,
            });
          });
        }

        return updatedFormValues;
      });

      setDraftChanges((previousState) => {
        return buildComplexFieldState({
          prevState: previousState,
          fieldKey,
          newValue: value,
        });
      });
    },
    [
      setIsTouched,
      setDraftChanges,
      setErrors,
      shouldValidateWithSchema,
      metadata,
      errors,
      formSchema,
      placeholderValues,
    ]
  );

  const onFormSubmit = (event) => {
    // Commenting this out until we remove react-form
    // event.preventDefault();

    if (isValid) {
      onSubmit(draftChanges);
      setIsTouched(false);
    }

    return false;
  };

  return {
    formValues,
    draftChanges,
    errors,
    isValid,
    isTouched,
    isDisabled,
    onFieldChange,
    onFormSubmit,
  };
};

export default useForm;
