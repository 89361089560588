import { forwardRef, useEffect, useState } from "react";
import { DATE_FORMATS, RANGE_SEPERATOR } from "./constants";
import { dateToString, stringToDate } from "./utils";

const DatePickerInput = forwardRef((props, ref) => {
  const {
    autoComplete,
    id,
    name,
    disabled,
    readOnly,
    placeholder,
    value,
    onClick,
    onFocus,
    onBlur,
    onChange,
    isRange,
    type,
    onInputChange,
    onKeyDown,
  } = props;
  const [rawValue, setRawValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  /**
   * Updates input value when ReactDatePicker value changes
   */
  useEffect(() => {
    // ignore if we are editing (recursive)
    if (isEditing) {
      return;
    }

    if (isRange) {
      if (type === "quarter") {
        // ReactDatePicker uses " - " as seperator
        const [startRangeValue, endRangeValue] = value.split(/\s*-\s*/);
        const startDateTime = stringToDate(type, startRangeValue);
        const endDateTime = stringToDate(type, endRangeValue);
        const startStr = dateToString(type, startDateTime);
        const endStr = dateToString(type, endDateTime);

        if (startDateTime) {
          setRawValue(`${startStr} ${RANGE_SEPERATOR} ${endStr}`);
        } else {
          setRawValue("");
        }
      } else {
        // simply replace " - " with fancy arrow seperator
        setRawValue(value.replace("-", RANGE_SEPERATOR));
      }
    } else {
      if (type === "quarter") {
        const dateTime = stringToDate(type, value);
        const dtStr = dateToString(type, dateTime);

        setRawValue(dtStr);
      } else {
        setRawValue(value);
      }
    }
  }, [type, isRange, isEditing, value]);

  const _onFocus = (event) => {
    setIsEditing(true);
    onFocus(event);
  };

  const _onBlur = (event) => {
    setIsEditing(false);
    onBlur(event);
  };

  const _onChange = (event) => {
    const newRawValue = event.target.value;

    if (isRange) {
      // get start and end value split by seperator
      const [startValue, endValue] = newRawValue.split(
        new RegExp(`\\s*${RANGE_SEPERATOR}\\s*`)
      );

      // parse to DateTime
      let startDateTime = stringToDate(type, startValue, true);
      let endDateTime = stringToDate(type, endValue, true);

      // convert to JS date to update ReactDatePicker
      const startDate = startDateTime?.toJSDate();
      const endDate =
        startDateTime && endDateTime && endDateTime >= startDateTime
          ? endDateTime.toJSDate()
          : null;
      const dateFormat = DATE_FORMATS[type];

      // Trigger calendar month/year to update
      onChange({
        ...event,
        target: {
          ...event.target,
          value: startDateTime?.toFormat(dateFormat) || "",
        },
      });
      // in above onChange ReactDatePicker updates value for ranges with same start date for both values
      // so call controlled onChange ourselves with correct start and end
      onInputChange([startDate, endDate]);

      const startStr = dateToString(type, startDateTime);
      const endStr = dateToString(type, endDateTime);

      if (startDate && endDate) {
        setRawValue(`${startStr} ${RANGE_SEPERATOR} ${endStr}`);
      } else if (startDate && !endValue) {
        // if start date valid but no end date value force the fancy arrow seperator
        setRawValue(`${startStr} ${RANGE_SEPERATOR} `);
      } else {
        setRawValue(newRawValue);
      }
    } else {
      const dateFormat = DATE_FORMATS[type];
      const dateTime = stringToDate(type, newRawValue, true);
      const date = dateTime?.toJSDate();

      // use ReactDatePickers onChange directly
      // (switches calendar month year as well)
      onChange({
        ...event,
        target: {
          ...event.target,
          value: dateTime?.toFormat(dateFormat) || "",
        },
      });

      if (date) {
        const dateStr = dateToString(type, dateTime);
        setRawValue(dateStr);
      } else {
        setRawValue(newRawValue);
      }
    }
  };

  return (
    <input
      id={id}
      name={name}
      autoComplete={autoComplete}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      value={rawValue}
      onChange={_onChange}
      onClick={onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
      onKeyDown={onKeyDown}
    />
  );
});

export default DatePickerInput;
