import { useState } from "react";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import ThemeContext from "./ThemeContext";
import { themeModes, themes } from "theme";

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(themeModes.light);

  return (
    <ThemeContext.Provider
      value={{
        themeMode,
        setThemeMode,
        theme: themes[themeMode],
      }}
    >
      <StyledComponentsThemeProvider theme={themes[themeMode]}>
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
