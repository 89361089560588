export const getLastActiveCredential = (credentialMapById) => {
  if (!credentialMapById) {
    return null;
  }

  return Object.values(credentialMapById)
    .filter((credential) => credential.active)
    .reduce((latest, current) => {
      if (!latest?.generated_timestamp) {
        return current;
      }

      if (current?.generated_timestamp && latest?.generated_timestamp) {
        if (current.generated_timestamp > latest.generated_timestamp) {
          return current;
        }
      }

      return latest;
    }, null);
};
