import { useForm } from "react-form";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import Button from "../Button";
import { Fieldset } from "./styles";
import { Row } from "core/styles";

import FormProvider from "core/context/FormProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 20;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Form = ({
  id,
  children,
  defaultValues,
  onSubmit = () => console.log("Form submitted!"),
  submitLabel = "Submit",
  onCancel = null,
  cancelLabel = "Cancel",
  cancelIcon = null,
  isCancelLoading = false,
  isFetching,
  isSubmitting,
  disabled = false,
  disabledUntilTouched = false,
  validatePristine = false,
  validate = null,
  mode = "default",
}) => {
  switch (mode) {
    case "create":
      validatePristine = true;
      break;
    case "update":
      validatePristine = true;
      disabledUntilTouched = true;
      break;
    default:
      break;
  }

  const {
    Form: ReactForm,
    meta: { isSubmitting: isReactFormSubmitting, canSubmit, isTouched },
  } = useForm({ onSubmit, defaultValues, validatePristine, validate });

  const isFormSubmitting = isSubmitting || isReactFormSubmitting;

  return (
    <FormProvider validatePristine={validatePristine}>
      <StyledForm id={id} as={ReactForm} data-cy="form">
        <Fieldset disabled={isFormSubmitting}>{children}</Fieldset>
        {isFetching ? (
          <div style={{ marginTop: "-15px" }}>
            <Skeleton style={{ height: "41px" }} />
          </div>
        ) : (
          <Row style={{ marginTop: "-15px" }} justifyContent="flex-end">
            {!!onCancel && (
              <Button
                type="button"
                variant="outlined"
                isLoading={isCancelLoading}
                onClick={onCancel}
              >
                {cancelIcon ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <FontAwesomeIcon icon={cancelIcon} />
                    <span>{cancelLabel}</span>
                  </span>
                ) : (
                  cancelLabel
                )}
              </Button>
            )}
            <Button
              type="submit"
              isLoading={isFormSubmitting}
              disabled={
                (disabledUntilTouched && !isTouched) || !canSubmit || disabled
              }
            >
              {submitLabel}
            </Button>
          </Row>
        )}
      </StyledForm>
    </FormProvider>
  );
};

export default Form;
