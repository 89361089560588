import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import AliasesTable from "modules/config/AliasesTable";
import { getAliases } from "modules/config/actions";
import { Row } from "core/styles";
import Button from "core/components/Button";
import { useEffect } from "react";
import useToaster from "core/hooks/useToaster";
import useDebounce from "core/hooks/useDebounce";
import { useMemo } from "react";
import useCurrentUser from "core/hooks/useCurrentUser";
import { ROLES } from "core/constants";
import Counter from "core/components/Counter";
import TableErrorFallback from "core/components/Table/TableErrorFallback";

const serializeSort = (sortArray) => {
  return sortArray.reduce((string, column) => {
    if (string.length > 0) {
      return `${string},${column.id}:${column.desc ? "desc" : "asc"}`;
    }
    return `${column.id}:${column.desc ? "desc" : "asc"}`;
  }, "");
};

const buildFilterQuery = (filter) => {
  let filterKey;

  switch (filter.name) {
    case "provider_npi":
      filterKey = "npi";
      break;

    default:
      filterKey = filter.name;
      break;
  }

  return {
    [filterKey]: filter.value,
  };
};

const Aliases = () => {
  const navigate = useNavigate();
  const { orgId: organizationId } = useParams();
  const { toaster } = useToaster();
  const fetchQueryCall = useAuthenticatedCall(getAliases);

  const [page, setPage] = useState(0);
  const [pageToken, setPageToken] = useState(null);
  const [sort, setSort] = useState([{ id: "provider_npi", desc: false }]);
  const [filter, setFilter] = useState({ name: null, value: "" });
  const [auditTrail, updateAuditTrail] = useState([]);

  const changePage = (action) => {
    if (action === "NEXT") {
      setPage(page + 1);
      setPageToken(data.pagination.page_next);
      updateAuditTrail(
        auditTrail.concat([
          {
            sort,
            filter,
            pageToken: data.pagination.page_next,
            page: page + 1,
          },
        ])
      );
    }

    if (action === "PREVIOUS") {
      setPage(page - 1);
      setPageToken(data.pagination.page_previous);
      updateAuditTrail(
        auditTrail.concat([
          {
            sort,
            filter,
            pageToken: data.pagination.page_previous,
            page: page - 1,
          },
        ])
      );
    }
  };

  const handleSortChange = (updaterFn) => {
    setPage(0);
    setPageToken(null);
    updateAuditTrail(
      auditTrail.concat([{ sort, filter, pageToken: null, page: 0 }])
    );

    return setSort(updaterFn);
  };

  const updateFilter = (filter) => {
    setPage(0);
    setPageToken(null);
    setSort([{ id: "provider_npi", desc: false }]);
    setFilter(filter);
    updateAuditTrail(
      auditTrail.concat([
        {
          sort: [{ id: "provider_npi", desc: false }],
          filter,
          pageToken: null,
          page: 0,
        },
      ])
    );
  };

  const baseQuery = ["aliases", organizationId];
  const serializedSort = useMemo(() => serializeSort(sort), [sort]);
  const builtFilter = useMemo(() => buildFilterQuery(filter), [filter]);
  const debouncedFilterQuery = useDebounce(builtFilter);
  const combinedQuery = [
    ...baseQuery,
    { page, sort: serializedSort, filter: debouncedFilterQuery },
  ];
  const { isLoading, data, fetchStatus, isPreviousData, isError, error } =
    useQuery({
      queryKey: combinedQuery,
      queryFn: fetchQueryCall,
      staleTime: 20 * 1000,
      keepPreviousData: true,
      meta: { pageToken },
    });

  useEffect(() => {
    window.history.pushState(
      { from: "Aliases" },
      "",
      `/config/${organizationId}/managed-providers/aliases`
    );
  }, [organizationId]);

  useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        toaster.error({ message: error[0] });
      } else {
        toaster.error({ message: error.message });
      }
    }
  }, [isError, error, toaster]);

  useEffect(() => {
    if (isError) {
      const index = auditTrail.length - 2 < 0 ? false : auditTrail.length - 2;

      if (Number.isInteger(index)) {
        const { page, pageToken, sort, filter } = auditTrail[index];
        setPage(page);
        setPageToken(pageToken);
        setSort(sort);
        setFilter(filter);
      } else {
        setPage(0);
        setPageToken(null);
        setSort([{ id: "provider_npi", desc: false }]);
        setFilter({ name: null, value: "" });
      }
    }
  }, [isError, auditTrail]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const toggleSelectAllRows = () => {
    if (selectedRowIds.length === data.rows.length) {
      setSelectedRowIds([]);
    } else {
      setSelectedRowIds(data.rows.map((row) => row.provider_npi));
    }
  };
  const toggleSelectSingleRow = (npi) => {
    if (selectedRowIds.includes(npi)) {
      setSelectedRowIds(
        selectedRowIds.filter((selectedNPI) => selectedNPI !== npi)
      );
    } else {
      setSelectedRowIds(selectedRowIds.concat(npi));
    }
  };

  const {
    currentUser: { role, organizationId: userOrgId },
  } = useCurrentUser();
  const canUpdateAliases =
    role === ROLES.systemAdmin ||
    (role === ROLES.orgAdmin && userOrgId === organizationId);

  return (
    <div style={{ position: "relative", paddingTop: "20px" }}>
      {canUpdateAliases && (
        <Row
          style={{
            position: "absolute",
            top: "-50px",
            right: "20px",
          }}
        >
          {!!selectedRowIds.length && (
            <Counter text={`${selectedRowIds.length} selected`} size="small" />
          )}
          <Button
            variant="outlined"
            width="auto"
            onClick={() => {
              const removeUrl = `/config/${organizationId}/managed-providers/aliases/remove`;

              if (selectedRowIds.length) {
                // We can't use search params here because the list of NPIs could be in the hundreds or thousands
                navigate(removeUrl, {
                  state: { npis: selectedRowIds, queryKey: combinedQuery },
                });
              } else {
                navigate(removeUrl, {
                  state: { queryKey: combinedQuery },
                });
              }
            }}
          >
            Remove NPIs
          </Button>
          <Button
            width="auto"
            onClick={() => {
              const updateUrl = `/config/${organizationId}/managed-providers/aliases/update`;

              if (selectedRowIds.length) {
                navigate(updateUrl, {
                  state: { npis: selectedRowIds, queryKey: combinedQuery },
                });
              } else {
                navigate(updateUrl, {
                  state: { queryKey: combinedQuery },
                });
              }
            }}
          >
            Add/Modify NPIs
          </Button>
        </Row>
      )}
      <ErrorBoundary FallbackComponent={TableErrorFallback}>
        <AliasesTable
          data={data ? data.rows : []}
          isRefreshing={fetchStatus === "fetching"}
          isLoading={
            isLoading || (fetchStatus === "fetching" && isPreviousData)
          }
          selectedRowIds={selectedRowIds}
          toggleSelectAllRows={toggleSelectAllRows}
          toggleSelectSingleRow={toggleSelectSingleRow}
          pagination={data ? data.pagination : {}}
          changePage={changePage}
          sorting={sort}
          handleSortChange={handleSortChange}
          filter={filter}
          updateFilter={updateFilter}
          queryKey={combinedQuery}
          canUpdate={canUpdateAliases}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Aliases;
