import ReactDatePicker from "react-datepicker";
import {
  ArrowContainer,
  CustomLeftArrow,
  CustomRightArrow,
  DatePickerHeaderContainer,
  DatePickerHeaderText,
  DatePickerHeaderTextContainer,
  DatePickerHeaderWrapper,
} from "./styles";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { MONTH_YEAR_FORMAT } from "./constants";

const DatePickerHeader = (props) => {
  const {
    monthDate,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    minDate,
    maxDate,
  } = props;

  const updateMonthYear = useMemo(
    () => (date) => {
      if (date) {
        changeMonth(date.getMonth());
        changeYear(date.getFullYear());
      }
    },
    [changeMonth, changeYear]
  );

  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const monthYearStr = useMemo(
    () => DateTime.fromJSDate(monthDate).toFormat(MONTH_YEAR_FORMAT),
    [monthDate]
  );

  const onChange = useMemo(
    () => (monthYearValue) => {
      updateMonthYear(monthYearValue);
      setIsPickerVisible(false);
    },
    [updateMonthYear]
  );

  return (
    <DatePickerHeaderContainer>
      <ArrowContainer onClick={decreaseMonth}>
        <CustomLeftArrow />
      </ArrowContainer>

      <DatePickerHeaderTextContainer>
        <DatePickerHeaderText onClick={() => setIsPickerVisible(true)}>
          {monthYearStr}
        </DatePickerHeaderText>
      </DatePickerHeaderTextContainer>

      <ArrowContainer onClick={increaseMonth}>
        <CustomRightArrow />
      </ArrowContainer>

      {isPickerVisible && (
        <DatePickerHeaderWrapper>
          <ReactDatePicker
            showMonthYearPicker
            showFourColumnMonthYearPicker
            showPopperArrow={false}
            previousYearButtonLabel={<CustomLeftArrow />}
            nextYearButtonLabel={<CustomRightArrow />}
            onChange={onChange}
            selected={isPickerVisible ? monthDate : null}
            minDate={minDate?.toJSDate()}
            maxDate={maxDate?.toJSDate()}
            inline
          />
        </DatePickerHeaderWrapper>
      )}
    </DatePickerHeaderContainer>
  );
};

export default DatePickerHeader;
