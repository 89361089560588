import PropTypes from "prop-types";
import {
  RadioInput,
  RadioInputGroup,
  RadioInputLabel,
  StyledRadio,
} from "./styles";

const Radio = (props) => {
  const {
    group,
    options,
    value,
    onChange = () => {},
    isDisabled = false,
    orientation = "vertical",
    variant = "large",
  } = props;
  return (
    <StyledRadio isDisabled={isDisabled}>
      <RadioInputGroup orientation={orientation}>
        {options.map((option) => (
          <RadioInputLabel key={option.label} variant={variant}>
            <RadioInput
              checked={value === option.value}
              type="radio"
              name={group}
              onChange={() => {
                onChange(option.value);
              }}
              variant={variant}
            />
            <div
              className={
                value === option.value
                  ? `${variant} checked`
                  : `${variant} unchecked`
              }
            />
            {option.label}
          </RadioInputLabel>
        ))}
      </RadioInputGroup>
    </StyledRadio>
  );
};

Radio.propTypes = {
  disabled: PropTypes.any,
  group: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
    })
  ).isRequired,
  orientation: PropTypes.string,
  value: PropTypes.any,
};

export default Radio;
