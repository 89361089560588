import { useState } from "react";

import { formatQuery } from "react-querybuilder";
import styled from "styled-components";
import { JSONTree } from "react-json-tree";

const JSONContainer = styled.div`
  font-family: Inter;
  letter-spacing: 0.6px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;

  & > ul {
    background-color: transparent !important;
  }
`;

const DebugBox = ({ isVisible = false, query, filters }) => {
  const theme = {
    base01: "#024073",
    base02: "#044981",
    base03: "#07518e",
    base04: "#0a5a9a",
    base05: "#0e62a6",
    base06: "#126ab1",
    base07: "#1772bc",
    base08: "#1c7ac6",
    base09: "#ffcea6",
    base0A: "#d6e699",
    base0B: "#aef6b2",
    base0C: "#9ff5e1",
    base0D: "#b2e3ff",
    base0E: "#dccbff",
    base0F: "#ffbcff",
  };

  const [debugState, setDebugState] = useState({
    showApiFilters: false,
    showQuery: false,
    showSQL: false,
  });

  const handleCheckboxChanged = (event) => {
    const { name, checked } = event.target;

    setDebugState((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };

  return isVisible ? (
    <div
      style={{
        position: "relative",
        border: "2px dashed black",
        padding: "10px",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-21px",
          left: "20px",
          zIndex: "1000",
          fontSize: "26px",
          fontWeight: "bold",
          backgroundColor: "#F5F2FC",
          padding: "4px",
        }}
      >
        Debug
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          gap: "15px",
          padding: "10px",
          border: "1px solid black",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <label htmlFor="showApiFilters" style={{ fontWeight: "bold" }}>
            Show API Filters:
          </label>
          <input
            id="showApiFilters"
            name="showApiFilters"
            type="checkbox"
            checked={debugState.showApiFilters}
            onChange={handleCheckboxChanged}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <label htmlFor="showQuery" style={{ fontWeight: "bold" }}>
            Show Query:
          </label>
          <input
            id="showQuery"
            name="showQuery"
            type="checkbox"
            checked={debugState.showQuery}
            onChange={handleCheckboxChanged}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <label htmlFor="showSQL" style={{ fontWeight: "bold" }}>
            Show SQL:
          </label>
          <input
            id="showSQL"
            name="showSQL"
            type="checkbox"
            checked={debugState.showSQL}
            onChange={handleCheckboxChanged}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        {debugState.showSQL && (
          <div style={{ flexBasis: "90%" }}>
            <h3 style={{ margin: "10px" }}>SQL WHERE Clause</h3>
            <div style={{ paddingLeft: "15px", paddingBottom: "10px" }}>
              See link for advanced config:
              https://react-querybuilder.js.org/docs/utils/export#configuration
            </div>
            <JSONContainer>{formatQuery(query, "sql")}</JSONContainer>
          </div>
        )}
        {debugState.showApiFilters && (
          <div style={{ flexBasis: "45%" }}>
            <h3 style={{ margin: "10px" }}>API Format</h3>
            {
              <JSONContainer>
                <JSONTree
                  data={filters}
                  theme={theme}
                  invertTheme={true}
                  hideRoot={true}
                  shouldExpandNodeInitially={() => true}
                />
              </JSONContainer>
            }
          </div>
        )}
        {debugState.showQuery && (
          <div style={{ flexBasis: "45%" }}>
            <h3 style={{ margin: "10px" }}>Query Format</h3>
            <JSONContainer>
              <JSONTree
                data={JSON.parse(formatQuery(query, "json"))}
                // data={JSON.parse(formatQuery(query, "json_without_ids"))}
                theme={theme}
                invertTheme={true}
                hideRoot={true}
                shouldExpandNodeInitially={() => true}
                sortObjectKeys={(a, b) => {
                  if (a < b) {
                    return -1;
                  }
                  if (a > b) {
                    return 1;
                  }

                  return 0;
                }}
              />
            </JSONContainer>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default DebugBox;
