import { Outlet } from "react-router-dom";
import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons";

import Tabs from "core/components/Tabs";
import Tab from "core/components/Tab";
import PageHeader from "core/components/PageHeader";

const ManagedProviders = () => {
  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <PageHeader
        title="Managed Providers"
        icon={faPeopleRoof}
        iconBackgroundColor="#00C1F3"
      />
      <Tabs>
        <Tab to="aliases" first>
          Aliases
        </Tab>
        <Tab to="relationships" last>
          Relationships
        </Tab>
      </Tabs>
      <Outlet />
    </div>
  );
};

export default ManagedProviders;
