import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/sharp-solid-svg-icons";

import Typography from "core/components/Typography";

const ErrorPage = () => {
  const location = useLocation();
  const error = location.state?.error || "Something went wrong!";

  return (
    <div
      data-cy="error-page"
      style={{
        height: "100%",
        width: "80%",
        margin: "0px auto",
        padding: "15vh 0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <FontAwesomeIcon icon={faBug} style={{ fontSize: "5rem" }} />
      <Typography variant="h1">Error</Typography>
      <Typography variant="h5">{error}</Typography>
    </div>
  );
};

export default ErrorPage;
