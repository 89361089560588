import PropTypes from "prop-types";
import { useState } from "react";
import { StyledCheckbox, CheckboxInputGroup } from "./styles";
import { CheckboxInputLabel } from "./styles";
import { CheckboxInput } from "./styles";
import { faCheck, faMinus, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CheckBoxIcon = ({ variant, checkboxType, value }) => {
  if (checkboxType === "explicit" && value === "false") {
    return (
      <div className="checked explicit">
        <FontAwesomeIcon
          icon={faXmark}
          style={{
            color: "#fff",
            fontSize: variant === "large" ? 14 : 12,
          }}
        />
      </div>
    );
  } else if (value === "indeterminate") {
    return (
      <div className="checked indeterminate">
        <FontAwesomeIcon
          icon={faMinus}
          style={{
            color: "#fff",
            fontSize: variant === "large" ? 14 : 12,
          }}
        />
      </div>
    );
  } else if (value === "true") {
    return (
      <div className="checked">
        <FontAwesomeIcon
          icon={faCheck}
          style={{
            color: "#fff",
            fontSize: variant === "large" ? 14 : 12,
          }}
        />
      </div>
    );
  }

  return <div className="unchecked" />;
};

const Checkbox = (props) => {
  const {
    options,
    value,
    onChange = () => {},
    isDisabled = false,
    orientation = "vertical",
    variant = "large",
    grouped = false,
    checkboxType = "basic",
  } = props;

  const [focusedBox, setBoxFocus] = useState(null);
  const [isFocused, setFocus] = useState(false);

  const triStateSwitch = (value) => {
    let val;
    switch (value) {
      case "null":
        val = "true";
        break;
      case "true":
        val = "false";
        break;
      case "false":
        val = "null";
        break;
      default:
        val = "null";
        break;
    }
    return val;
  };

  if (grouped) {
    return (
      <StyledCheckbox isDisabled={isDisabled}>
        <CheckboxInputGroup orientation={orientation}>
          {value.map((option, index) => {
            return (
              <CheckboxInputLabel
                key={option.label}
                variant={variant}
                isDisabled={isDisabled}
                focused={focusedBox === index}
              >
                <CheckboxInput
                  checked={option.value === "true"}
                  type="checkbox"
                  onChange={({ target }) => {
                    const index = value.findIndex(
                      (o) => o.label === option.label
                    );
                    const item = value[index];
                    if (checkboxType === "explicit") {
                      const val = triStateSwitch(item.value);
                      item.value = String(val);
                    } else {
                      item.value = String(target.checked);
                    }
                    onChange([...value]);
                  }}
                  variant={variant}
                  onFocus={() => {
                    if (!isDisabled) {
                      setBoxFocus(index);
                    }
                  }}
                  onBlur={() => {
                    setBoxFocus(null);
                  }}
                />
                <CheckBoxIcon
                  variant={variant}
                  checkboxType={checkboxType}
                  value={option.value}
                />
                {option.label}
              </CheckboxInputLabel>
            );
          })}
        </CheckboxInputGroup>
      </StyledCheckbox>
    );
  } else {
    return (
      <StyledCheckbox isDisabled={isDisabled}>
        <CheckboxInputLabel
          key={options[0].label}
          variant={variant}
          isDisabled={isDisabled}
          focused={isFocused}
        >
          <CheckboxInput
            checked={value === "true"}
            type="checkbox"
            onChange={({ target }) => {
              let val;
              if (checkboxType === "explicit") {
                val = triStateSwitch(value);
                onChange(String(val));
              } else {
                onChange(String(target.checked));
              }
            }}
            onFocus={() => {
              if (!isDisabled) {
                setFocus(true);
              }
            }}
            onBlur={() => {
              setFocus(null);
            }}
            variant={variant}
          />
          <CheckBoxIcon
            variant={variant}
            checkboxType={checkboxType}
            value={value}
          />
          {options[0].label}
        </CheckboxInputLabel>
      </StyledCheckbox>
    );
  }
};

Checkbox.propTypes = {
  checkboxType: PropTypes.string,
  grouped: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  orientation: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variant: PropTypes.string,
};

export default Checkbox;

// Notes

// Default - checkboxType is basic.
// Values  - true/false/indeterminate (type of string)
// [we are using string because we have 4 {true/false/indeterminate/null} values in total to be supported]
// True value  - faCheck Icon
// False value - Empty
// Indeterminate - faMinus Icon

// Explicit False - checkboxType is explicit
// Values  - true/false/null (type of string)
// True value  - faCheck Icon
// False value - faXmark Icon
// Null value  - empty
// Proposed usecase - As include/exclude checbox filter

// Use case for indeterminate value - This comes into picture when we have a check box with children check boxes.
// So, indeterminate value make sense for Single checkbox (unsupported for group).
// We have to control the value for Indeterminate checkbox from parent, based on the values of all the children checkboxes.
// If all children checkbox items are True, then this check box will show True.
// If all children checkbox items are False, then this check box will show False.
// If children checkbox items do not have same values, then this check box will show indeterminate.
