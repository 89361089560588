import { useContext } from "react";
import FlashContext from "../context/FlashContext";

const useFlash = () => {
  const { success, setSuccess, error, setError } = useContext(FlashContext);

  return {
    success,
    setSuccess,
    error,
    setError,
  };
};

export default useFlash;
