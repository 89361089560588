import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledTab = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 145px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;

  &:hover {
    background-color: #dadde1;
  }

  ${(props) =>
    props.selected &&
    `
      background-color: #541299;
      color: #ffffff;
      cursor: default;

      &:hover {
        background-color: #6d36a5;
        color: #ffffff;
      }
  `}
`;

const Tab = ({ children, to, first, last }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selected = location.pathname.endsWith(`/${to}`);

  return (
    <StyledTab selected={selected} onClick={() => navigate(to)}>
      {children}
    </StyledTab>
  );
};

export default Tab;
