import { generateID } from "react-querybuilder";
import {
  rangeOperators,
  multiValueOperators,
  nullValueOperators,
} from "./constants";

export const isNullOperator = (operator) =>
  nullValueOperators.map((o) => o.value).includes(operator);

export const isRangeOperator = (operator) =>
  rangeOperators.map((o) => o.value).includes(operator);

export const isListOperator = (operator) =>
  multiValueOperators.map((o) => o.value).includes(operator);

export const buildQueryRuleFromApiFilter = (apiFilter = {}) => {
  const isGroup = apiFilter.hasOwnProperty("filters");

  if (isGroup) {
    return {
      id: apiFilter.id || generateID(),
      combinator: apiFilter.operator,
      rules: apiFilter.filters.map(buildQueryRuleFromApiFilter),
    };
  }

  const isRangeFilter = isRangeOperator(apiFilter.operator);

  return {
    id: apiFilter.id || generateID(),
    field: apiFilter.field,
    operator: apiFilter.operator,
    value: isRangeFilter ? [apiFilter.from, apiFilter.to] : apiFilter.value,
  };
};

const staticId = generateID();
export const convertFiltersToQuery = (filters = [], isLoading = false) => {
  if (isLoading) {
    return {
      id: staticId,
      combinator: "and",
      rules: [
        {
          id: generateID(),
          combinator: "and",
          isLoading: true,
          rules: [
            {
              id: generateID(),
              operator: "=",
              isLoading: true,
            },
            {
              id: generateID(),
              operator: "=",
              isLoading: true,
            },
          ],
        },
      ],
    };
  } else {
    return filters.reduce(
      (query, currentFilter) => {
        const convertedFilter = buildQueryRuleFromApiFilter(currentFilter);
        query.rules = query.rules.concat(convertedFilter);

        return query;
      },
      {
        id: staticId,
        combinator: "and",
        rules: [],
      }
    );
  }
};

export const buildApiFilterFromQueryRule = (queryRule = {}) => {
  const isGroup = queryRule.hasOwnProperty("combinator");

  if (isGroup) {
    return {
      id: queryRule.id,
      operator: queryRule.combinator,
      filters: queryRule.rules.map(buildApiFilterFromQueryRule),
    };
  }

  const isRangeFilter = isRangeOperator(queryRule.operator);
  const fieldValueObject = isRangeFilter
    ? { from: queryRule.value[0], to: queryRule.value[1] }
    : { value: queryRule.value };

  return {
    id: queryRule.id,
    field: queryRule.field,
    operator: queryRule.operator,
    ...fieldValueObject,
  };
};

export const convertQueryToFilters = (query = {}) => {
  return query.rules.map(buildApiFilterFromQueryRule);
};

export const retrofitSelectChangeHandler =
  (originalHandler, isMultiSelect) => (valueContainer) => {
    if (isMultiSelect) {
      return originalHandler(valueContainer.map((item) => item.value));
    } else {
      return originalHandler(valueContainer.value);
    }
  };

export const retrofitMultiNumberChangeHandler =
  (originalHandler) => (event) => {
    const formattedValue = event.target.value
      .replaceAll(/[^0-9,. ]+/g, "")
      .split(/\s*,\s*/)
      .map((v) => Number(v));

    originalHandler(formattedValue);
  };

export const retrofitTextChangeHandler = (originalHandler) => (event) =>
  originalHandler(event.target.value);

export const getSelectValueFromOptions = (options, value, isMultiSelect) => {
  if (isMultiSelect) {
    return (
      options.filter((option) => value?.includes(option.value)) || options[0]
    );
  } else {
    return options.find((option) => option.value === value) || options[0];
  }
};
