import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import {
  TextInput,
  FieldControlSkeleton,
  SearchContainer,
  SearchIcon,
  ResetIcon,
} from "../styles";
import { useState, useRef } from "react";

const Search = ({
  id,
  name,
  type = "search",
  value,
  onChange,
  placeholder = "",
  styles = { container: {}, input: {}, cancelButton: {}, icon: {} },
  autoFocus = false,
  variant = "large",
  isDisabled = false,
  showSkeleton = false,
  error,
}) => {
  const [searchText, setSearchText] = useState(value);
  const inputRef = useRef();

  const handleResetClick = () => {
    setSearchText("");
    inputRef?.current.focus();
  };
  return (
    <SearchContainer variant={variant} style={{ ...styles.container }}>
      {showSkeleton ? (
        <FieldControlSkeleton type={type} size={variant}>
          <Skeleton />
        </FieldControlSkeleton>
      ) : (
        <>
          <TextInput
            id={id}
            type={type}
            variant={variant}
            disabled={isDisabled}
            field={name || "search"}
            name={name || "search"}
            placeholder={placeholder}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            style={{ ...styles.input }}
            cancelButtonStyles={styles.cancelButton}
            autoFocus={autoFocus}
            error={error}
            ref={inputRef}
          />
          {searchText === "" ? (
            <SearchIcon
              icon={faMagnifyingGlass}
              variant={variant}
              disabled={isDisabled}
              style={{ ...styles.icon }}
            />
          ) : (
            <div onClick={handleResetClick}>
              <ResetIcon
                icon={faXmark}
                variant={variant}
                style={{ ...styles.icon }}
              />
            </div>
          )}
        </>
      )}
    </SearchContainer>
  );
};

export default Search;
