export const DURATION_1_SECOND = 1000;
export const DURATION_1_MIN = 60 * DURATION_1_SECOND;
export const DURATION_5_MIN = 5 * DURATION_1_MIN;
export const DURATION_25_MIN = 25 * DURATION_1_MIN;
export const DURATION_30_MIN = 30 * DURATION_1_MIN;

// leverage a luhn validator for npi numbers.
// more info here: https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
const validateNPI = (npi) => {
  let tmp;
  let sum;
  let i = npi.length;
  let j;
  if (i === 15 && npi.slice(0, 5) === "80840") sum = 0;
  else if (i === 10) sum = 24;
  else return false;
  j = 0;
  while (i !== 0) {
    tmp = npi.charCodeAt(i - 1) - "0".charCodeAt(0);
    if (j++ % 2 !== 0) {
      if ((tmp <<= 1) > 9) {
        tmp -= 10;
        tmp++;
      }
    }
    sum += tmp;
    i--;
  }
  if (sum % 10 === 0) return true;
  else return false;
};

const validateZip = (value) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace("#", "");
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

export const ENVIRONMENTS = {
  TEST: "testing",
  DEV: "development",
  QA: "qa",
  PROD: "production",
};

export const getEnv = () => {
  switch (window.location.hostname) {
    case "populi-ui-test.web.app":
    case "populi-ui-test.firebaseapp.com":
    case "test-app.populi.ai":
      return ENVIRONMENTS.TEST;

    case "populi-ui-qa.web.app":
    case "populi-ui-qa.firebaseapp.com":
    case "qa-app.populi.ai":
      return ENVIRONMENTS.QA;

    case "populi-ui-prod.web.app":
    case "populi-ui-prod.firebaseapp.com":
    case "app.populi.ai":
      return ENVIRONMENTS.PROD;

    case "populi-ui-dev.web.app":
    case "populi-ui-dev.firebaseapp.com":
    case "dev-app.populi.ai":
      return ENVIRONMENTS.DEV;

    case "localhost":
    default:
      return (
        ENVIRONMENTS[process.env.REACT_APP_ENVIRONMENT] || ENVIRONMENTS.DEV
      );
  }
};

const getApiUrlPrefix = () => {
  const env = getEnv();

  switch (env) {
    case ENVIRONMENTS.TEST:
      return "https://populi-testing.firebaseapp.com";

    case ENVIRONMENTS.QA:
      return "https://populi-qa.firebaseapp.com";

    case ENVIRONMENTS.PROD:
      return "https://populi-prod.firebaseapp.com";

    case ENVIRONMENTS.DEV:
    default:
      return "https://populi-dev.firebaseapp.com";
  }
};

export const objectWithFilteredKeys = (obj, ignoredKeys) => {
  const keys = Object.keys(obj);
  const allowedKeys = keys.filter((key) => !ignoredKeys.includes(key));

  return allowedKeys.reduce((result, key) => {
    const isValueObject =
      obj[key] && !Array.isArray(obj[key]) && typeof obj[key] === "object";

    if (isValueObject) {
      result[key] = objectWithFilteredKeys(obj[key], ignoredKeys);
    } else {
      result[key] = obj[key];
    }

    return result;
  }, {});
};

export const objectWithTranslatedKeys = (object, dictionary) => {
  return Object.entries(object).reduce((object, entry) => {
    let [key, value] = entry;

    const isValueObject =
      value && !Array.isArray(value) && typeof value === "object";

    if (isValueObject) {
      value = objectWithTranslatedKeys(value, dictionary);
    }

    if (dictionary[key]) {
      object[dictionary[key]] = value;
    } else {
      object[key] = value;
    }

    return object;
  }, {});
};

export const friendlyNumber = (value) => {
  // make it "human readable" by adding a suffix, 1000 -> 1k, 1000000 -> 1m, etc.
  const symbolMap = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let i;
  for (i = symbolMap.length - 1; i > 0; i--) {
    if (value >= symbolMap[i].value) {
      break;
    }
  }

  return (
    (Math.round(value) / symbolMap[i].value).toFixed(1).replace(regex, "$1") +
    symbolMap[i].symbol
  );
};

export const formatSpecialCharacters = (value) => {
  let formattedValue = "";

  for (let i = 0; i < value.length; i++) {
    if (/[().,;:+@-_%']/g.test(value[i])) {
      const val = "\\" + value[i];
      formattedValue = formattedValue + val;
    } else {
      formattedValue = formattedValue + value[i];
    }
  }

  return formattedValue;
};

const Utils = {
  getEnv,
  getApiUrlPrefix,
  newShade,
  validateNPI,
  validateZip,
  objectWithFilteredKeys,
};

export default Utils;
