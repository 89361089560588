import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/sharp-solid-svg-icons";
import { Link } from "react-router-dom";

export const ContainedButton = styled(Link)`
  display: block;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  padding: 12px 24px;
  padding-top: 11px;
  margin: 0.25rem 0;
  background-color: #541299;
  cursor: pointer;
  height: 41px;
  width: ${(props) => (props.width ? props.width : "100%")};

  &:hover {
    background-color: #6d36a5;
    text-decoration: none;
  }

  // Override default font awesome spin speed
  & .fa-spin {
    animation-duration: 1s;
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: #d7d7d7;
    pointer-events: none;
    cursor: default;
  `}
`;

export const OutlinedButton = styled(Link)`
  display: block;
  border: solid 1px #541299;
  border-radius: 5px;
  color: white;
  font-family: "Inter";
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  padding: 12px 24px;
  padding-top: 11px;
  margin: 0.25rem 0;
  background-color: #ffffff;
  color: #541299;
  cursor: pointer;
  height: 41px;
  width: ${(props) => (props.width ? props.width : "100%")};

  &:hover {
    background-color: #ddcdef;
    text-decoration: none;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  `}
`;

export const TextButton = styled(Link)`
  border: none;
  font-family: "Inter";
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  padding: 12px 24px;
  padding-top: 11px;
  margin: 0.25rem 0;
  color: #541299;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "auto")};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: #ccc;
    pointer-events: none;
    cursor: default;
  `}
`;

const VARIANTS = {
  contained: ContainedButton,
  outlined: OutlinedButton,
  text: TextButton,
};

const ButtonLink = (props) => {
  const { variant, isLoading, disabled, disabledMessage, children } = props;

  const VariantButton = VARIANTS[variant] || VARIANTS.contained;

  return (
    <VariantButton title={disabled ? disabledMessage : ""} {...props}>
      {isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
    </VariantButton>
  );
};

export default ButtonLink;
