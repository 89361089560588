import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/sharp-solid-svg-icons";
import { getColor } from "theme";

export const ContainedButton = styled.button`
  display: block;
  border: 0;
  border-radius: 5px;
  color: ${(props) =>
    getColor(props.theme, "components.button.contained.textColor")};
  font-family: "Inter";
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 12px 24px;
  margin: 0px;
  background-color: ${(props) =>
    getColor(props.theme, "components.button.contained.backgroundColor")};
  cursor: pointer;
  height: 41px;
  width: ${(props) => (props.width ? props.width : "100%")};

  &:hover {
    background-color: ${(props) =>
      getColor(
        props.theme,
        "components.button.contained.hoverBackgroundColor"
      )};
  }

  // Override default font awesome spin speed
  & .fa-spin {
    animation-duration: 1s;
  }

  &:disabled {
    background-color: #d7d7d7;
    pointer-events: none;
    cursor: default;
  }

  ${(props) =>
    props.isLoading &&
    `
      background-color: #d7d7d7;
      pointer-events: none;
      cursor: default;
    `}
`;

export const OutlinedButton = styled.button`
  display: block;
  border: solid 1px
    ${(props) =>
      getColor(props.theme, "components.button.outlined.borderColor")};
  border-radius: 5px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 32px;
  margin: 0px;
  background-color: ${(props) =>
    getColor(props.theme, "components.button.outlined.backgroundColor")};
  color: ${(props) =>
    getColor(props.theme, "components.button.outlined.textColor")};
  cursor: pointer;
  height: 41px;
  width: ${(props) => (props.width ? props.width : "100%")};

  &:hover {
    background-color: ${(props) =>
      getColor(props.theme, "components.button.outlined.hoverBackgroundColor")};
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;

export const TextButton = styled.button`
  display: block;
  border: none;
  font-family: "Inter";
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 6px;
  margin: 0px;
  background-color: ${(props) =>
    getColor(props.theme, "components.button.text.backgroundColor")};
  color: ${(props) =>
    getColor(props.theme, "components.button.text.textColor")};
  cursor: pointer;
  height: 41px;
  width: ${(props) => (props.width ? props.width : "100%")};

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
    cursor: default;
  }
`;

const VARIANTS = {
  contained: ContainedButton,
  outlined: OutlinedButton,
  text: TextButton,
};

const Button = (props) => {
  const { variant, isLoading, disabled, disabledMessage, children } = props;

  const VariantButton = VARIANTS[variant] || VARIANTS.contained;

  return (
    <VariantButton title={disabled ? disabledMessage : ""} {...props}>
      {isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
    </VariantButton>
  );
};

export default Button;
