import { useState } from "react";
import NavigationContext from "./NavigationContext";
import useUserSettings from "core/hooks/useUserSettings";

const NavigationProvider = ({ children }) => {
  const { settings, setSettings } = useUserSettings();
  const [userSearchValue, setUserSearchValue] = useState("");
  const [orgSearchValue, setOrgSearchValue] = useState("");
  const [currentUserPageNumber, setCurrentUserPageNumber] = useState(0);
  const [currentOrgPageNumber, setCurrentOrgPageNumber] = useState(0);

  const setNavOpen = (newValue) => {
    setSettings({ isNavigationOpen: newValue });
  };

  return (
    <NavigationContext.Provider
      value={{
        isNavOpen: settings.isNavigationOpen,
        setNavOpen,
        userSearchValue,
        setUserSearchValue,
        orgSearchValue,
        setOrgSearchValue,
        currentUserPageNumber,
        setCurrentUserPageNumber,
        currentOrgPageNumber,
        setCurrentOrgPageNumber,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
