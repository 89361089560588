import { useState } from "react";
import { Utils } from "common";

const env = Utils.getEnv();

const KEYS = {
  AUTH_TOKEN: `populi:${env}:auth`,
  REFRESH_TOKEN: `populi:${env}:refreshToken`,
  EXPIRATION_TIME: `populi:${env}:expirationTime`,
  LAST_ACTIVE_TIME: `populi:${env}:lastActiveTime`,
  USER_ROLE: `populi:${env}:role`,
  ORG_ID: `populi:${env}:orgId`,
  NAME: `populi:${env}:name`,
  ORG_NAME: `populi:${env}:orgName`,
  PASSWORD_META: `populi:${env}:passwordMeta`,
  IS_PASSWORD_EXPIRING: `populi:${env}:isPasswordExpiring`,
};

const KEY_LOOKUP = Object.keys(KEYS).reduce((obj, key) => {
  obj[KEYS[key]] = key;
  return obj;
}, {});

const useLocalStore = () => {
  const [error, setError] = useState("");

  const getStore = () => {
    return Object.keys(KEYS).reduce((map, key) => {
      return {
        ...map,
        [key]: localStorage.getItem(KEYS[key]),
      };
    }, {});
  };

  const setKey = (key, value) => {
    setError("");

    if (KEYS[KEY_LOOKUP[key]]) {
      localStorage.setItem(key, value);
      window.dispatchEvent(new Event("storage"));
    }

    return setError(
      `${key} doesn't exist. Check the name of the key used, or extend the existing default keymap.`
    );
  };

  const deleteKey = (key) => {
    setError("");

    if (KEYS[KEY_LOOKUP[key]]) {
      localStorage.removeItem(key);
      window.dispatchEvent(new Event("storage"));
    }

    return setError(
      `${key} doesn't exist. Check the name of the key used, or extend the existing default keymap.`
    );
  };

  // Todo:
  // const clearStore = () => localStorage.clear()...

  return {
    KEYS,
    getStore,
    setKey,
    deleteKey,
    error,
  };
};

export default useLocalStore;
