import { useState } from "react";

import BulkPasteContext from "./BulkPasteContext";

const BulkPasteProvider = ({
  children,
  id,
  size,
  values,
  validator,
  label,
  onChange,
  isDisabled,
}) => {
  // ! Anti Pattern - makes this an uncontrolled component
  // const [values, setValues] = useState(initialValues || []);

  const [draftValues, setDraftValues] = useState("");
  const [validValues, setValidValues] = useState([]);
  const [invalidValues, setInvalidValues] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [validationVisible, setValidationVisible] = useState(false);
  const [editModeIndex, setEditModeIndex] = useState(null);

  return (
    <BulkPasteContext.Provider
      value={{
        id,
        size,
        values, // Allow values to pass through
        setValues: onChange, // This is exactly the same thing as setValues
        draftValues,
        setDraftValues,
        validValues,
        setValidValues,
        invalidValues,
        setInvalidValues,
        validator,
        label,
        modalVisible,
        setModalVisible,
        validationVisible,
        setValidationVisible,
        editModeIndex,
        setEditModeIndex,
        onChange,
        isDisabled,
      }}
    >
      {children}
    </BulkPasteContext.Provider>
  );
};

export default BulkPasteProvider;
