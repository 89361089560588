import PropTypes from "prop-types";
import styled from "styled-components";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledPill = styled.div`
  font-family: Inter;
  text-align: center;
  width: fit-content;
  background-color: #dadde1;
  color: #000000;

  ${(props) =>
    props.size === "small" &&
    `
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 10px;

    `}

  ${(props) =>
    props.size === "large" &&
    `
    font-size: 18px;
    padding: 4px 15px;
    border-radius: 16px;

  `}

  ${(props) =>
    props.variant === "info" &&
    `
    background-color: #007EAC;
    color: #FFFFFF;
  `}

  ${(props) =>
    props.variant === "pending" &&
    `
    background-color: #5F6467;
    color: #FFFFFF;
  `}

  ${(props) =>
    props.variant === "warning" &&
    `
    background-color: #D68712;
    color: #FFFFFF;
  `}

  ${(props) =>
    props.variant === "failed" &&
    `
    background-color: #BA351C;
    color: #FFFFFF;
  `}

  ${(props) =>
    props.variant === "success" &&
    `
    background-color: #008844;
    color: #FFFFFF;
  `}

  ${(props) =>
    props.hasTooltip &&
    `

    & > div {
      display: flex;
      align-items: center;

    & > div {
      display: flex;
      align-items: center;
      position: relative;
    }

      svg {
        margin-left: 7px;
        cursor: pointer;
      }
    }
  `}
`;

const Pill = ({
  text = "",
  size = "small",
  variant = "default",
  tooltipId = "",
  tooltipContent = "",
  textDataCy = "",
  tooltipStrategy = "absolute",
}) => {
  return (
    <StyledPill size={size} variant={variant} hasTooltip={!!tooltipContent}>
      {tooltipContent ? (
        <div>
          <div data-cy={textDataCy}>{text}</div>
          <div>
            <FontAwesomeIcon
              data-tooltip-id={tooltipId}
              data-tooltip-html={tooltipContent}
              data-tooltip-position-strategy={tooltipStrategy}
              icon={faCircleInfo}
              style={{
                width: "16px",
                height: "16px",
                boxSizing: "border-box",
              }}
            />
          </div>
        </div>
      ) : (
        <span data-cy={textDataCy}>{text}</span>
      )}
    </StyledPill>
  );
};

Pill.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    "default",
    "info",
    "pending",
    "warning",
    "failed",
    "success",
    "pending",
  ]),
  size: PropTypes.oneOf(["small", "large"]),
  tooltipContent: PropTypes.string,
};

export default Pill;
