import { API } from "common";

// Matt: Giving this a test run here first
const buildQuery = (baseUrl, params) => {
  let url = `${baseUrl}?`;

  Object.keys(params).forEach((paramKey) => {
    if (!!params[paramKey]) {
      url = `${url}&${paramKey}=${params[paramKey]}`;
    }
  });

  return url;
};

export const getAliases = ({
  accessToken,
  organizationId,
  sort,
  meta: { pageToken },
  filter = {},
}) => {
  const url = buildQuery("/provider-alias", {
    include_hcx: true,
    organizationId: organizationId,
    page_token: pageToken,
    sort,
    limit: 18,
    ...filter,
  });

  return API.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getRelationships = ({
  accessToken,
  organizationId,
  sort,
  meta: { pageToken },
  filter = {},
}) => {
  const url = buildQuery("/provider-relationships", {
    include_hcx: true,
    organizationId: organizationId,
    page_token: pageToken,
    sort,
    limit: 18,
    ...filter,
  });

  return API.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateRelationships = ({
  accessToken,
  organizationId,
  relationships,
}) => {
  return API.put(
    `/provider-relationships?organizationId=${organizationId}`,
    {
      provider_relationships: relationships,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateAliases = ({ accessToken, organizationId, aliases }) => {
  return API.put(
    `/provider-alias?organizationId=${organizationId}`,
    {
      provider_alias: aliases,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const removeRelationships = ({ accessToken, organizationId, npis }) => {
  // Note: Axios Delete has a different structure than POST/PUT
  // body and headers all fall into single object
  const url = buildQuery("/provider-relationships", {
    organizationId: organizationId,
  });

  return API.delete(url, {
    data: { npis },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const removeAliases = ({ accessToken, organizationId, npis }) => {
  // Note: Axios Delete has a different structure than POST/PUT
  // body and headers all fall into single object
  const url = buildQuery("/provider-alias", {
    organizationId: organizationId,
  });

  return API.delete(url, {
    data: { npis },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
