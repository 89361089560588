import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { SwitchContainer, SwitchRoot, SwitchThumb } from "./styles";
import { Label } from "../Form";
import { useMemo } from "react";
import { FieldError } from "../Form/styles";

const Toggle = ({
  id = "",
  value,
  onChange,
  buttonStyle,
  isDisabled = false,
  isLoading = false,
  label,
  labelPosition = "top",
  error,
}) => {
  const flexDirection = useMemo(
    () => (labelPosition === "top" ? "column" : "row"),
    [labelPosition]
  );
  const alignItems = useMemo(
    () => (labelPosition === "top" ? "start" : "center"),
    [labelPosition]
  );

  return (
    <div style={{ width: "fit-content" }}>
      <SwitchContainer style={{ flexDirection, alignItems }}>
        {label && <Label disabled={isDisabled}>{label}</Label>}
        {isLoading ? (
          <Skeleton
            style={{ width: "51px", height: "24px", borderRadius: "9999px" }}
          />
        ) : (
          <SwitchRoot
            id={id}
            disabled={isDisabled}
            checked={value}
            onCheckedChange={onChange}
            style={buttonStyle}
          >
            <SwitchThumb />
          </SwitchRoot>
        )}
      </SwitchContainer>
      <FieldError>{error}</FieldError>
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["left", "top"]),
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  buttonStyle: PropTypes.object,
};

export default Toggle;
