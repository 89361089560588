import styled from "styled-components";

export const StyledCheckbox = styled.div`
  width: fit-content;
  margin-bottom: 22px;
  ${(props) =>
    props.isDisabled &&
    `
    opacity: .5;
    pointer-events: none;
    cursor:
  `}
`;

export const CheckboxInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "column" : "row"};
  gap: 10px;
  margin-top: 10px;
`;

export const CheckboxInput = styled.input`
  height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
  width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
  margin-right: 8px;
  opacity: 0;
`;

export const CheckboxInputLabel = styled.label`
  font-size: 12px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: relative;
  color: #2b2826;
  line-height: 12px;

  .unchecked {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    background-color: #fff;
    border: ${({ focused }) =>
      focused ? "1px solid #0097C0" : "1px solid #dddbda"};
    border-radius: 4px;
  }

  .checked {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    width: ${({ variant }) => (variant === "large" ? "20px" : "16px")};
    background-color: #fff;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ isDisabled, focused }) =>
      isDisabled ? "#D8D8D8" : focused ? "#300866" : "#0097c0"};
  }

  .explicit {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ isDisabled }) => (isDisabled ? "#D8D8D8" : "#BA351C")};
  }

  .listOption {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ isDisabled }) => (isDisabled ? "#D8D8D8" : "#0097C0")};
  }

  &:hover {
    cursor: pointer;
  }
`;
