import { animated, useSpring } from "react-spring";

const showStyles = {
  display: "flex",
  opacity: 1,
  minHeight: "45px",
};

const hideStyles = {
  display: "none",
  opacity: 0,
};

const baseStyles = {
  backgroundColor: "#007EAC",
  width: "100%",
  color: "white",
  fontSize: "14px",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Inter",
  fontWeight: "600",
  padding: "10px 30px",
  borderRadius: "5px",
};

const InfoFlash = ({ style, children }) => {
  const styles = children
    ? { ...baseStyles, ...showStyles, ...style }
    : hideStyles;
  const slideInStyles = useSpring({
    config: { mass: 5, tension: 50, friction: 10 },
    delay: 500,
    ...styles,
  });

  return <animated.div style={slideInStyles}>{children}</animated.div>;
};

export default InfoFlash;
