import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${(props) => props.columns};
  gap: ${(props) => props.gap || "0px"};
  justify-content: ${(props) => props.justify || "start"};
  margin-bottom: ${(props) => props.lift || "default"};

  @media (max-width: 960px) {
    grid-auto-flow: row;
  }
`;

export const GridColumn = styled.div`
  text-align: ${(props) => props.align || "default"};
`;

export const Title = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 39px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px;
  text-align: left;
`;

export const TitleSm = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  text-align: left;
`;

// Matt: This should be co-located with implementation since it isn't really reusable
export const OrgAvatar = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  background-color: #d68712;
  text-align: center;
  color: #ffffff;
  padding-top: 20px;
`;

export const Heading1 = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
`;

export const Avatar = styled.div`
  height: 80px;
  width: 80px;
  background-color: #541299;
  border-radius: 40px;
  color: #ffffff;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  padding-top: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "normal"};

  ${(props) =>
    props.alignItems &&
    `
    align-items: ${props.alignItems};
  `}

  ${(props) =>
    props.gap &&
    `
    gap: ${props.gap};
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap[props.gap || "medium"]};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "normal"};
`;

export const TinyIcon = styled(FontAwesomeIcon)`
  display: flex;
  box-sizing: content-box;
  margin: 0px;
  padding: 1px;
  width: 8px;
  height: 8px;
  color: #706e6b;
  border: solid 1px #706e6b;
  border-radius: 6px;

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const TooltipHeading = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  padding-bottom: 2px;
`;

export const TooltipLabel = styled.div`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
  flex-shrink: 2;
  flex-wrap: wrap;
  padding-top: 1px;
`;

export const TooltipIcon = styled(FontAwesomeIcon)`
  display: flex;
  box-sizing: content-box;
  margin: 0px;
  padding: 3px;
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 2px;
  background-color: #006cca;
  flex-shrink: 0;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const Line = styled.div`
  background-color: #9f9f9f;
  opacity: 0.5;
  width: 100%;
  height: 1px;
  margin: 20px 0px;
`;
