import { useState } from "react";

import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";
import { required, validate } from "common/validation";

const BasicNPIForm = ({
  onSubmit,
  onSuccess,
  onCancel,
  defaultValues,
  isLoading,
}) => {
  // Trap default values and prevent react form from resetting on re-render
  const [formState] = useState(defaultValues);

  return (
    <Form
      mode="create"
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      onCancel={onCancel}
      defaultValues={formState}
      submitLabel="Submit"
      isLoading={isLoading}
    >
      <Input
        type="textarea"
        field="npis"
        label="NPIs"
        validate={validate(required())}
        isLoading={isLoading}
        autoComplete="npis"
        placeholder="Enter a list of NPI numbers"
        disabled={defaultValues.npis.length}
      />
    </Form>
  );
};

export default BasicNPIForm;
