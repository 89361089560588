import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import Chip from "../Chip";
import {
  InputErrorIcon,
  MultiTextInput,
  TextInput,
  MultiContainer,
  ClearValueButton,
  FieldHint,
} from "./styles";

const TextField = (props) => {
  const {
    id,
    type,
    variant,
    placeholder,
    isReadOnly,
    isDisabled,
    value,
    error,
    onChange,
    validateBeforeChange,
    onBlur,
    onPaste,
    isMultiValue = false,
    ...rest
  } = props;
  const inputRef = useRef();
  const [draftValue, setDraftValue] = useState("");
  const [showHint, setShowHint] = useState(false);

  const handleDraftChange = (event) => {
    const updatedDraftValue = event.target.value;

    // Reset validation error when draftValue is cleared
    if (!updatedDraftValue || updatedDraftValue.length === 1) {
      validateBeforeChange(value);
    }

    if (updatedDraftValue.trim().length <= 5 && showHint) {
      setShowHint(false);
    }

    if (updatedDraftValue.trim().length > 5 && !showHint) {
      setTimeout(() => {
        setShowHint(true);
      }, 1500);
    }

    setDraftValue(updatedDraftValue);
  };

  const handleClearValueClick = () => {
    onChange([]);
    setDraftValue("");
    inputRef?.current.focus();
  };

  if (isMultiValue) {
    return (
      <div style={{ position: "relative" }}>
        <MultiContainer
          isDisabled={isDisabled}
          hasError={!!error}
          variant={variant}
          onClick={() => inputRef?.current.focus()}
        >
          {value.length > 0 && (
            <ClearValueButton
              variant={variant}
              hasError={!!error}
              onClick={handleClearValueClick}
            >
              <FontAwesomeIcon icon={faXmark} />
            </ClearValueButton>
          )}

          {value.map((singleValue, index) => {
            return (
              <Chip
                key={index}
                tabIndex={index + 1}
                text={singleValue}
                variant="actionable"
                isDisabled={isDisabled}
                size={variant}
                onDismiss={() => {
                  // Remove value from value list
                  onChange(value.filter((v, i) => i !== index));
                  inputRef?.current.focus();
                }}
              />
            );
          })}

          <MultiTextInput
            id={id}
            name={id}
            tabIndex={value.length + 2}
            type="text"
            variant={variant}
            placeholder={placeholder}
            value={draftValue}
            ref={inputRef}
            disabled={isDisabled}
            readOnly={isReadOnly}
            onChange={handleDraftChange}
            onKeyDown={(event) => {
              // When user hits backspace
              if (event.key === "Backspace") {
                // Check to make sure input has value
                const inputHasValue = draftValue.trim().length > 0;

                if (!inputHasValue) {
                  // Remove last value chip
                  onChange(value.toSpliced(-1, 1));
                }
              }

              // When user hits enter
              if (event.key === "Enter") {
                // Check to make sure input has value
                const inputHasValue = draftValue.trim().length > 0;

                if (inputHasValue) {
                  // Prevent form from automatically submitting when user presses enter with draft values
                  event.preventDefault();

                  // Pass up draft value to field instance
                  const errorMessage = validateBeforeChange(
                    value.concat(draftValue.trim())
                  );

                  if (errorMessage) {
                    return;
                  }

                  // Reset draft input value
                  setDraftValue("");
                }
              }
            }}
            {...rest}
          />
        </MultiContainer>

        {error && (
          <InputErrorIcon icon={faCircleExclamation} variant={variant} />
        )}

        <FieldHint isVisible={showHint && variant !== "small"}>
          Press <Chip text="Enter" />
        </FieldHint>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <TextInput
        type="text"
        id={id}
        name={id}
        variant={variant}
        placeholder={placeholder}
        readOnly={isReadOnly}
        disabled={isDisabled}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={(event) => {
          props.setValue((value) => value?.trim());

          // Bubble
          onBlur && onBlur(event);
        }}
        {...rest}
      />
      {error && <InputErrorIcon icon={faCircleExclamation} variant={variant} />}
    </div>
  );
};

export default TextField;
