import { useMemo } from "react";

import useBulkPaste from "./useBulkPaste";
import BulkPasteValidatorTable from "./BulkPasteValidatorTable";
import {
  ModalButton,
  ModalButtonContainer,
  ModalColumn,
  ModalHeader,
  ModalText,
} from "./styles";

const BulkPasteValidator = () => {
  const { label, invalidValues, hideModal, saveValidator, editModeIndex } =
    useBulkPaste();

  const isSaveValidatorDisabled = useMemo(() => {
    // Disabled if currently editing a value
    return editModeIndex !== null;
  }, [editModeIndex]);

  return (
    <ModalColumn>
      <ModalHeader>Validate {label}</ModalHeader>

      <ModalText>
        There were {invalidValues.length} invalid {label.toLowerCase()}. Please
        correct the errors below, uncorrected {label.toLowerCase()} will not be
        added.
      </ModalText>

      <BulkPasteValidatorTable />

      <ModalButtonContainer>
        <ModalButton type="button" variant="outlined" onClick={hideModal}>
          Cancel
        </ModalButton>

        <ModalButton
          type="button"
          onClick={saveValidator}
          disabled={isSaveValidatorDisabled}
        >
          Add {label}
        </ModalButton>
      </ModalButtonContainer>
    </ModalColumn>
  );
};

export default BulkPasteValidator;
