export const rangeOperators = [
  { name: "between", value: "between", label: "between" },
  { name: "not between", value: "is not between", label: "not between" },
];

export const singleValueOperators = [
  { name: "=", value: "=", label: "=" },
  { name: "!=", value: "!=", label: "!=" },
  { name: "<", value: "<", label: "<" },
  { name: ">", value: ">", label: ">" },
  { name: "<=", value: "<=", label: "<=" },
  { name: ">=", value: ">=", label: ">=" },
];

export const multiValueOperators = [
  { name: "is in", value: "is in", label: "in" },
  { name: "is not in", value: "is not in", label: "not in" },
];

export const nullValueOperators = [
  { name: "is null", value: "is null", label: "is null" },
  { name: "is not null", value: "is not null", label: "is not null" },
];

export const allOperators = [
  // See:
  // https://api.populi.ai/docs/#tag/Organization-Management/paths/~1licenses/put
  // license.activation_filters
  // SingleValueFilter Operators
  ...singleValueOperators,

  // Not Supported
  // { name: "contains", label: "contains" },
  // { name: "beginsWith", label: "begins with" },
  // { name: "endsWith", label: "ends with" },
  // { name: "doesNotContain", label: "does not contain" },
  // { name: "doesNotBeginWith", label: "does not begin with" },
  // { name: "doesNotEndWith", label: "does not end with" },
  // { name: "null", label: "is null" },
  // { name: "notNull", label: "is not null" },

  // MultiValueFilter Operators
  ...multiValueOperators,

  // RangeFilter Operators
  ...rangeOperators,

  ...nullValueOperators,
];
