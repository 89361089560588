export const STATUS_BG_COLORS = {
  default: "#DCDCDC",
  success: "#29824F",
  failed: "#BA351C",
  info: "#007EAC",
  pending: "#5F6467",
  warning: "#D68712",
};

export const STATUS_COLORS = {
  default: "#000000",
  success: "#ffffff",
  failed: "#ffffff",
  info: "#ffffff",
  pending: "#ffffff",
  warning: "#ffffff",
};
