import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";

export const StyledArrowIcon = styled(FontAwesomeIcon)`
  color: #706e6b;
  width: 12px;
  height: 20px;
  font-weight: bold;
`;

export const ArrowContainer = styled.div`
  display: inline-block;
  width: 30px;
  height: 24px;
  padding: 2px;

  :hover {
    background-color: #f5f2fc;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const StyledErrorIcon = styled(FontAwesomeIcon)`
  color: #ff2a00;
  width: 20px;
  height: 20px;

  ${(props) =>
    props.$size === "small" &&
    `
    width: 16px;
    height: 16px;
  `}
`;

export const IconActionContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 10px;
  display: flex;
  gap: 8px;
  align-items: center;

  ${(props) =>
    props.$size === "small" &&
    `
    right: 10px;
    top: 5px;
    gap: 5px;
  `}
`;

export const StyledCalendarIcon = styled(FontAwesomeIcon)`
  color: #706e6b;
  width: 18px;
  height: 20px;
  cursor: pointer;

  ${(props) =>
    props.$size === "small" &&
    `
    width: 16px;
    height: 16px;
  `}
`;

export const StyledClearIcon = styled(FontAwesomeIcon)`
  color: #706e6b;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  cursor: pointer;

  ${(props) =>
    props.$size === "small" &&
    `
    width: 16px;
    height: 16px;
  `}
`;

export const DatePickerHeaderContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0px -5px;
`;

export const DatePickerHeaderTextContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const DatePickerHeaderText = styled.div`
  display: inline-block;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 0px;
  border-radius: 9px;
  padding: 2px 4px;

  :hover {
    background-color: #f5f2fc;
    cursor: pointer;
  }
`;

export const DatePickerHeaderWrapper = styled.div`
  position: absolute;
  left: -11px;
  top: -11px;
`;

export const CustomLeftArrow = () => <StyledArrowIcon icon={faChevronLeft} />;

export const CustomRightArrow = () => <StyledArrowIcon icon={faChevronRight} />;
