import Accordion, {
  AccordionContent,
  AccordionItem,
} from "core/components/Accordion";
import ProductList from "./ProductList";
import Skeleton from "react-loading-skeleton";

const BundleCount = ({
  maxItemsCount = 1,
  enabledItemsCount = 0,
  hasError = false,
}) => {
  const backgroundColor = hasError
    ? "#ff2a00"
    : enabledItemsCount > 0
    ? "#000000"
    : "#CCCCCC";

  return (
    <div
      style={{
        backgroundColor,
        fontSize: "16px",
        borderRadius: "16px",
        color: "#ffffff",
        fontWeight: "normal",
        padding: "4px 15px",
      }}
    >
      {enabledItemsCount}/{maxItemsCount}
    </div>
  );
};

const SkeletonAccordion = ({ itemCount = 1 }) => {
  return (
    <Skeleton
      count={itemCount}
      style={{
        height: "58px",
        padding: "15px 20px",
        marginBottom: "10px",
        borderRadius: "5px",
      }}
    />
  );
};

const ProductBundleAccordion = ({
  openItems,
  setOpenItems,
  bundles,
  products,
  productsEnabled,
  handleProductToggle,
  isLoading,
  settingsControlsConfigured,
  isReadOnly = false,
}) => {
  if (isLoading) {
    return <SkeletonAccordion itemCount={bundles.length} />;
  }

  return (
    <Accordion openItems={openItems} setOpenItems={setOpenItems}>
      {Object.values(bundles).map((bundle) => {
        const bundleProducts = Object.values(products).filter(
          (product) => product.meta.bundle.id === bundle.id
        );
        const bundleHasError = bundleProducts.some(
          (product) => product.hasError
        );
        const bundleHasProducts = bundleProducts.length > 0;

        return bundleHasProducts ? (
          <AccordionItem
            key={bundle.id}
            value={bundle.id}
            title={bundle.name}
            HeaderRight={() => {
              return (
                <BundleCount
                  maxItemsCount={bundleProducts.length}
                  enabledItemsCount={
                    bundleProducts.filter((product) =>
                      productsEnabled.includes(product.id)
                    ).length
                  }
                  hasError={bundleHasError}
                />
              );
            }}
          >
            <AccordionContent>
              <ProductList
                isReadOnly={isReadOnly}
                products={bundleProducts.map((product) => {
                  const compositePath = `${bundle.path}/${product.page.path}`;

                  return {
                    ...product,
                    controlsConfiguredCount:
                      settingsControlsConfigured[product.id],
                    toggleId: product.id,
                    productPath: compositePath,
                    isEnabled: productsEnabled.includes(product.id),
                    handleEnabledChange: handleProductToggle,
                  };
                })}
              />
            </AccordionContent>
          </AccordionItem>
        ) : null;
      })}
    </Accordion>
  );
};

export default ProductBundleAccordion;
