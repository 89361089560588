import { useMemo } from "react";

import { InputGroup, Label } from "../Form";
import useBulkPaste from "./useBulkPaste";
import {
  ModalButton,
  ModalButtonContainer,
  ModalHeader,
  ModalColumn,
  ModalText,
  ModalTextarea,
} from "./styles";

const BulkPasteEditor = () => {
  const { draftValues, setDraftValues, label, hideModal, saveEditor } =
    useBulkPaste();

  const isSaveEditorDisabled = useMemo(
    () => draftValues?.trim().length === 0,
    [draftValues]
  );

  return (
    <ModalColumn>
      <ModalHeader>Add {label}</ModalHeader>

      <ModalText>
        Copy and paste your {label} below with one per line.
      </ModalText>

      <InputGroup>
        <Label>{label}</Label>

        <ModalTextarea
          value={draftValues}
          placeholder={`Copy and paste your ${label}`}
          onChange={(event) => setDraftValues(event.target.value)}
        />
      </InputGroup>

      <ModalButtonContainer>
        <ModalButton type="button" variant="outlined" onClick={hideModal}>
          Cancel
        </ModalButton>

        <ModalButton
          type="button"
          onClick={saveEditor}
          disabled={isSaveEditorDisabled}
        >
          Add {label}
        </ModalButton>
      </ModalButtonContainer>
    </ModalColumn>
  );
};

export default BulkPasteEditor;
