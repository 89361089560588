import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import Flex from "../../Flex";
import styles from "./styles";
import Typography from "../../Typography";
import { useMemo } from "react";
import { friendlyNumber } from "common/utilities";

const Slider = ({
  id = "",
  label,
  size = "large",
  isRange = false,
  isDisabled = false,
  isLoading = false,
  value,
  minValue = 0,
  maxValue = 100,
  increment = 1,
  inverted,
  orientation,
  minStepsBetweenThumbs = 1,
  onChange,
  isReadOnly = false,
  error = "",
}) => {
  const filledTrackStyle = useMemo(
    () =>
      isRange
        ? {
            left: `${((value.min - minValue) / (maxValue - minValue)) * 100}%`,
            width: `${
              ((value.max - value.min) / (maxValue - minValue)) * 100
            }%`,
          }
        : {
            width: `${((value - minValue) / (maxValue - minValue)) * 100}%`,
          },
    [isRange, maxValue, minValue, value]
  );

  const onValueChange = (newValue) => {
    if (isRange) {
      onChange({
        min: Math.min(...newValue),
        max: Math.max(...newValue),
      });
    } else {
      onChange(newValue[0]);
    }
  };

  return (
    <styles.InputGroup
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: size !== "small" ? "150px" : "100px",
      }}
    >
      {size !== "small" && <styles.Label>{label}</styles.Label>}

      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Flex direction="row" alignItems="center">
            <styles.Root
              id={id}
              disabled={isDisabled}
              value={
                isRange
                  ? [value?.min || minValue, value?.max || maxValue]
                  : [value]
              }
              min={minValue}
              max={maxValue}
              inverted={inverted}
              orientation={orientation}
              step={increment}
              minStepsBetweenThumbs={minStepsBetweenThumbs}
              onValueChange={onValueChange}
            >
              <styles.Track size={size}>
                <styles.FilledTrack style={filledTrackStyle} size={size} />
                <styles.Range />
              </styles.Track>

              {isRange ? (
                <>
                  <styles.Thumb size={size}>
                    {friendlyNumber(value?.min)}
                  </styles.Thumb>
                  <styles.Thumb size={size}>
                    {friendlyNumber(value?.max)}
                  </styles.Thumb>
                </>
              ) : (
                <styles.Thumb size={size}>{friendlyNumber(value)}</styles.Thumb>
              )}
            </styles.Root>
          </Flex>

          <Flex direction="row" justifyContent="space-between">
            <Typography
              variant="sub-text"
              noMargin
              style={{
                width: "33%",
                fontSize: size === "small" ? "10px" : "12px",
              }}
            >
              {friendlyNumber(minValue)}
            </Typography>
            <Typography
              variant="sub-text"
              noMargin
              style={{
                width: "33%",
                textAlign: "center",
                fontSize: size === "small" ? "10px" : "12px",
              }}
            >
              {friendlyNumber((maxValue - minValue) * 0.5 + minValue)}
            </Typography>
            <Typography
              variant="sub-text"
              noMargin
              style={{
                width: "33%",
                textAlign: "right",
                fontSize: size === "small" ? "10px" : "12px",
              }}
            >
              {friendlyNumber(maxValue)}
            </Typography>
          </Flex>
        </>
      )}
    </styles.InputGroup>
  );
};

Slider.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["large", "small"]),
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRange: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
  ]),
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
