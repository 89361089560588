import styled from "styled-components";

const ProductSettingsCard = styled.div`
  background-color: #f9f9f9;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #dddbda;
`;

export default ProductSettingsCard;
