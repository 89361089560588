import useCurrentUser from "./useCurrentUser";

// A wrapper for the the function passed to useQuery
// Provides currentUser's accessToken to the underlying api call invoked
// Also keeps the react-query devtools from being polluted with long token strings
const useAuthenticatedCall = (apiCall) => {
  const { currentUser } = useCurrentUser();

  let { accessToken } = currentUser;

  return (queryArguments) => {
    const {
      // eslint-disable-next-line no-unused-vars
      queryKey: [_key, organizationId, requestParams],
      pageParam,
      meta,
    } = queryArguments;

    return apiCall({
      organizationId,
      ...requestParams,
      pageParam,
      meta,
      accessToken,
    });
  };
};

export default useAuthenticatedCall;
