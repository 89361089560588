import { useContext } from "react";
import UserContext from "../context/UserContext";

const useCurrentUser = () => {
  const {
    currentUser,
    setCurrentUser,
    updateCurrentUser,
    isPasswordExpired,
    setIsPasswordExpired,
    initializing,
    setInitializing,
    isIdentified,
    setIsIdentified,
  } = useContext(UserContext);

  return {
    currentUser,
    setCurrentUser,
    updateCurrentUser,
    isPasswordExpired,
    setIsPasswordExpired,
    initializing,
    setInitializing,
    isIdentified,
    setIsIdentified,
  };
};

export default useCurrentUser;
