import { allOperators } from "core/components/QueryBuilder/constants";

export const stateOptions = [
  {
    name: "AL",
    value: "AL",
    label: "AL",
  },
  {
    name: "AK",
    value: "AK",
    label: "AK",
  },
  {
    name: "AS",
    value: "AS",
    label: "AS",
  },
  {
    name: "AZ",
    value: "AZ",
    label: "AZ",
  },
  {
    name: "AR",
    value: "AR",
    label: "AR",
  },
  {
    name: "CA",
    value: "CA",
    label: "CA",
  },
  {
    name: "CO",
    value: "CO",
    label: "CO",
  },
  {
    name: "CT",
    value: "CT",
    label: "CT",
  },
  {
    name: "DE",
    value: "DE",
    label: "DE",
  },
  {
    name: "DC",
    value: "DC",
    label: "DC",
  },
  {
    name: "FM",
    value: "FM",
    label: "FM",
  },
  {
    name: "FL",
    value: "FL",
    label: "FL",
  },
  {
    name: "GA",
    value: "GA",
    label: "GA",
  },
  {
    name: "GU",
    value: "GU",
    label: "GU",
  },
  {
    name: "HI",
    value: "HI",
    label: "HI",
  },
  {
    name: "ID",
    value: "ID",
    label: "ID",
  },
  {
    name: "IL",
    value: "IL",
    label: "IL",
  },
  {
    name: "IN",
    value: "IN",
    label: "IN",
  },
  {
    name: "IA",
    value: "IA",
    label: "IA",
  },
  {
    name: "KS",
    value: "KS",
    label: "KS",
  },
  {
    name: "KY",
    value: "KY",
    label: "KY",
  },
  {
    name: "LA",
    value: "LA",
    label: "LA",
  },
  {
    name: "ME",
    value: "ME",
    label: "ME",
  },
  {
    name: "MH",
    value: "MH",
    label: "MH",
  },
  {
    name: "MD",
    value: "MD",
    label: "MD",
  },
  {
    name: "MA",
    value: "MA",
    label: "MA",
  },
  {
    name: "MI",
    value: "MI",
    label: "MI",
  },
  {
    name: "MN",
    value: "MN",
    label: "MN",
  },
  {
    name: "MS",
    value: "MS",
    label: "MS",
  },
  {
    name: "MO",
    value: "MO",
    label: "MO",
  },
  {
    name: "MT",
    value: "MT",
    label: "MT",
  },
  {
    name: "NE",
    value: "NE",
    label: "NE",
  },
  {
    name: "NV",
    value: "NV",
    label: "NV",
  },
  {
    name: "NH",
    value: "NH",
    label: "NH",
  },
  {
    name: "NJ",
    value: "NJ",
    label: "NJ",
  },
  {
    name: "NM",
    value: "NM",
    label: "NM",
  },
  {
    name: "NY",
    value: "NY",
    label: "NY",
  },
  {
    name: "NC",
    value: "NC",
    label: "NC",
  },
  {
    name: "ND",
    value: "ND",
    label: "ND",
  },
  {
    name: "MP",
    value: "MP",
    label: "MP",
  },
  {
    name: "OH",
    value: "OH",
    label: "OH",
  },
  {
    name: "OK",
    value: "OK",
    label: "OK",
  },
  {
    name: "OR",
    value: "OR",
    label: "OR",
  },
  {
    name: "PW",
    value: "PW",
    label: "PW",
  },
  {
    name: "PA",
    value: "PA",
    label: "PA",
  },
  {
    name: "PR",
    value: "PR",
    label: "PR",
  },
  {
    name: "RI",
    value: "RI",
    label: "RI",
  },
  {
    name: "SC",
    value: "SC",
    label: "SC",
  },
  {
    name: "SD",
    value: "SD",
    label: "SD",
  },
  {
    name: "TN",
    value: "TN",
    label: "TN",
  },
  {
    name: "TX",
    value: "TX",
    label: "TX",
  },
  {
    name: "UT",
    value: "UT",
    label: "UT",
  },
  {
    name: "VT",
    value: "VT",
    label: "VT",
  },
  {
    name: "VI",
    value: "VI",
    label: "VI",
  },
  {
    name: "VA",
    value: "VA",
    label: "VA",
  },
  {
    name: "WA",
    value: "WA",
    label: "WA",
  },
  {
    name: "WV",
    value: "WV",
    label: "WV",
  },
  {
    name: "WI",
    value: "WI",
    label: "WI",
  },
  {
    name: "WY",
    value: "WY",
    label: "WY",
  },
].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

export const mockAttributeOptions = [
  {
    label: "Item 1",
    value: "Item 1",
  },
  {
    label: "Item 2",
    value: "Item 2",
  },
  {
    label: "Item 3",
    value: "Item 3",
  },
  {
    label: "Item 4",
    value: "Item 4",
  },
];

export const mockModelOptions = [
  {
    name: "Model 1",
    value: "model-1",
    label: "Model 1",
  },
  {
    name: "Model 2",
    value: "model-2",
    label: "Model 2",
  },
  {
    name: "Model 3",
    value: "model-3",
    label: "Model 3",
  },
];

export const mockQueryBuilderFields = [
  {
    inputType: "text",
    label: "Name",
    name: "name",
    operators: allOperators,
    value: "name",
  },
  {
    inputType: "number",
    label: "Age",
    name: "age",
    operators: allOperators,
    value: "age",
  },
  {
    inputType: "text",
    label: "Gender",
    name: "gender",
    operators: allOperators,
    value: "gender",
  },
  {
    inputType: "select",
    label: "State",
    name: "state",
    operators: allOperators,
    value: "state",
    valueEditorType: "multiselect",
    values: stateOptions,
  },
  {
    dateType: "date",
    inputType: "date",
    label: "Date",
    name: "date",
    operators: allOperators,
    value: "date",
    valueEditorType: "date",
  },
  {
    dateType: "month",
    inputType: "date",
    label: "Month",
    name: "month",
    operators: allOperators,
    value: "month",
    valueEditorType: "date",
  },
  {
    dateType: "year",
    inputType: "date",
    label: "Year",
    name: "year",
    operators: allOperators,
    value: "year",
    valueEditorType: "date",
  },
  {
    dateType: "quarter",
    inputType: "date",
    label: "Quarter",
    name: "quarter",
    operators: allOperators,
    value: "quarter",
    valueEditorType: "date",
  },
  {
    inputType: "number",
    label: "Decile",
    name: "decile",
    operators: allOperators,
    value: "decile",
  },
  {
    inputType: "number",
    label: "Decile",
    name: "decile",
    operators: allOperators,
    value: "decile",
  },
  {
    inputType: "zip",
    label: "Zipcodes",
    name: "zip",
    operators: allOperators,
    value: "zip",
  },
];
