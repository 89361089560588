import { useState } from "react";
import { PasswordHideIcon, PasswordShowIcon, TextInput } from "./styles";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordField = (props) => {
  const { isReadOnly, isDisabled, ...rest } = props;

  const [isVisible, setVisible] = useState(false);

  if (isVisible) {
    return (
      <span style={{ position: "relative" }}>
        <TextInput
          {...rest}
          type="text"
          readOnly={isReadOnly}
          disabled={isDisabled}
          style={{ paddingRight: "55px" }}
        />
        <PasswordHideIcon icon={faEyeSlash} onClick={() => setVisible(false)} />
      </span>
    );
  }

  return (
    <span style={{ position: "relative" }}>
      <TextInput
        {...rest}
        type="password"
        readOnly={isReadOnly}
        disabled={isDisabled}
        style={{ paddingRight: "55px" }}
      />
      <PasswordShowIcon icon={faEye} onClick={() => setVisible(true)} />
    </span>
  );
};

export default PasswordField;
