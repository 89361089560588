import { z } from "zod";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Field from "core/components/Field";
import OrgIdTooltip from "./OrgIdTooltip";
import useForm from "core/hooks/useForm";
import { DEFAULT_ORG } from "core/constants";
import {
  domain,
  email,
  lettersAndNumbers,
  lowercase,
  maxLength,
  minLength,
  notEquals,
  required,
  startsWithLetter,
  validate,
  objectOrArrayRequired,
  noDuplicateArrayItems,
} from "common/validation";
import { useQuery } from "@tanstack/react-query";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getCustomerSegments } from "modules/admin/actions";
import DetailsViewer from "modules/admin/DetailsViewer";

// TODO: Update this to match field level design and come up with good pattern for re-use between form and field
const formSchema = z.object({
  id: z.string().min(2).max(10),
  active: z.boolean(),
  name: z.string().min(2),
  domain: z.array(z.string().min(3)).min(1),
  customer_segment: z.string(),
  medicare_licensed: z.boolean(),
  primaryContact: z.object({
    name: z.string().min(2),
    email: z.string().email(),
  }),
  licenses: z.object({
    tableau_user_licenses: z.object({
      explorer_count: z.number(),
      viewer_count: z.number(),
    }),
    user_limit: z.number(),
  }),
  preferences: z.object({
    bi_portal: z.object({
      default_states: z.array(z.string()),
    }),
  }),
});

const OrganizationDetailForm = ({
  onSubmit,
  initialValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  isCancelling,
  error,
}) => {
  const { formValues, errors, isDisabled, onFormSubmit, onFieldChange } =
    useForm({
      placeholderValues: DEFAULT_ORG,
      initialValues,
      disabledUntilTouched: true,
      onSubmit,
      formSchema,
      isFetchingInitialValues: isFetching,
    });

  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);

  const customerSegmentsQueryKey = ["customer-segments", null, {}];

  const { data: customerSegments } = useQuery({
    queryKey: customerSegmentsQueryKey,
    queryFn: fetchCustomerSegments,
    placeholderData: {},
  });

  const getCustomerSegmentOptions = useMemo(() => {
    let obj = [];

    if (customerSegments && Object.keys(customerSegments).length > 0) {
      const customerSegmentKeys = Object.keys(customerSegments);
      for (let i = 0; i < customerSegmentKeys.length; i++) {
        const option = {
          value: customerSegmentKeys[i],
          label: Object.values(customerSegments).find(
            (item) => item.id === customerSegmentKeys[i]
          )?.name,
        };
        obj.push(option);
      }
    }

    return obj;
  }, [customerSegments]);

  const isMedicareLicensable = Object.values(customerSegments).find(
    (item) => item.id === formValues.customer_segment
  )?.is_medicare_licensable;

  return (
    <Form
      onSubmit={onFormSubmit}
      submitLabel={`${initialValues?.id ? "Save Changes" : "Next"}`}
      onCancel={onCancel}
      cancelLabel="Retry Setup"
      cancelIcon={faArrowsRotate}
      isCancelLoading={isCancelling}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
      // Removing mode to control form entirely with isDisabled above
      // mode={!!values?.id ? "update" : "create"}
    >
      <Error>{error}</Error>
      <Field
        type="text"
        id="name"
        labelText="Organization Name"
        placeholder="Enter name"
        autoComplete="organization"
        showSkeleton={isFetching}
        isRequired={true}
        value={formValues.name}
        error={errors.name}
        onChange={onFieldChange("name")}
        validator={validate(required(), minLength(2))}
      />

      <Field
        type="text"
        id="id"
        labelText="Organization ID"
        labelHint={<OrgIdTooltip />}
        placeholder="Enter unique id"
        autoComplete="organization-id"
        showSkeleton={isFetching}
        isRequired={true}
        value={formValues.id}
        error={errors.id}
        isDisabled={Boolean(initialValues?.id)}
        onChange={onFieldChange("id")}
        validator={validate(
          required(),
          startsWithLetter(),
          lowercase(),
          lettersAndNumbers(),
          minLength(2),
          maxLength(10),
          notEquals("hosting", "populi")
        )}
      />

      <Field
        type="text"
        id="primaryContact.name"
        labelText="Primary Contact"
        placeholder="Enter name"
        autoComplete="name"
        showSkeleton={isFetching}
        isRequired={true}
        value={formValues.primaryContact.name}
        error={errors.primaryContact?.name}
        onChange={onFieldChange("primaryContact.name")}
        validator={validate(required(), minLength(2))}
      />
      <Field
        type="text" // TODO Email input
        id="primaryContact.email"
        labelText="Primary Email"
        placeholder="Enter email"
        autoComplete="email"
        showSkeleton={isFetching}
        isRequired={true}
        value={formValues.primaryContact.email}
        error={errors.primaryContact?.email}
        onChange={onFieldChange("primaryContact.email")}
        validator={validate(required(), email())}
      />
      <Field
        type="text"
        id="domain"
        labelText="Domain"
        placeholder="Enter domain"
        showSkeleton={isFetching}
        isMultiValue={true}
        isRequired={true}
        value={formValues.domain}
        error={errors.domain}
        onChange={onFieldChange("domain")}
        validator={validate(
          objectOrArrayRequired(),
          domain(),
          noDuplicateArrayItems()
        )}
      />
      {!initialValues?.id ? (
        <div>
          <Field
            type="combobox"
            id="customer_segment"
            labelText="Customer Segment"
            placeholder="Please Select"
            showSkeleton={isFetching}
            isMultiValue={false}
            isRequired={true}
            value={formValues.customer_segment}
            error={errors.customer_segment}
            onChange={(val) => {
              onFieldChange("customer_segment")(val);
              onFieldChange("medicare_licensed")(false);
            }}
            validator={validate(required())}
            options={getCustomerSegmentOptions}
          />
          {isMedicareLicensable && (
            <Field
              type="radio"
              id="medicare_licensed"
              labelText="Is Medicare QE Included?"
              showSkeleton={isFetching}
              isMultiValue={false}
              isRequired={true}
              value={formValues.medicare_licensed}
              onChange={onFieldChange("medicare_licensed")}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          )}
        </div>
      ) : (
        <DetailsViewer
          style={{ paddingTop: 0, paddingBottom: "20px" }}
          isLoading={isFetching}
          data={[
            {
              label: "Customer Segment",
              value: Object.values(customerSegments).find(
                (item) => item.id === formValues?.customer_segment
              )?.name,
            },
            ...(isMedicareLicensable
              ? [
                  {
                    label: "Is Medicare QE Included?",
                    value: formValues?.medicare_licensed ? "Yes" : "No",
                  },
                ]
              : []),
          ]}
        />
      )}
    </Form>
  );
};

export default OrganizationDetailForm;
