import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "core/components/Link";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Todo: Need a good fallback potentially
const ProductPageBreadcrumbs = ({ breadcrumbs = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLocationFromRoute = location?.state?.from === "route";

  useEffect(() => {
    navigate(location.pathname, { state: null, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const linkCrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
  const currentPageCrumb = breadcrumbs[breadcrumbs.length - 1];

  return (
    <motion.div
      initial={{
        opacity: isLocationFromRoute ? 0 : 1,
      }}
      exit={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        opacity: {
          duration: 0.4,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          fontSize: "14px",
          fontFamily: "Inter",
          gap: "5px",
        }}
      >
        {linkCrumbs.map(({ title, to, disabled }) => {
          return (
            <div key={title}>
              {disabled ? (
                <span
                  style={{
                    color: "#3E3E3C",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </span>
              ) : (
                <Link
                  to={to}
                  state={{ from: "route" }}
                  style={{
                    marginRight: "5px",
                    fontSize: "14px",
                    fontFamily: "Inter",
                  }}
                >
                  {title}
                </Link>
              )}

              <span style={{ color: "#3e3e3c" }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
          );
        })}
        <div style={{ color: "#3E3E3C", fontWeight: "bold" }}>
          {currentPageCrumb.title}
        </div>
      </div>
    </motion.div>
  );
};

export default ProductPageBreadcrumbs;
