import React, { useRef, useState } from "react";
import { splitFormProps, useField } from "react-form";
import Skeleton from "react-loading-skeleton";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  InputGroup,
  Label,
  Input as StyledInput,
  InputSkeletonContainer,
  Textarea,
  FieldError,
} from "./styles";

const PasswordInput = React.forwardRef((props, ref) => {
  const { type, ...rest } = props;
  const [isVisible, setVisible] = useState(false);

  if (isVisible) {
    return (
      <span style={{ position: "relative" }}>
        <StyledInput
          {...rest}
          ref={ref}
          type="text"
          style={{ paddingRight: "55px" }}
        />
        <FontAwesomeIcon
          icon={faEyeSlash}
          onClick={() => setVisible(false)}
          style={{
            position: "absolute",
            height: "18px",
            top: "1px",
            right: "10px",
            cursor: "pointer",
          }}
        />
      </span>
    );
  }

  return (
    <span style={{ position: "relative" }}>
      <StyledInput
        {...rest}
        ref={ref}
        type="password"
        style={{ paddingRight: "55px" }}
      />
      <FontAwesomeIcon
        icon={faEye}
        onClick={() => setVisible(true)}
        style={{
          position: "absolute",
          height: "18px",
          top: "1px",
          right: "11px",
          cursor: "pointer",
        }}
      />
    </span>
  );
});

const TextareaInput = React.forwardRef((props, ref) => {
  return <Textarea rows="10" ref={ref} {...props} />;
});

const NumberInput = (props) => {
  const { setValue, value, min, max } = props;
  const ref = useRef();

  const focus = () => ref.current.focus();

  const handleDecrement = () => {
    const newValue = value - 1;
    if (newValue < min) {
      setValue(Number(min));
    } else {
      setValue(newValue);
    }
  };

  const handleIncrement = () => {
    const newValue = value + 1;
    if (newValue > max) {
      setValue(Number(max));
    } else {
      setValue(newValue);
    }
  };

  const handleChange = (event) => setValue(Number(event.target.value));

  return (
    <div
      style={{
        position: "relative",
      }}
      onClick={focus}
    >
      <StyledInput ref={ref} {...props} onChange={handleChange} />
      <div
        style={{
          position: "absolute",
          display: "flex",
          right: "15px",
          gap: "15px",
          height: "41px",
          top: "0px",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faMinus}
          style={{ cursor: "pointer" }}
          onClick={handleDecrement}
        />
        <FontAwesomeIcon
          icon={faPlus}
          style={{ cursor: "pointer" }}
          onClick={handleIncrement}
        />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  // So that these don't collide with the handlers we assign directly
  const { onBlur, onPaste, ...rest } = props;

  return (
    <StyledInput
      {...rest}
      onBlur={(event) => {
        props.setValue((value) => value?.trim());

        // Bubble
        onBlur && onBlur(event);
      }}
      onPaste={(event) => {
        // Keeps from firing onChange with a value of undefined
        event.preventDefault();

        if (event.clipboardData) {
          const pasteValue = event.clipboardData.getData("text");
          props.setValue(() => pasteValue.trim());
        }

        // Bubble
        onPaste && onPaste(event);
      }}
    />
  );
};

export const getInputType = (type) => {
  switch (type) {
    case "password":
      return PasswordInput;

    case "textarea":
      return TextareaInput;

    case "number":
      return NumberInput;

    default:
      return TextInput;
  }
};

const Input = React.forwardRef((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props);
  const {
    meta: { error, isTouched },
    getInputProps,
    setValue,
  } = useField(field, {
    ...fieldOptions,
  });

  const TypedInput = getInputType(rest.type);

  if (props.isLoading) {
    return (
      <InputGroup>
        <Label>{props.label}</Label>
        <InputSkeletonContainer>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  return (
    <InputGroup>
      <Label htmlFor={props.field}>{props.label}</Label>
      <TypedInput
        id={props.field}
        {...getInputProps({ ref, ...rest })}
        setValue={setValue}
      />
      <FieldError>{error && isTouched ? error : ""}</FieldError>
    </InputGroup>
  );
});

export default Input;
