import { TrError } from "./styles";

const RowErrorFallback = () => {
  return (
    <TrError>
      There was an issue loading this row. Please contact support for
      assistance.
    </TrError>
  );
};

export default RowErrorFallback;
