import PropTypes from "prop-types";

import BulkPasteProvider from "./BulkPasteProvider";
import BulkPasteReadOnlyInput from "./BulkPasteReadOnlyInput";
import BulkPasteModal from "./BulkPasteModal";

const BulkPasteInput = ({
  id,
  size = "large",
  label,
  value,
  validator,
  onChange,
  isDisabled,
}) => {
  return (
    <BulkPasteProvider
      id={id}
      size={size}
      label={label}
      values={value}
      validator={validator}
      onChange={onChange}
      isDisabled={isDisabled}
    >
      <BulkPasteReadOnlyInput />
      <BulkPasteModal />
    </BulkPasteProvider>
  );
};

BulkPasteInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  validator: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["large", "small"]),
};

export default BulkPasteInput;
