import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const defaultSettings = {
  isNavigationOpen: false,
};

const useUserSettings = () => {
  const queryClient = useQueryClient();

  const { data: cachedSettings } = useQuery({
    queryKey: ["settings"],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        try {
          // I am defaulting settings too, because after we get data, it can be undefined.
          // Until we get data placeholderData will be used.
          const settings =
            queryClient.getQueryData("settings") || defaultSettings;
          resolve(settings);
        } catch (error) {
          reject(error);
        }
      });
    },
    placeholderData: {
      // This will be only used till we get data from cache.
      ...defaultSettings,
    },
    networkMode: "always",
  });

  const { mutate: setSettings } = useMutation({
    mutationFn: (setting) => {
      return new Promise((resolve, reject) => {
        try {
          queryClient.setQueryData("settings", (oldSettings) => ({
            ...oldSettings,
            ...setting,
          }));
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["settings"] });
    },
  });

  return {
    setSettings,
    settings: cachedSettings,
  };
};

export default useUserSettings;
