import PropTypes from "prop-types";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import Typography from "core/components/Typography";

export const Container = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailsViewer = ({ data = [], isLoading, style }) => {
  const getDisplayString = (val) => {
    if (val === undefined || val === null) return "—";
    if (typeof val === "number") return String(val);
    if (Array.isArray(val)) return val.join(", ");
    else return val || "—";
  };

  return (
    <Container style={{ ...style }}>
      {data.map((item, index) => {
        return (
          <ItemContainer key={index}>
            <Typography variant="sub-text" style={{ fontWeight: 600 }} noMargin>
              {item.label}
            </Typography>
            {isLoading ? (
              <Skeleton style={{ height: "30px" }} />
            ) : (
              <Typography variant="p" noMargin>
                {getDisplayString(item.value)}
              </Typography>
            )}
          </ItemContainer>
        );
      })}
    </Container>
  );
};

DetailsViewer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  isLoading: PropTypes.bool,
};

export default DetailsViewer;
