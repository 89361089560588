import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleCheck,
  faCircleInfo,
  faCircleX,
} from "@fortawesome/pro-regular-svg-icons";

import useToast from "core/hooks/useToaster";

const TOAST_DURATION = 3000;
const TOAST_DURATION_IN_SECONDS = TOAST_DURATION / 1000;

// Adapting this https://css-tricks.com/timer-bars-in-css-with-custom-properties/
const timer = keyframes`
 to {
    transform: scaleX(0);
  }
`;

// Adapting this https://css-tricks.com/css3-progress-bars/
const loading = keyframes`
   0% {
     background-position: 0 0;
   }
   100% {
     background-position: 75px 75px;
     /* background-position: 50px 50px; */
   }
`;

const ToasterContainer = styled.div`
  position: fixed;
  top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1000;
  gap: 8px;
`;

const getToastColor = (type) => {
  switch (type) {
    case "success":
      return "#008844;";

    case "info":
      return "#007EAC;";

    case "error":
      return "#BA351C;";

    default:
      return "#2a0f4d;";
  }
};

const getToastIcon = (type) => {
  switch (type) {
    case "success":
      return faCircleCheck;

    case "info":
      return faCircleInfo;

    case "error":
      return faCircleX;

    default:
      return faCircleInfo;
  }
};

const Toast = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background-color: ${(props) => getToastColor(props.type)};
  color: #fff;
  padding: 12px 15px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;

const ToastTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const ToastMessage = styled.div`
  font-size: 12px;
`;

const getDismissColor = (type) => {
  switch (type) {
    case "success":
      return "#9fdebe";

    case "info":
      return "#97b9e7";

    case "error":
      return "#fba398";

    default:
      return "#9d9ca7";
  }
};
const ToastDismiss = styled.div`
  cursor: pointer;
  margin-left: auto;
  padding: 2px 6px;
  margin-top: -2px;
  border-radius: 100%;

  :hover {
    background-color: ${(props) => getDismissColor(props.type)};
  }
`;

const getTimerColor = (type) => {
  switch (type) {
    case "success":
      return "#1AA366;";

    case "info":
      return "#00529a;";

    case "error":
      return "#CA2A29;";

    default:
      return "#a3a1ab;";
  }
};

const ToastTimer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 99%;
  border-bottom-left-radius: 4px;
  background-color: ${(props) => getTimerColor(props.type)};

  /* Animation */
  animation-name: ${timer};
  animation-duration: ${(props) => `calc(${props.duration} * 1s)`};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: unset;
  transform-origin: left center;
`;

const ToastLoadingBar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => getTimerColor(props.type)};
  overflow: hidden;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: ${loading} 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
`;

// Todo: Animate toasts added and remove from toaster
const Toaster = () => {
  const { toasts, dismissById } = useToast();

  return (
    <ToasterContainer>
      {Object.values(toasts).length
        ? Object.values(toasts).map((toast) => {
            const { id, type, title, message, isInfinite } = toast;

            return (
              <Toast key={id} type={type}>
                <FontAwesomeIcon
                  icon={getToastIcon(type)}
                  size="lg"
                  style={{ marginTop: "2px" }}
                />

                <div>
                  <ToastTitle>{title}</ToastTitle>
                  <ToastMessage>{message}</ToastMessage>
                </div>
                <ToastDismiss type={toast.type} onClick={() => dismissById(id)}>
                  <FontAwesomeIcon icon={faXmark} />
                </ToastDismiss>
                {isInfinite ? (
                  <ToastLoadingBar type={type} />
                ) : (
                  <ToastTimer
                    type={type}
                    duration={TOAST_DURATION_IN_SECONDS}
                  />
                )}
              </Toast>
            );
          })
        : null}
    </ToasterContainer>
  );
};

export default Toaster;
