import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import { FormSelect as Select } from "core/components/Form/Select";
import { stateOptions } from "./OrgProductLicenseForm/constants";
import { validate } from "common/validation";

const OrgPreferencesForm = ({
  onSubmit,
  submitLabel = "Submit",
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  return (
    <Form
      id="orgPreferences"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitLabel={submitLabel}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      mode={"update"}
    >
      <Error>{error}</Error>
      <Select
        field="preferences.bi_portal.default_states"
        label="Default State"
        validate={validate()}
        isLoading={isFetching}
        placeholder="None"
        options={stateOptions}
      />
    </Form>
  );
};

export default OrgPreferencesForm;
