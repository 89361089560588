import { useCallback, useRef, useState } from "react";

import NPIValidatorTable from "./NPIValidatorTable";
import { Row } from "core/styles";
import Button from "core/components/Button";
import Utils from "common/utilities";
import Typography from "core/components/Typography";

const NPIValidationForm = ({
  invalidNPIs = [],
  validNPIs = [],
  onCancel,
  onSubmit,
  isSubmitting,
}) => {
  const [draftNPIs, setDraftNPIs] = useState(() =>
    invalidNPIs.map((npi, index) => ({
      index,
      npi,
      isValid: false,
      errorText: "",
      canRevalidate: false,
      inEditMode: false,
    }))
  );

  const currentRowIndexRef = useRef(null);
  const editDraftNPI = useCallback(
    ({ rowIndex: newRowIndex }) => {
      const { current } = currentRowIndexRef;
      if (current !== null) draftNPIs[current].inEditMode = false;
      if (newRowIndex !== null) draftNPIs[newRowIndex].inEditMode = true;
      currentRowIndexRef.current = newRowIndex;
      setDraftNPIs([...draftNPIs]);
    },
    [draftNPIs]
  );

  const changeDraftNPI = useCallback(
    ({ npi, rowIndex }) => {
      const newItem = {
        index: rowIndex,
        npi,
        isValid: false,
        errorText: "",
        canRevalidate: true,
        inEditMode: true,
      };
      draftNPIs.splice(rowIndex, 1, newItem);
      // new array reference is set so NPIValidatorTable is re rendered with new list
      setDraftNPIs([...draftNPIs]);
    },
    [draftNPIs]
  );

  const validateDraftNPI = useCallback(
    ({ rowIndex }) => {
      const npiItem = draftNPIs.find((item) => item.index === rowIndex);

      const anyValidNPIItemWithSameNPI = validNPIs
        .concat(
          draftNPIs.filter((item) => item.isValid).map((item) => item.npi)
        )
        .find((item) => item === npiItem.npi);

      const newItem = {
        ...npiItem,
      };

      newItem.canRevalidate = false;
      newItem.inEditMode = false;

      if (anyValidNPIItemWithSameNPI) {
        newItem.errorText = "NPI already exists";
      } else {
        if (Utils.validateNPI(newItem.npi)) {
          newItem.isValid = true;
          newItem.errorText = "";
        } else {
          newItem.errorText = "Invalid NPI";
        }
      }

      draftNPIs.splice(rowIndex, 1, newItem);
      // new array reference is set so NPIValidatorTable is re rendered with new list.
      setDraftNPIs([...draftNPIs]);
    },
    [validNPIs, draftNPIs]
  );

  const disableSubmit = draftNPIs.find(
    ({ isValid, inEditMode }) => !isValid || inEditMode
  );

  return (
    <>
      <Typography variant="h2">Validate NPIs</Typography>

      <Typography variant="p">
        There were {draftNPIs.length} invalid NPIs. Please correct the errors
        below. Uncorrected NPIs will not be added to your configuration.
      </Typography>

      <NPIValidatorTable
        npiList={draftNPIs}
        editDraftNPI={editDraftNPI}
        changeDraftNPI={changeDraftNPI}
        validateDraftNPI={validateDraftNPI}
      />
      <Row style={{ marginTop: "20px" }}>
        <Button type="button" onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <Button
          as="button"
          type="submit"
          onClick={() => {
            const correctedNPIs = draftNPIs
              .filter((item) => item.isValid)
              .map((item) => item.npi);
            onSubmit(correctedNPIs);
          }}
          disabled={disableSubmit}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </Row>
    </>
  );
};

export default NPIValidationForm;
