import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import PropTypes from "prop-types";

const DownloadIcon = styled(FontAwesomeIcon)`
  opacity: 0.7;
  --fa-primary-color: #007eac;
  --fa-secondary-color: #007eac;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    opacity: 1;
  }
`;

export const DownloadButton = ({
  value,
  hoverTitle = "Download",
  successTitle = "Success!",
  tooltipDirection = "right",
}) => {
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const handleDownload = async () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(value)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();

    setDownloadSuccess(true);
    setTimeout(() => {
      setDownloadSuccess(false);
    }, 3000);
  };

  return (
    <span
      id="downloadIcon"
      style={{
        display: "inline-block",
        position: "relative",
        padding: "5px",
        paddingLeft: "10px",
        cursor: "pointer",
      }}
      onClick={handleDownload}
    >
      <Tooltip
        anchorSelect="#downloadIcon"
        place={tooltipDirection}
        style={{
          transition: "background-color 150ms linear",
          ...(downloadSuccess ? { backgroundColor: "#008844" } : {}),
        }}
      >
        {downloadSuccess ? successTitle : hoverTitle}
      </Tooltip>
      <DownloadIcon icon={faDownload} size="lg" title={hoverTitle} />
    </span>
  );
};

DownloadButton.propTypes = {
  value: PropTypes.string.isRequired,
  hoverTitle: PropTypes.string,
  successTitle: PropTypes.string,
};

export default DownloadButton;
