import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "pages/home";
import OrganizationManagement from "pages/admin/org-management";
import SwitchOrganization from "pages/admin/switch-org";
import UserManagement from "pages/admin/user-management";
import ForgotPassword from "pages/forgot-password";
import CreatePassword from "pages/create-password";
import CreateOrg from "pages/admin/create-org";
import CreateUser from "pages/admin/create-user";
import UserProfile from "pages/admin/user-profile";
import UpdateOrg from "pages/admin/update-org";
import ManagedProviders from "pages/config/managed-providers";
import Aliases from "pages/config/aliases";
import Relationships from "pages/config/relationships";
import UpdateRelationships from "pages/config/update-relationships";
import UpdateAliases from "pages/config/update-aliases";
import RemoveRelationships from "pages/config/remove-relationships";
import RemoveAliases from "pages/config/remove-aliases";
import ExpiringPassword from "pages/expiring-password";
import PageNotFound from "pages/404";
import ErrorPage from "pages/error";
import ProtectedRoute from "core/components/ProtectedRoute";
import Toaster from "core/components/Toaster";

const App = () => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="create-password" element={<CreatePassword />} />
          <Route path="expiring-password" element={<ExpiringPassword />} />

          <Route
            index
            element={
              <ProtectedRoute pageTitle="Home" hideAppShell>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route path="config/:orgId">
            <Route
              path="managed-providers"
              element={
                <ProtectedRoute pageTitle="Managed Providers">
                  <ManagedProviders />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="aliases" />} />
              <Route path="aliases" element={<Aliases />} />
              <Route path="relationships" element={<Relationships />} />
            </Route>

            <Route
              path="managed-providers/relationships/update"
              element={
                <ProtectedRoute pageTitle="Update Relationships">
                  <UpdateRelationships />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/relationships/update/:npi"
              element={
                <ProtectedRoute pageTitle="Update Relationships">
                  <UpdateRelationships />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/relationships/remove"
              element={
                <ProtectedRoute pageTitle="Remove Relationships">
                  <RemoveRelationships />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/relationships/remove/:npi"
              element={
                <ProtectedRoute pageTitle="Remove Relationships">
                  <RemoveRelationships />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/aliases/update"
              element={
                <ProtectedRoute pageTitle="Update Aliases">
                  <UpdateAliases />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/aliases/update/:npi"
              element={
                <ProtectedRoute pageTitle="Update Aliases">
                  <UpdateAliases />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/aliases/remove"
              element={
                <ProtectedRoute pageTitle="Remove Aliases">
                  <RemoveAliases />
                </ProtectedRoute>
              }
            />
            <Route
              path="managed-providers/aliases/remove/:npi"
              element={
                <ProtectedRoute pageTitle="Remove Aliases">
                  <RemoveAliases />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="admin/switch-org"
            element={
              <ProtectedRoute pageTitle="Organizations">
                <SwitchOrganization />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin/switch-org/:orgId"
            element={
              <ProtectedRoute pageTitle="Organizations">
                <SwitchOrganization />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin/org-management"
            element={
              <ProtectedRoute pageTitle="Organizations">
                <OrganizationManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin/org-management/create"
            element={
              <ProtectedRoute>
                <CreateOrg />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin/org-management/:orgId/update/*"
            element={
              <ProtectedRoute>
                <UpdateOrg />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin/org-management/:orgId"
            element={
              <ProtectedRoute
                pageTitle="Users"
                backLinkText="Back to Organizations"
                backLinkTo="/admin/org-management"
                backLinkPermissions="org-management:read"
              >
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/org-management/:orgId/users/create"
            element={
              <ProtectedRoute>
                <CreateUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/org-management/:orgId/users/:email/profile/*"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="404"
            element={
              <ProtectedRoute hideAppShell={true}>
                <PageNotFound />
              </ProtectedRoute>
            }
          />
          <Route path="error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Routes>
      </BrowserRouter>

      <Toaster />
    </div>
  );
};

export default App;
