import styled from "styled-components";
import * as Switch from "@radix-ui/react-switch";

export const SwitchContainer = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
`;

export const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 9999px; // better than 100%

  transition: transform 300ms;
  transform: translateX(2px);

  &[data-state="checked"] {
    transform: translateX(29px);
  }
`;

export const SwitchRoot = styled(Switch.Root)`
  all: unset;
  width: 51px;
  height: 24px;
  background-color: #b0adab;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;

  &::before {
    content: "Off";
    position: absolute;

    right: 8px;
    top: 5px;

    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    color: #ffffff;
  }

  &[data-state="checked"] {
    background-color: #007eac;

    &::before {
      content: "On";
      top: 5px;
      left: 8px;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
  `}

  ${(props) =>
    props.isreadonly === "true" &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `}
`;
