import React from "react";
import { splitFormProps, useField } from "react-form";

const Checkbox = React.forwardRef((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props);
  const { value, setValue, getInputProps } = useField(field, {
    ...fieldOptions,
  });

  const onChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <input
      type="checkbox"
      {...getInputProps({ ref, ...rest })}
      onChange={onChange}
      checked={value}
    />
  );
});

export default Checkbox;
