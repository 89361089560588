import { useMemo, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { faBuildingUser, faBuilding } from "@fortawesome/pro-regular-svg-icons";
import Skeleton from "react-loading-skeleton";
import { ErrorBoundary } from "react-error-boundary";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useCurrentUser from "core/hooks/useCurrentUser";
import useToaster from "core/hooks/useToaster";
import Search from "core/components/Search";
import PageHeader from "core/components/PageHeader";
import TableErrorFallback from "core/components/Table/TableErrorFallback";
import { Row } from "core/styles";
import { getOrganizations, getUsers } from "modules/admin/actions";
import UserTable from "modules/admin/UserTable";
import Button from "core/components/Button";
import { ROLES } from "core/constants";
import Chip from "core/components/Chip";
import ButtonLink from "core/components/ButtonLink";
import useNavigation from "core/hooks/useNavigation.js";

const UserManagement = () => {
  const navigate = useNavigate();
  const { toaster } = useToaster();
  const params = useParams();
  const { orgId: organizationId } = params;
  const {
    currentUser: { role, organizationId: userOrgId },
  } = useCurrentUser();

  const { userSearchValue, setUserSearchValue } = useNavigation();
  const handleSearchChange = (event) => {
    setUserSearchValue(event.target.value);
  };
  const location = useLocation();

  const fetchUsers = useAuthenticatedCall(getUsers);
  const queryKey = ["users", organizationId];
  const {
    isLoading,
    data: users,
    fetchStatus,
    isError: isUsersError,
    error: usersError,
  } = useQuery({
    queryKey,
    queryFn: fetchUsers,
    staleTime: 20 * 1000,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isUsersError) {
      if (Array.isArray(usersError)) {
        toaster.error({ message: usersError[0] });
      } else {
        toaster.error({ message: usersError.message });
      }
    }
  }, [isUsersError, usersError, toaster]);

  useEffect(() => {
    const pathname = location.pathname; //this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const fetchOrganization = useAuthenticatedCall((req) =>
    getOrganizations({ ...req, organizationId })
  );

  const {
    data,
    isError: isOrgError,
    error: orgError,
  } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchOrganization,
    refetchOnMount: false,
  });
  const organization = useMemo(() => data?.[0], [data]);

  useEffect(() => {
    if (isOrgError) {
      if (Array.isArray(orgError)) {
        toaster.error({ message: orgError[0] });
      } else {
        toaster.error({ message: orgError.message });
      }
    }
  }, [isOrgError, orgError, toaster]);

  const canUpdateUsers =
    role === ROLES.systemAdmin ||
    (role === ROLES.orgAdmin && userOrgId === organizationId);

  const assignedExplorerSeats = organization?.userCount.explorer || 0;
  const assignedViewerSeats = organization?.userCount.viewer || 0;
  const assignedCreatorSeats = organization?.userCount.creator || 0;
  const assignedActiveUsers = organization?.userCount.total || 0;

  const availableExplorerSeats =
    organization?.licenses?.tableau_user_licenses?.explorer_count || 0;
  const availableViewerSeats =
    organization?.licenses?.tableau_user_licenses?.viewer_count || 0;
  const availableCreatorSeats =
    organization?.licenses?.tableau_user_licenses?.creator_count || 0;
  const availableActiveUsers = organization?.licenses?.user_limit || 0;

  const hasBIPortalLicenses = availableViewerSeats + availableExplorerSeats > 0;

  const pageTitle = useMemo(() => {
    if (!organization) return <Skeleton style={{ width: "150px" }} />;
    if (role === ROLES.systemAdmin || role === ROLES.systemViewer)
      return organization.name;
    if (role === ROLES.orgAdmin) return "My Organization";
  }, [organization, role]);

  const pageIcon = useMemo(() => {
    if (role === ROLES.systemAdmin || role === ROLES.systemViewer) {
      const isMyOrg = organizationId === userOrgId;
      return isMyOrg ? faBuildingUser : faBuilding;
    }

    if (role === ROLES.orgAdmin) return faBuildingUser;
  }, [role, organizationId, userOrgId]);

  return (
    <div style={{ position: "relative", padding: "35px" }}>
      <PageHeader
        title={pageTitle}
        icon={pageIcon}
        iconBackgroundColor="#D68712"
        iconAlt="Users"
        subtitle={
          organization ? (
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                gap: "5px 10px",
                flexWrap: "wrap",
              }}
            >
              {role === ROLES.orgAdmin && (
                <Chip text={`Org Name: ${organization.name}`} />
              )}
              <Chip
                text={`Active Users: ${assignedActiveUsers}/${availableActiveUsers}`}
              />
              {hasBIPortalLicenses ? (
                <>
                  <Chip
                    text={`BI Explorers: ${assignedExplorerSeats}/${availableExplorerSeats}`}
                  />
                  <Chip
                    text={`BI Viewers: ${assignedViewerSeats}/${availableViewerSeats}`}
                  />
                  {organizationId === "populi" ? (
                    <Chip
                      text={`BI Creators: ${assignedCreatorSeats}/${availableCreatorSeats}`}
                    />
                  ) : null}
                </>
              ) : null}
              {[ROLES.orgAdmin, ROLES.systemAdmin, ROLES.systemViewer].includes(
                role
              ) ? (
                <ButtonLink
                  to={`/admin/org-management/${organizationId}/update/general`}
                  state={{ from: "UserManagement", orgId: organizationId }}
                  variant="text"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: "12px",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {[ROLES.systemViewer].includes(role)
                    ? "View Details"
                    : "Edit Details"}
                </ButtonLink>
              ) : null}
            </div>
          ) : (
            "Loading..."
          )
        }
        PageActionsComponent={
          <Row
            style={{
              width: "fit-content",
              flex: 1,
              justifyContent: "flex-end",
              alignSelf: "flex-end",
              gap: "15px",
            }}
          >
            <Search value={userSearchValue} onChange={handleSearchChange} />
            {canUpdateUsers && (
              <Button
                width="auto"
                onClick={() =>
                  navigate(
                    `/admin/org-management/${organizationId}/users/create`,
                    {
                      state: {
                        from:
                          userOrgId === organizationId
                            ? "MyOrganization"
                            : "OrgUsers",
                        orgId: organizationId,
                      },
                    }
                  )
                }
              >
                Create User
              </Button>
            )}
          </Row>
        }
      />
      <ErrorBoundary FallbackComponent={TableErrorFallback}>
        <UserTable
          data={users || []}
          isRefreshing={fetchStatus === "fetching"}
          isLoading={isLoading}
          canUpdate={canUpdateUsers}
          searchFilter={{
            searchValue: userSearchValue,
            columns: ["name", "email"],
          }}
          userRole={role}
        />
      </ErrorBoundary>
    </div>
  );
};

export default UserManagement;
