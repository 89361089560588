import { motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AnimatedProductPageContainer = ({ direction = null, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationHasState = !!location.state;

  useEffect(() => {
    navigate(location.pathname, { state: null, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      initial={{
        opacity: locationHasState ? 0 : 1,
        // x: locationHasState ?
      }}
      exit={{
        // x: window.scrollY > 50 ? 0 : 150,
        opacity: 0,
        // x: isForwards ? -150 : isBackwards ? 150 : 0,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      transition={{
        x: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.5,
        },
      }}
      style={{ width: "100%" }}
    >
      {children}
    </motion.div>
  );
};
export default AnimatedProductPageContainer;
